define("snapdragon-common/routes/authenticated", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Authenticated route to be extended by other routes
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    buildRedirectUrl: function buildRedirectUrl() {
      return encodeURIComponent(window.location.href);
    },
    beforeModel: function beforeModel(transition) {
      if (IS_WEIGHTSITE) {
        if (!this.get('session.isAuthenticated')) {
          transition.abort();

          if (!Ember.testing) {
            var redirect = this.buildRedirectUrl(transition);
            window.location.href = "/login?redirect=".concat(redirect);
          }

          return;
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});