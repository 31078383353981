define("snapdragon-common/constants/food-logging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MEAL_TYPE_ID_TO_MEAL_TYPE = _exports.MEAL_TYPE_TO_BE = _exports.BE_TO_MEAL_TYPE = _exports.MEAL_TYPE_TO_STRING_MAP = _exports.MEAL_TYPE_STRING_MAP = _exports.EVENING_SNACK = _exports.AFTERNOON_SNACK = _exports.MORNING_SNACK = _exports.DINNER = _exports.LUNCH = _exports.BREAKFAST = _exports.ANYTIME = _exports.WATER_UNIT_TO_SYMBOL = _exports.MAX_WATER_BY_UNIT = _exports.CALORIES_PER_GRAM_OF_PROTEIN = _exports.CALORIES_PER_GRAM_OF_FAT = _exports.CALORIES_PER_GRAM_OF_CARBS = _exports.CALORIE_BUFFER = _exports.DEFAULT_WATER_GOAL_ML = _exports.DEFAULT_WATER_GOAL_CUP = _exports.DEFAULT_WATER_GOAL_OZ = _exports.FUTURE_ZONE = _exports.OVER_ZONE = _exports.UNDER_ZONE = _exports.IN_ZONE = void 0;
  var IN_ZONE = 'IN_ZONE';
  _exports.IN_ZONE = IN_ZONE;
  var UNDER_ZONE = 'UNDER_ZONE';
  _exports.UNDER_ZONE = UNDER_ZONE;
  var OVER_ZONE = 'OVER_ZONE';
  _exports.OVER_ZONE = OVER_ZONE;
  var FUTURE_ZONE = 'FUTURE_ZONE';
  _exports.FUTURE_ZONE = FUTURE_ZONE;
  var DEFAULT_WATER_GOAL_OZ = 64;
  _exports.DEFAULT_WATER_GOAL_OZ = DEFAULT_WATER_GOAL_OZ;
  var DEFAULT_WATER_GOAL_CUP = 8;
  _exports.DEFAULT_WATER_GOAL_CUP = DEFAULT_WATER_GOAL_CUP;
  var DEFAULT_WATER_GOAL_ML = 1900;
  _exports.DEFAULT_WATER_GOAL_ML = DEFAULT_WATER_GOAL_ML;
  var CALORIE_BUFFER = 50;
  _exports.CALORIE_BUFFER = CALORIE_BUFFER;
  var CALORIES_PER_GRAM_OF_CARBS = 4;
  _exports.CALORIES_PER_GRAM_OF_CARBS = CALORIES_PER_GRAM_OF_CARBS;
  var CALORIES_PER_GRAM_OF_FAT = 9;
  _exports.CALORIES_PER_GRAM_OF_FAT = CALORIES_PER_GRAM_OF_FAT;
  var CALORIES_PER_GRAM_OF_PROTEIN = 4;
  /**
   * Used for upper validation limits on logging water
   * @type {{en_US: number, en_US_cup: number, METRIC: number}}
   */

  _exports.CALORIES_PER_GRAM_OF_PROTEIN = CALORIES_PER_GRAM_OF_PROTEIN;
  var MAX_WATER_BY_UNIT = {
    en_US: 256,
    // 2 gallons
    en_US_cup: 32,
    // 2 gallons
    METRIC: 7600 // 7570 = 2 gallons

  };
  /**
   * Translates the display name value to unit
   * @type {{en_US: string, en_US_cup: string, METRIC: string}}
   */

  _exports.MAX_WATER_BY_UNIT = MAX_WATER_BY_UNIT;
  var WATER_UNIT_TO_SYMBOL = {
    en_US: 'fl oz',
    en_US_cup: 'cup',
    METRIC: 'ml'
  };
  _exports.WATER_UNIT_TO_SYMBOL = WATER_UNIT_TO_SYMBOL;
  var ANYTIME = 'Anytime';
  _exports.ANYTIME = ANYTIME;
  var BREAKFAST = 'Breakfast';
  _exports.BREAKFAST = BREAKFAST;
  var LUNCH = 'Lunch';
  _exports.LUNCH = LUNCH;
  var DINNER = 'Dinner';
  _exports.DINNER = DINNER;
  var MORNING_SNACK = 'Morning_Snack';
  _exports.MORNING_SNACK = MORNING_SNACK;
  var AFTERNOON_SNACK = 'Afternoon_Snack';
  _exports.AFTERNOON_SNACK = AFTERNOON_SNACK;
  var EVENING_SNACK = 'Evening_Snack';
  _exports.EVENING_SNACK = EVENING_SNACK;
  var MEAL_TYPE_STRING_MAP = {
    Anytime: ANYTIME,
    Breakfast: BREAKFAST,
    Lunch: LUNCH,
    Dinner: DINNER,
    'Morning Snack': MORNING_SNACK,
    'Afternoon Snack': AFTERNOON_SNACK,
    'Evening Snack': EVENING_SNACK
  };
  _exports.MEAL_TYPE_STRING_MAP = MEAL_TYPE_STRING_MAP;
  var MEAL_TYPE_TO_STRING_MAP = {
    ANYTIME: ANYTIME,
    BREAKFAST: BREAKFAST,
    LUNCH: LUNCH,
    DINNER: DINNER,
    MORNING_SNACK: MORNING_SNACK,
    AFTERNOON_SNACK: AFTERNOON_SNACK,
    EVENING_SNACK: EVENING_SNACK
  };
  /**
   * Back end (BE) maintains a different set of meal types than front end, create a
   * set of constants that translate between the two
   * @type {{
   *      BEFORE_BREAKFAST: string,
   *      BREAKFAST: string,
   *      BEFORE_LUNCH: string,
   *      LUNCH: string,
   *      BEFORE_DINNER: string,
   *      DINNER: string,
   *      AFTER_DINNER: string,
   *      NA: string
   *  }}
   */

  _exports.MEAL_TYPE_TO_STRING_MAP = MEAL_TYPE_TO_STRING_MAP;
  var BE_TO_MEAL_TYPE = {
    BEFORE_BREAKFAST: 'ANYTIME',
    // Note, you cannot currently create a BEFORE BREAKFAST note on weightsite
    BREAKFAST: 'BREAKFAST',
    BEFORE_LUNCH: 'MORNING_SNACK',
    LUNCH: 'LUNCH',
    BEFORE_DINNER: 'AFTERNOON_SNACK',
    DINNER: 'DINNER',
    AFTER_DINNER: 'EVENING_SNACK',
    NA: 'ANYTIME'
  };
  /**
   * @type {{
   *      ANYTIME: string,
   *      BREAKFAST: string,
   *      MORNING_SNACK: string,
   *      LUNCH: string,
   *      AFTERNOON_SNACK: string,
   *      DINNER: string,
   *      EVENING_SNACK: string
   * }}
   */

  _exports.BE_TO_MEAL_TYPE = BE_TO_MEAL_TYPE;
  var MEAL_TYPE_TO_BE = {
    ANYTIME: 'NA',
    BREAKFAST: 'BREAKFAST',
    MORNING_SNACK: 'BEFORE_LUNCH',
    LUNCH: 'LUNCH',
    AFTERNOON_SNACK: 'BEFORE_DINNER',
    DINNER: 'DINNER',
    EVENING_SNACK: 'AFTER_DINNER'
  };
  _exports.MEAL_TYPE_TO_BE = MEAL_TYPE_TO_BE;
  var MEAL_TYPE_ID_TO_MEAL_TYPE = {
    1: BREAKFAST,
    2: MORNING_SNACK,
    3: LUNCH,
    4: AFTERNOON_SNACK,
    5: DINNER,
    6: EVENING_SNACK,
    7: ANYTIME
  };
  _exports.MEAL_TYPE_ID_TO_MEAL_TYPE = MEAL_TYPE_ID_TO_MEAL_TYPE;
});