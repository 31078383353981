define("snapdragon-common/constants/app-store-urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GOOGLE_PLAY_STORE_URL = _exports.APPLE_APP_STORE_URL = void 0;
  // Fitbit App Store URLs
  var APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/fitbit-health-fitness/id462638897';
  _exports.APPLE_APP_STORE_URL = APPLE_APP_STORE_URL;
  var GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.fitbit.FitbitMobile';
  _exports.GOOGLE_PLAY_STORE_URL = GOOGLE_PLAY_STORE_URL;
});