define("snapdragon-common/helpers/get-by-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getByMatch = getByMatch;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function getByMatch(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        array = _ref2[0],
        filter = _ref2[1],
        field = _ref2[2];

    for (var i = 0; i < array.length; i++) {
      if (match(array[i], filter)) {
        return Ember.get(array[i], field);
      }
    }

    return '';
  }

  function match(obj1, obj2) {
    for (var prop in obj2) {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }

    return true;
  }

  var _default = Ember.Helper.helper(getByMatch);

  _exports.default = _default;
});