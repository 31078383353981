define("snapdragon-common/components/cw-form-rich-select-field", ["exports", "snapdragon-common/components/cw-form-select-field", "snapdragon-common/templates/components/cw-form-rich-select-field"], function (_exports, _cwFormSelectField, _cwFormRichSelectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isTrigger(el) {
    return el.tagName.toLowerCase() === 'select' || el.classList.contains('ember-power-select-trigger');
  }
  /**
   * Form component for select with search capabilities.
   *
   * Component displays set of options using
   * - native select (if number of options is less or equal to `nativeThreshold`)
   * - `power-select` component (if number of options is more than `nativeThreshold`)
   *
   * `power-select` displays options in overlay and allows to search options using search input.
   * On desktop overlay takes part of screen space, while on mobile overlay takes whole screen space.
   */


  var _default = _cwFormSelectField.default.extend({
    layout: _cwFormRichSelectField.default,

    /**
     * This threshold specify maximum number of options displayed using native `select`.
     */
    nativeThreshold: 10,
    disabled: false,
    mobileView: Ember.inject.service('mobile-view'),
    classNames: ['form-rich-select-field'],
    isWebView: Ember.computed.oneWay('mobileView.isWebView'),
    richApi: null,
    selectedOption: null,
    searchField: Ember.computed('optionLabelField', function () {
      return this.optionLabelField || 'displayName';
    }),
    isNative: Ember.computed('options', function () {
      var options = this.options,
          nativeThreshold = this.nativeThreshold;
      return options ? options.length <= nativeThreshold : true;
    }),
    matchOption: Ember.computed('searchField', function () {
      var _this = this;

      return function (option) {
        var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        var searchField = _this.searchField;
        var value = (option[searchField] || '').toLowerCase();
        return value.indexOf(search.toLowerCase().trim());
      };
    }),
    onOpen: function onOpen() {},
    onClose: function onClose() {},
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('selectedOption', this.getOptionById(this.value));
    },
    actions: {
      onRichFocus: function onRichFocus(_, event) {
        if (isTrigger(event.target)) {
          this.setFocused(true);
          this.onFocus(event);
        }
      },
      onRichBlur: function onRichBlur(_, event) {
        if (isTrigger(event.target)) {
          this.setFocused(false);
          this.setTouched(true);
          this.onBlur(event);
        }
      },
      changeRichValue: function changeRichValue(optionOrValue) {
        var idField = this.optionIdField;
        var id = optionOrValue[idField];
        this.set('value', id);
        this.onChange(id);
      },
      registerRichApi: function registerRichApi(api) {
        this.set('richApi', api);
      },
      onRichSelectKeyboardFocus: function onRichSelectKeyboardFocus(event) {
        // On mobile when user navigates through form controls using native on-screen keyboard,
        // he/she navigates only via native input/select elements (iPhone).
        //
        // When rich-select displays power-select-based component,
        // keyboard skips such component during navigation.
        //
        // Here, we use additional <select> element to guarantee
        // that user navigates through power-select component.
        // Remove/Insert <select> element in order to hide on-screen keyboard.
        var el = event.target;
        var parent = el.parentElement;
        parent.removeChild(el);
        parent.appendChild(el); // Open overlay

        this.richApi.actions.open();
      }
    }
  });

  _exports.default = _default;
});