define("snapdragon-common/components/fit-dob-fieldset", ["exports", "snapdragon-common/templates/components/fit-dob-fieldset"], function (_exports, _fitDobFieldset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * @typedef DayListItem
   * @property {String} value The day in the form of a string.
   * @property {String} displayValue Representation of the day used to look up translation.
   */

  /**
   * @typedef MonthListItem
   * @property {String} value The month in the form of a string.
   * @property {String} displayValue Representation of the month used to look up translation.
   */
  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    moment: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes
    layout: _fitDobFieldset.default,
    classNames: ['fit-dob-fieldset'],
    classNameBindings: ['hasError:error', 'isDateEmpty:date-empty'],
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Trigger date of birth change when the entire date changes.
       * @param {String} date The date in the format YYYY-MM-DD
       * @returns {*}
       */
      dateChange: function dateChange(date) {
        var _date$split = date.split('-'),
            _date$split2 = _slicedToArray(_date$split, 3),
            year = _date$split2[0],
            month = _date$split2[1],
            day = _date$split2[2];

        return this.dateOfBirthChange(year, month, day);
      },

      /**
       * Trigger date of birth change when a year changes
       * @param year
       */
      yearChange: function yearChange(year) {
        var _EmberGetProperties = Ember.getProperties(this, 'month', 'day'),
            month = _EmberGetProperties.month,
            day = _EmberGetProperties.day;

        this.dateOfBirthChange(year, month, day);
      },

      /**
       * Trigger date of birth change when a day changes
       * @param day
       */
      dayChange: function dayChange(day) {
        var _EmberGetProperties2 = Ember.getProperties(this, 'year', 'month'),
            year = _EmberGetProperties2.year,
            month = _EmberGetProperties2.month;

        return this.dateOfBirthChange.call(this, year, month, day);
      },

      /**
       * Trigger date of birth change when a month changes
       * @param month
       */
      monthChange: function monthChange(month) {
        var _EmberGetProperties3 = Ember.getProperties(this, 'year', 'day'),
            year = _EmberGetProperties3.year,
            day = _EmberGetProperties3.day;

        return this.dateOfBirthChange.call(this, year, month, day);
      }
    },
    // -------------------------------------------------------------------------
    // Events
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Autocomplete attribute.
     *
     * @type {String|null}
     */
    autocomplete: 'off',

    /**
     * Flag to add error class to component. Useful for validation to add an error class.
     * @type {Boolean}
     */
    hasError: false,

    /**
     * Flag to determine whether the user is using a mobile browser
     */
    isMobile: false,

    /**
     * Flag to indicate if `dateOfBirth` is not filled
     * @returns {Boolean}
     */
    isDateEmpty: Ember.computed.empty('dateOfBirth'),

    /**
     * List of months
     * @type {MonthListItem[]}
     */
    months: Ember.computed(function () {
      return [{
        value: '01',
        displayName: 'january'
      }, {
        value: '02',
        displayName: 'february'
      }, {
        value: '03',
        displayName: 'march'
      }, {
        value: '04',
        displayName: 'april'
      }, {
        value: '05',
        displayName: 'may'
      }, {
        value: '06',
        displayName: 'june'
      }, {
        value: '07',
        displayName: 'july'
      }, {
        value: '08',
        displayName: 'august'
      }, {
        value: '09',
        displayName: 'september'
      }, {
        value: '10',
        displayName: 'october'
      }, {
        value: '11',
        displayName: 'november'
      }, {
        value: '12',
        displayName: 'december'
      }];
    }),

    /**
     * List of days within a month of a specific year.
     * @returns {DayListItem[]}
     */
    days: Ember.computed('month', 'year', function () {
      var moment = this.get('moment');
      var year = Ember.get(this, 'year');
      var month = Ember.get(this, 'month'); // In the case that year or month are empty string, moment attempts to build a date based
      // on an invalid string et the date to null instead of invalid

      var date = year && month ? "".concat(Ember.get(this, 'year'), "-").concat(Ember.get(this, 'month')) : null;
      var daysInMonth = moment.moment(date).daysInMonth() || 31;

      var rangeArr = this._range(daysInMonth);

      return rangeArr.map(function (day) {
        return {
          value: day,
          displayName: day
        };
      });
    }),

    /**
     * Month from `dateOfBirth`.
     * @returns {String}
     */
    month: Ember.computed('dateOfBirth', function () {
      var birthday = Ember.get(this, 'dateOfBirth');
      return birthday && birthday.split('-')[1] || '';
    }),

    /**
     * Day from `dateOfBirth`.
     * @returns {String}
     */
    day: Ember.computed('dateOfBirth', function () {
      var birthday = Ember.get(this, 'dateOfBirth');
      return birthday && birthday.split('-')[2] || '';
    }),

    /**
     * Year from `dateOfBirth`.
     * @returns {String}
     */
    year: Ember.computed('dateOfBirth', function () {
      var birthday = Ember.get(this, 'dateOfBirth');
      return birthday && birthday.split('-')[0] || '';
    }),

    /**
     * Initial source value for rendering the components year, month, and day inputs.
     * @type {String|null}
     */
    dateOfBirth: null,
    // -------------------------------------------------------------------------
    // Observers
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Returns an array with a list of the numbers of 1....n
     * @param {Number} n
     * @private
     * @returns {Array}
     */
    _range: function _range(n) {
      return new Array(n).fill().map(function (_, i) {
        return i < 9 ? '0' + (i + 1) : String(i + 1);
      });
    },

    /**
     * Determine if day is within range of days.
     * @param {String} day Used to check whether it is within the range of days.
     * @returns {boolean}
     */
    isDayWithinDaysRange: function isDayWithinDaysRange(day) {
      var days = Ember.get(this, 'days');
      return !!days.findBy('value', day);
    },

    /**
     * Corrects the day if it is not within range and executes onChange function.
     * @param year
     * @param month
     * @param day
     */
    dateOfBirthChange: function dateOfBirthChange(year, month, day) {
      //edge case -- if a user selects their birthday as Leap Day or a day out of the month range and then changes
      //to a year or month without that day -- we need to set this back to 1
      if (day && !this.isDayWithinDaysRange(day)) {
        day = '01';
      }

      return Ember.RSVP.resolve(Ember.get(this, 'onChange')(year, month, day));
    },

    /**
     * Placeholder function for actions or other methods to overwrite
     * @param {String} year Year of the date in the format YYYY
     * @param {String} month Month of the date in the format MM
     * @param {String} day Day of the date in the format DD
     */
    onChange: function onChange(year, month, day) {}
  });

  _exports.default = _default;
});