define("snapdragon-common/constants/keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KEYCODES = _exports.GENERIC = _exports.V = _exports.NUMERIC_END_INDEX = _exports.NUMERIC_START_INDEX = _exports.ENTER = _exports.TAB = _exports.BACKSPACE = void 0;

  var _KEYCODES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Constants for key names
   */
  var BACKSPACE = 'BACKSPACE';
  _exports.BACKSPACE = BACKSPACE;
  var TAB = 'TAB';
  _exports.TAB = TAB;
  var ENTER = 'ENTER';
  _exports.ENTER = ENTER;
  var NUMERIC_START_INDEX = 'NUMERIC_START_INDEX';
  _exports.NUMERIC_START_INDEX = NUMERIC_START_INDEX;
  var NUMERIC_END_INDEX = 'NUMERIC_END_INDEX';
  _exports.NUMERIC_END_INDEX = NUMERIC_END_INDEX;
  var V = 'V'; // Some virtual keyboards send generic keycode, https://stackoverflow.com/questions/36753548/keycode-on-android-is-always-229

  _exports.V = V;
  var GENERIC = 'GENERIC';
  _exports.GENERIC = GENERIC;
  var KEYCODES = (_KEYCODES = {}, _defineProperty(_KEYCODES, BACKSPACE, 8), _defineProperty(_KEYCODES, TAB, 9), _defineProperty(_KEYCODES, ENTER, 13), _defineProperty(_KEYCODES, NUMERIC_START_INDEX, 48), _defineProperty(_KEYCODES, NUMERIC_END_INDEX, 57), _defineProperty(_KEYCODES, V, 86), _defineProperty(_KEYCODES, GENERIC, 229), _KEYCODES);
  _exports.KEYCODES = KEYCODES;
});