define("snore-detection-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('onboarding', function () {
      this.route('data-usage');
      this.route('survey');
    });
    this.route('details', {
      path: 'date/:date'
    });
    this.route('product');
    this.route('help');
    this.route('leave');
  });

  _exports.default = _default;
});