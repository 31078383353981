define("snapdragon-common/components/cw-form-field-group", ["exports", "snapdragon-common/templates/components/cw-form-field-group"], function (_exports, _cwFormFieldGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FLOAT_LABEL_AUTO = _exports.FLOAT_LABEL_NEVER = _exports.FLOAT_LABEL_ALWAYS = void 0;
  var FLOAT_LABEL_ALWAYS = 'always';
  _exports.FLOAT_LABEL_ALWAYS = FLOAT_LABEL_ALWAYS;
  var FLOAT_LABEL_NEVER = 'never';
  _exports.FLOAT_LABEL_NEVER = FLOAT_LABEL_NEVER;
  var FLOAT_LABEL_AUTO = 'auto';
  /**
   * This component implements common behavior for form components:
   * - displays label
   * - displays error
   * - displays any component content (like input, select, etc).
   *
   * **Note**. This component serves to implement more complex components
   * (like `form-text-field`, `form-select-field`, etc) and should not be used in templates directly.
   * Its main goal is to encapsulate common behavior and UI of form components.
   */

  _exports.FLOAT_LABEL_AUTO = FLOAT_LABEL_AUTO;

  var _default = Ember.Component.extend({
    layout: _cwFormFieldGroup.default,

    /**
     * Specifies how to display label. One of `always`, `never` or `auto`.
     * - `always`. Displays label as floated.
     * - `never`. Label never floats.
     * - `auto`. Label floats if component is focused or not empty.
     */
    floatLabel: FLOAT_LABEL_AUTO,

    /**
     * Label text
     */
    label: '',

    /**
     * Specifies if component is focused
     */
    focused: false,

    /**
     * Specifies if component is empty
     */
    empty: false,

    /**
     * Specifies if error should be displayed
     */
    showError: false,

    /**
     * Text of error
     */
    errorText: null,
    dataTestQa: null,
    classNames: ['form-field-group'],
    classNameBindings: ['isLabelFloat', 'isLabelDisplayed', 'focused:is-focused', 'showError:is-error', 'className'],
    isLabelFloat: Ember.computed('empty', 'focused', 'floatLabel', function () {
      var floatLabel = this.floatLabel,
          focused = this.focused,
          empty = this.empty;

      if (floatLabel === FLOAT_LABEL_ALWAYS) {
        return true;
      }

      if (floatLabel === FLOAT_LABEL_NEVER) {
        return false;
      }

      return focused || !empty;
    }),
    isLabelDisplayed: Ember.computed('floatLabel', 'empty', function () {
      var floatLabel = this.floatLabel,
          empty = this.empty;
      return floatLabel === FLOAT_LABEL_NEVER ? empty : true;
    })
  });

  _exports.default = _default;
});