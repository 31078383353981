define("snapdragon-common/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Convert each first letter of word within a given string in uppercase.
   *
   * @param {String} str
   * @returns {String}
   */
  function titleize(str) {
    if (typeof str === 'string' && str.length) {
      return str.replace(/[_\W]/g, ' ') // convert all non-whitelisted chars into spaces
      .toLowerCase() // convert any other chars to lowercase
      .replace(/\s*\b(\w)/g, function (a, b) {
        return " ".concat(b.toUpperCase());
      }) // convert each first letter of word to uppercase
      .trim(); // trim spaces
    }

    return '';
  }

  var titleizeHelper = function titleizeHelper(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        str = _ref2[0];

    return titleize(str);
  }; // wrap to support hbs arguments on input


  var _default = Ember.Helper.helper(titleizeHelper);

  _exports.default = _default;
});