define("snapdragon-common/helpers/format-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /*
   * Formats a duration of seconds into a human readable form
   * @param Array the array of arguments where the first is the duration in seconds, the
   * second param is the style "short" (1 h 5 m), "medium" (1 hr 5 min), "long" (1 hour 5 minutes)
   */
  var _default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          durationInSeconds = _ref2[0],
          _ref2$ = _ref2[1],
          style = _ref2$ === void 0 ? 'long' : _ref2$;

      (false && !(['short', 'medium', 'long'].includes(style)) && Ember.assert("The style should be one of short, medium, or long but received ".concat(style), ['short', 'medium', 'long'].includes(style)));
      return [this._getHours(durationInSeconds, style), this._getMinutes(durationInSeconds, style), this._getSeconds(durationInSeconds, style)].filter(function (e) {
        return e != null;
      }).join(' ');
    },
    _getHours: function _getHours(duration, style) {
      var formatter = this.get('i18n');
      var formatStringHours = "duration_hours_".concat(style);
      var hours = Math.floor(duration / 3600);
      var formattedString = null;

      if (hours > 1) {
        formattedString = formatter.format(formatStringHours, {
          hours: Math.floor(duration / 3600)
        });
      } else if (hours === 1) {
        formattedString = formatter.format(style == 'long' ? "".concat(formatStringHours, "_singular") : formatStringHours, {
          hours: Math.floor(duration / 3600)
        });
      }

      return formattedString;
    },
    _getMinutes: function _getMinutes(duration, style) {
      var formatter = this.get('i18n');
      var formatStringMinutes = "duration_minutes_".concat(style);
      var minutes = Math.floor(duration % 3600 / 60);
      var formattedString = null;

      if (minutes > 1) {
        formattedString = formatter.format(formatStringMinutes, {
          minutes: minutes
        });
      } else if (minutes === 1) {
        formattedString = formatter.format(style == 'long' ? "".concat(formatStringMinutes, "_singular") : formatStringMinutes, {
          minutes: minutes
        });
      }

      return formattedString;
    },
    _getSeconds: function _getSeconds(duration, style) {
      var formatter = this.get('i18n');
      var formatStringSeconds = "duration_seconds_".concat(style);
      var seconds = duration % 60;
      var formattedString = null;

      if (seconds > 0 && seconds < 60) {
        formattedString = formatter.format(formatStringSeconds, {
          seconds: seconds
        });
      } else if (seconds === 1) {
        formattedString = formatter.format(style == 'long' ? "".concat(formatStringSeconds, "_singular") : formatStringSeconds, {
          seconds: seconds
        });
      }

      return formattedString;
    }
  });

  _exports.default = _default;
});