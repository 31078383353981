define("consent-data/serializers/consent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    /**
     * Override to prevent pluralizing model name.
     * @override
     * @param modelName
     * @returns {string}
     */
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return modelName;
    },

    /**
     * Change consent ID to a composite of <ID>::<category> because consent IDs are unique to the category.
     * Change category attribute into a relationship.
     *
     * @override
     * @param store
     * @param primaryModelClass
     * @param payload
     * @returns {*}
     */
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      var data = payload.data;

      if (Ember.isArray(data)) {
        payload.data = data = data[0];
      }

      var _data = data,
          id = _data.id,
          attributes = _data.attributes,
          category = _data.attributes.category,
          _data$relationships = _data.relationships,
          relationships = _data$relationships === void 0 ? {} : _data$relationships;
      delete attributes.category;
      Ember.set(data, 'id', "".concat(id, "::").concat(category));
      Ember.set(relationships, 'category', {
        data: {
          id: category,
          type: 'consent-category'
        }
      });
      Ember.set(data, 'relationships', relationships);
      return this._super.apply(this, arguments);
    },

    /**
     * Serialized data needs to change the composite ID to the actually consent ID.
     * Endpoint currently expects an array of resource objects.
     *
     * @override
     * @param snapshot
     * @returns {object}
     */
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      var data = json.data;
      var record = snapshot.record;
      var consentId = Ember.get(record, 'consentId');
      Ember.set(data, 'id', consentId);
      Ember.set(json, 'data', [data]);
      return json;
    }
  });

  _exports.default = _default;
});