define('ember-svg-jar/utils/make-svg', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatAttrs = formatAttrs;
  exports.symbolUseFor = symbolUseFor;
  exports.inlineSvgFor = inlineSvgFor;
  exports.default = makeSvg;
  function formatAttrs(attrs) {
    return Object.keys(attrs).map(function (key) {
      return !Ember.isNone(attrs[key]) && key + '="' + attrs[key] + '"';
    }).filter(function (attr) {
      return attr;
    }).join(' ');
  }

  function symbolUseFor(assetId) {
    var attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return '<svg ' + formatAttrs(attrs) + '><use xlink:href="' + assetId + '" /></svg>';
  }

  function inlineSvgFor(assetId, getInlineAsset) {
    var attrs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var asset = getInlineAsset(assetId);

    if (!asset) {
      // eslint-disable-next-line no-console
      console.warn('ember-svg-jar: Missing inline SVG for ' + assetId);
      return;
    }

    var svgAttrs = asset.attrs ? Ember.merge((0, _emberCopy.copy)(asset.attrs), attrs) : attrs;
    var size = attrs.size;


    if (size) {
      svgAttrs.width = parseFloat(svgAttrs.width) * size || svgAttrs.width;
      svgAttrs.height = parseFloat(svgAttrs.height) * size || svgAttrs.height;
      delete svgAttrs.size;
    }

    return '<svg ' + formatAttrs(svgAttrs) + '>' + asset.content + '</svg>';
  }

  function makeSvg(assetId) {
    var attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var getInlineAsset = arguments[2];

    if (!assetId) {
      // eslint-disable-next-line no-console
      console.warn('ember-svg-jar: asset name should not be undefined or null');
      return;
    }

    var isSymbol = assetId.lastIndexOf('#', 0) === 0;
    var svg = isSymbol ? symbolUseFor(assetId, attrs) : inlineSvgFor(assetId, getInlineAsset, attrs);

    return Ember.String.htmlSafe(svg);
  }
});