define("snapdragon-common/components/input-and-label-row", ["exports", "snapdragon-common/templates/components/input-and-label-row"], function (_exports, _inputAndLabelRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _inputAndLabelRow.default,
    type: 'text',

    /**
     * Stub for passing in function called on focus out to enable validation
     */
    enableValidationOnFocusOut: function enableValidationOnFocusOut() {},

    /**
     * Stub for passing in validation on input
     */
    validateOnInput: function validateOnInput() {}
  });

  _exports.default = _default;
});