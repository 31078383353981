define("ember-fitbit-pwd-auth/authenticators/fitbit-password", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger = Ember.Logger;

  var _default = _oauth2PasswordGrant.default.extend({
    sessionRefresh: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    emberFitbitPwdAuthConfig: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
      return Ember.get(config, 'ember-fitbit-pwd-auth') || {};
    }),
    enableRefreshToken: Ember.computed(function () {
      return Ember.get(this, 'emberFitbitPwdAuthConfig.enableRefreshToken');
    }),
    serverTokenEndpoint: Ember.computed(function () {
      return Ember.get(this, 'emberFitbitPwdAuthConfig.serverTokenEndpoint');
    }),
    expirationTimePercentageForRefreshToken: 0.90,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (Ember.get(this, 'enableRefreshToken')) {
        /**
         * Only attach in non-test env otherwise an error will throw when browser is refreshed or closed.
         */
        var _ref = Ember.getOwner(this).resolveRegistration('config:environment') || {},
            environment = _ref.environment;

        if (environment !== 'test') {
          window.addEventListener('beforeunload', function () {
            Ember.get(_this, 'sessionRefresh').unregisterAsRefreshTokenTab();
          });
        }
      }
    },

    /**
     Over-rides the ember-simple-auth/authenticators/oauth2-password-grant.js restore() method and allows
     to call the _refreshAccessToken() method without checking the refresh token expiration date
     @param {Object} data The refresh token present in the ember_simple_auth:session cookie
     @return {Promise} A promise that when it resolves results in the session becoming or remaining authenticated
     @public
     */
    restore: function restore(data) {
      var _this2 = this;

      if (!Ember.get(this, 'enableRefreshToken') || !data.refresh_token) {
        if (Ember.get(this, 'enableRefreshToken')) {
          Ember.set(this, 'refreshAccessTokens', !!data.refresh_token);
        }

        return this._super.apply(this, arguments);
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (_this2.isRefreshTokenNeeded(data) && Ember.get(_this2, 'sessionRefresh').tryRegisterAsRefreshTokenTab()) {
            _this2._refreshAccessToken(data.expires_in, data.refresh_token, data).then(resolve, reject);
          } else {
            resolve(data);
          }
        });
      }
    },
    isRefreshTokenNeeded: function isRefreshTokenNeeded(sessionObject) {
      var refreshAccessTokens = sessionObject.refresh_token;
      var expiresAt = sessionObject.expires_at;
      var expiresIn = sessionObject.expires_in * 1000;

      if (!refreshAccessTokens || !expiresAt || !expiresIn) {
        return false;
      }

      return expiresAt - Date.now() < expiresIn * Ember.get(this, 'expirationTimePercentageForRefreshToken');
    },

    /**
     Makes the refresh access token call.
     - enableRefreshToken false: If it is successful it updates the session information and schedules the next token refresh
     - enableRefreshToken true: If it is successful it updates the session information
     @param {String} refreshToken The refresh token present in the ember_simple_auth:session cookie
     @param {Number} expiresIn Seconds to token expiration date
     @param {Object} sessionData current session data
     @return {Promise}
     @private
     */
    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken, sessionData) {
      var _this3 = this;

      if (!Ember.get(this, 'enableRefreshToken')) {
        return this._super.apply(this, arguments);
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var data = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken
          };
          var serverTokenEndpoint = Ember.get(_this3, 'serverTokenEndpoint');

          _this3.makeRequest(serverTokenEndpoint, data).then(function (response) {
            // eslint-disable-line camelcase
            Ember.run(function () {
              expiresIn = response.expires_in || expiresIn;
              refreshToken = response.refresh_token || refreshToken;

              var expiresAt = _this3._absolutizeExpirationTime(expiresIn);

              var updatedData = Ember.assign(response, {
                expires_in: expiresIn,
                expires_at: expiresAt,
                refresh_token: refreshToken
              }); // eslint-disable-line camelcase

              Ember.get(_this3, 'sessionRefresh').setSuccessfulRefreshTimestamp();

              _this3.trigger('sessionDataUpdated', updatedData);

              resolve(updatedData);
            });
          }, function (xhr, status, error) {
            Logger.warn("Access token could not be refreshed - server responded with ".concat(error, "."));

            if (xhr.status === 401) {
              if (_this3.get('session.isAuthenticated')) {
                _this3.get('session').invalidate();
              }

              reject(xhr.status);
            } else if (xhr.status === 400) {
              resolve(sessionData);
            } else {
              Logger.warn('Refresh token failed due to a connection error.');
              reject(xhr.status);
            }
          });
        });
      }
    },
    _absolutizeExpirationTime: function _absolutizeExpirationTime(expiresIn) {
      if (!Ember.isEmpty(expiresIn)) {
        return new Date(new Date().getTime() + expiresIn * 1000).getTime();
      }
    },
    makeRequest: function makeRequest(url, data) {
      var config = Ember.get(this, 'emberFitbitPwdAuthConfig');

      var _EmberGetProperties = Ember.getProperties(config, 'clientId', 'enableRefreshToken'),
          clientId = _EmberGetProperties.clientId,
          enableRefreshToken = _EmberGetProperties.enableRefreshToken;

      var sessionData = Ember.get(this, 'sessionData').serialize();
      (false && !(!!clientId) && Ember.assert('clientId is missing from ember-fitbit-pwd-auth config.', !!clientId));
      (false && !(!/:/.test(clientId)) && Ember.assert('The clientId should not include a secret or end with a colon', !/:/.test(clientId)));

      if (sessionData) {
        data['session-data'] = sessionData;
      }

      data.client_id = clientId;
      data.access_token = 'on';
      data.enableRefreshToken = !!enableRefreshToken;
      return this._super(url, data);
    }
  });

  _exports.default = _default;
});