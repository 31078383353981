define("snapdragon-common/templates/components/fit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMyEnvJE",
    "block": "{\"symbols\":[\"flash\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row column flash-message-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"modalFlashMessagesQueue\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"flash-message\",null,[[\"flash\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"close-button\"],[11,\"data-close\",\"\"],[12,\"aria-label\",[27,\"format-by-namespace\",[\"aria_close_modal\",\"common.aria\"],null]],[11,\"data-test-qa\",\"fit-modal-close\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/fit-modal.hbs"
    }
  });

  _exports.default = _default;
});