define("covid-survey-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('survey', function () {
      this.route('additional-details');
      this.route('age');
      this.route('covid-approx-start');
      this.route('covid-severity');
      this.route('covid-symptom-dates');
      this.route('covid-symptoms');
      this.route('covid-when-take');
      this.route('diagnosis');
      this.route('flu-symptom-dates');
      this.route('flu-symptoms');
      this.route('flu-when-take');
      this.route('height-weight');
      this.route('med-conditions');
      this.route('no-positive-test-dates');
      this.route('no-positive-test-symptoms');
      this.route('not-tested');
      this.route('not-tested-symptoms');
      this.route('other-test-taken');
      this.route('other-when-take');
      this.route('other-symptoms');
      this.route('other-symptom-dates');
      this.route('serological-date-range');
      this.route('serological-severity');
      this.route('serological-symptoms');
      this.route('serological-when-take');
      this.route('sex');
      this.route('smoke');
      this.route('tests-taken');
      this.route('zipcode');
      this.route('breather-serological');
      this.route('breather-flu');
      this.route('breather-other');
      this.route('follow-up');
    });
    this.route('landing-page');
    this.route('not-eligible');
    this.route('consent-agreement');
    this.route('consent-signature');
    this.route('thank-you');
    this.route('continued-involvement');
    this.route('help');
    this.route('withdrawn');
    this.route('study-finished');
  });

  _exports.default = _default;
});