define("ember-fitbit-pwd-auth/services/storage-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    REFRESH_TOKEN_TAB_KEY: 'refresh-token-tab',
    // uuid/v4 generated
    LAST_REFRESH_TIMESTAMP: 'last-refresh-ts',
    // storage key for the last token refresh timestamp
    REFRESH_TOKEN_INTERVAL: 'refresh-token-interval-min' // storage key for the current refresh interval in minutes

  });

  _exports.default = _default;
});