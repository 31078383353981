define("snapdragon-common/errors/private-data-error", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Private Data Error can be used catch any 403 errors from endpoints that return this when data is private
   * Example usage: /
   *
   * This error type is just used to ensure distinction in error handling from a normal 400 and 500 error.
   */
  var _default = _emberData.default.AdapterError.extend({
    message: 'Private Data Error'
  });

  _exports.default = _default;
});