define("snapdragon-common/mixins/log-history-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LogsByWeek = void 0;

  /**
   * Logs grouped by week. This class was created to update logs and dates properties when the source `model` is updated.
   *
   * Observing the source prevents the need to recreate objects that represent logs grouped by week every time the source
   * was modified.
   *
   * @class LogsByWeek
   */
  var LogsByWeek = Ember.Object.extend({
    moment: null,
    YEAR_MONTH_DAY: null,
    startDate: null,
    endDate: null,

    /**
     * Storage for logs.
     * @returns {Array}
     */
    logs: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * Storage for dates of logs.
     */
    dates: Ember.computed('logs.[]', function () {
      var moment = Ember.get(this, 'moment');
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      return Ember.A(Ember.get(this, 'logs').map(function (log) {
        return moment.moment(Ember.get(log, 'date')).format(YEAR_MONTH_DAY);
      }));
    })
  });
  _exports.LogsByWeek = LogsByWeek;

  var _default = Ember.Mixin.create({
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    startDayOfWeek: Ember.computed.readOnly('currentUser.startDayOfWeek'),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),

    /**
     * Groups logs by week.
     *
     * Expects an already sorted `logList` in descending order.
     *
     * @param {Array} logList An array of log objects. Must include 'date' property
     * @param {Map} weeksMap An array of week objects.
     * @returns {Map<String, LogsByWeek>} log objects grouped by week
     **/
    logByWeek: function logByWeek() {
      var _this = this;

      var logList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var weeksMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Map();
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      var moment = Ember.get(this, 'moment');

      if (weeksMap.size) {
        /**
         * Cleanup the logs for each week by removing logs that is not in the list.
         */
        weeksMap.forEach(function (week) {
          var logs = Ember.get(week, 'logs');

          if (Ember.isEmpty(logList)) {
            logs.clear();
          } else {
            var logsToRemove = logs.filter(function (log) {
              return !logList.includes(log);
            });
            logs.removeObjects(logsToRemove);
          }
        });
      }

      if (Ember.isPresent(logList)) {
        var startDayOfWeekOffset = Ember.get(this, 'startDayOfWeek') === 'SUNDAY' ? 0 : 6;
        var mostRecentLogDate = moment.moment(Ember.get(logList, 'firstObject.date'));
        var daysToSubtract = (mostRecentLogDate.isoWeekday() + startDayOfWeekOffset) % 7;
        var beginningOfWeek = mostRecentLogDate.clone().subtract(daysToSubtract, 'days').format(YEAR_MONTH_DAY);
        var week = this.getOrCreateLogsByWeek(beginningOfWeek, weeksMap);
        logList.forEach(function (log) {
          var date = moment.moment(Ember.get(log, 'date'));

          if (!date.isSameOrAfter(beginningOfWeek)) {
            _this._addWeek(beginningOfWeek, weeksMap, week);

            while (date.isBefore(beginningOfWeek)) {
              beginningOfWeek = moment.moment(beginningOfWeek).subtract(1, 'weeks').format(YEAR_MONTH_DAY);
            }

            week = _this.getOrCreateLogsByWeek(beginningOfWeek, weeksMap);
          }
          /**
           * Add the log to `week.logs` if `week.logs` doesn't include the log.
           *
           * `addObject` will push the object onto the end of the array if it is not already present in the array.
           */


          Ember.get(week, 'logs').addObject(log);
        });

        this._addWeek(beginningOfWeek, weeksMap, week);
      }

      return weeksMap;
    },

    /**
     * Get the LogsByWeek instance from weeksMap or create one.
     * @param {String} dateStr
     * @param {Map} weeksMap
     * @returns {LogsByWeek}
     */
    getOrCreateLogsByWeek: function getOrCreateLogsByWeek(dateStr, weeksMap) {
      var week;

      if (weeksMap) {
        week = weeksMap.get(dateStr);
      }

      if (!week) {
        week = LogsByWeek.create({
          moment: Ember.get(this, 'moment'),
          YEAR_MONTH_DAY: Ember.get(this, 'YEAR_MONTH_DAY')
        });
      }

      return week;
    },

    /**
     * Sets the `startDate` and `endDate` moment instances to the week object before pushing into array of week objects.
     * @param {String} beginningOfWeek Date string of the beginning of week
     * @param {Map} weeksMap Array of week objects that contain list of logs
     * @param {LogsByWeek} week Log objects that belong to the same week
     * @private
     */
    _addWeek: function _addWeek(beginningOfWeek, weeksMap, week) {
      if (!weeksMap.has(beginningOfWeek)) {
        var moment = Ember.get(this, 'moment');
        var startDate = moment.moment(beginningOfWeek);
        Ember.setProperties(week, {
          startDate: startDate,
          endDate: startDate.clone().add(6, 'days')
        });
        weeksMap.set(beginningOfWeek, week);
      }
    }
  });

  _exports.default = _default;
});