define("snapdragon-common/components/prototype-feedback", ["exports", "snapdragon-common/templates/components/prototype-feedback", "snapdragon-common/constants/prototype-feedback"], function (_exports, _prototypeFeedback, _prototypeFeedback2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @typedef {Object} Feedback
   * @property {string} helpfulResponse - String indicating [NOT|SLIGHTLY|FAIRLY|HELPFUL|VERY]
   * @property {string} helpfulFeedback - text input feedback from user
   */
  var _default = Ember.Component.extend({
    layout: _prototypeFeedback.default,
    tagName: '',
    RESPONSE_NOT: _prototypeFeedback2.RESPONSE_NOT,
    RESPONSE_SLIGHTLY: _prototypeFeedback2.RESPONSE_SLIGHTLY,
    RESPONSE_FAIRLY: _prototypeFeedback2.RESPONSE_FAIRLY,
    RESPONSE_HELPFUL: _prototypeFeedback2.RESPONSE_HELPFUL,
    RESPONSE_VERY: _prototypeFeedback2.RESPONSE_VERY,

    /**
     * Feedback survey submission form
     * @returns {Object}
     */
    feedback: Ember.computed(function () {
      return {
        helpfulResponse: null,
        helpfulFeedback: ''
      };
    }),

    /**
     * Action stub to be replaced by parent
     */
    logTap: Ember.computed(function () {
      (false && !(false) && Ember.assert('prototype-feedback: logTap action must be defined by parent'));
    }),

    /**
     * Survey Name to be replaced by parent
     */
    surveyName: Ember.computed(function () {
      (false && !(false) && Ember.assert('prototype-feedback: surveyName property must be defined by parent'));
    }),

    /**
     * Action stub to be replaced by parent
     */
    submitSurvey: Ember.computed(function () {
      (false && !(false) && Ember.assert('prototype-feedback: submitSurvey action must be defined by parent'));
    }),
    actions: {
      submitSurvey: function submitSurvey(feedback, surveyName) {
        return this.submitSurvey(feedback, surveyName);
      }
    }
  });

  _exports.default = _default;
});