define("snapdragon-common/templates/components/fit-select-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1qgWfiZL",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[7,\"select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],[23,[\"selectAction\"]]],[[\"value\"],[\"target.value\"]]]],[12,\"disabled\",[21,\"disabled\"]],[12,\"data-test-qa\",[27,\"if\",[[23,[\"dataQa\"]],[23,[\"dataQa\"]],[23,[\"className\"]]],null]],[12,\"autocomplete\",[27,\"if\",[[23,[\"autocomplete\"]],[23,[\"autocomplete\"]]],null]],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"emptyLabel\"]]],null,{\"statements\":[[0,\"        \"],[7,\"option\"],[11,\"value\",\"\"],[9],[1,[21,\"emptyLabel\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"list\"]]],[[\"key\"],[\"value\"]],{\"statements\":[[0,\"        \"],[7,\"option\"],[12,\"value\",[22,1,[\"value\"]]],[12,\"selected\",[27,\"eq\",[[23,[\"selectedValue\"]],[22,1,[\"value\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"bypassTranslations\"]]],null,{\"statements\":[[0,\"                \"],[1,[22,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"translationNamespace\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"format-by-namespace\",[[22,1,[\"displayName\"]],[23,[\"translationNamespace\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[27,\"format\",[[22,1,[\"displayName\"]]],null],false],[0,\"\\n            \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/fit-select-menu.hbs"
    }
  });

  _exports.default = _default;
});