define("consent-data/serializers/gdpr-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _emberData.default.JSONAPISerializer.extend({
    /**
     * Normalize a single response payload to have relationships with many consents and belongs to category. Also create
     * included resource objects for the relationships so that an additional request is not necessary. The `included`
     * and `relationships` are not included in the payload so they are added here.
     *
     * We can expect that if a consent is required, it will show up in required-consents field and the consent's
     * agree property will be false. Otherwise the consent will not show up in in the required-consents field.
     *
     * @override
     * @param store
     * @param primaryModelClass
     * @param payload
     * @returns {*}
     */
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      var data = payload.data,
          _payload$included = payload.included,
          included = _payload$included === void 0 ? [] : _payload$included,
          _payload$meta = payload.meta,
          meta = _payload$meta === void 0 ? {} : _payload$meta;
      var attributes = data.attributes;
      var requiredConsents = attributes['required-consents'],
          contentBaseUrl = attributes['content-base-url'];
      var relationships = Ember.getWithDefault(data, 'relationships', {});
      delete attributes['required-consents'];
      delete attributes['content-base-url'];
      var categoryRelationship = {
        data: {
          type: 'consent-category',
          id: 'gdpr'
        }
      };
      var requiredConsentsRelationship;

      if (Ember.isPresent(requiredConsents)) {
        var categoryId = categoryRelationship.data.id;
        var consents = requiredConsents.map(function (requiredConsent) {
          return {
            id: "".concat(requiredConsent, "::").concat(categoryId),
            type: 'consent',
            attributes: {
              'consent-id': requiredConsent,
              agree: false
            },
            relationships: {
              category: categoryRelationship
            }
          };
        });
        included.push.apply(included, _toConsumableArray(consents));
        requiredConsentsRelationship = consents.map(function (consent) {
          var type = consent.type,
              id = consent.id;
          return {
            type: type,
            id: id
          };
        });
        Ember.set(relationships, 'consents', {
          data: requiredConsentsRelationship
        });
      } else {
        requiredConsentsRelationship = [];
      }

      Ember.set(relationships, 'category', categoryRelationship);
      Ember.set(data, 'relationships', relationships);
      included.push({
        type: 'consent-category',
        id: 'gdpr',
        relationships: {
          consents: {
            data: requiredConsentsRelationship
          }
        }
      });
      Ember.set(payload, 'included', included);
      Ember.set(meta, 'consent-content-base-url', contentBaseUrl);
      Ember.set(attributes, 'meta', meta);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});