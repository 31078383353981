define("ember-fitbit-pwd-auth/services/session-refresh", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger = Ember.Logger;
  var uuid = (0, _uuid.default)();
  /**
   Returns the number of minutes passed since the given timestamp
   @param {Number} timestamp UTC timestamp
   @return {Number} minutes passed since the given timestamp
   @private
   */

  var _timeLapsedSince = function _timeLapsedSince(timestamp) {
    return (Date.now() - timestamp) / 60000; // 60 * 1000 milliseconds in a minute
  };

  var _default = Ember.Service.extend({
    uuid: uuid,
    browserStorage: Ember.inject.service(),
    storageKeys: Ember.inject.service(),
    refreshTokenTabKey: Ember.computed(function () {
      return Ember.get(this, 'storageKeys.REFRESH_TOKEN_TAB_KEY');
    }),
    lastRefreshTsKey: Ember.computed(function () {
      return Ember.get(this, 'storageKeys.LAST_REFRESH_TIMESTAMP');
    }),
    currentRefreshTokenIntervalKey: Ember.computed(function () {
      return Ember.get(this, 'storageKeys.REFRESH_TOKEN_INTERVAL');
    }),

    /**
     Checks whether the current tab is the designated one to update the access token.
     If it's not and the last refresh attempt was not successful it sets the current tab as the designated one
     @return {Boolean} Whether the current tab registered as the designated refresh access token tab
     @public
     */
    tryRegisterAsRefreshTokenTab: function tryRegisterAsRefreshTokenTab() {
      var success = false;
      var refreshTokenTabKey = Ember.get(this, 'refreshTokenTabKey');
      var lastRefreshTsKey = Ember.get(this, 'lastRefreshTsKey');
      var currentRefreshTokenIntervalKey = Ember.get(this, 'currentRefreshTokenIntervalKey');
      var storage = Ember.get(this, 'browserStorage');
      var currentRefreshTabUuid = storage.getItem(refreshTokenTabKey);
      var uuid = Ember.get(this, 'uuid');

      if (!currentRefreshTabUuid) {
        storage.setItem(refreshTokenTabKey, uuid);
        success = true;
      } else {
        var isRefreshTokenTab = this._isRefreshTokenTab(refreshTokenTabKey, uuid);

        var currentRefreshTokenInterval = storage.getItem(currentRefreshTokenIntervalKey);
        var lastUpdateTs = storage.getItem(lastRefreshTsKey);

        var lapsedTimeSinceLastUpdateTs = _timeLapsedSince(lastUpdateTs);

        if (isRefreshTokenTab) {
          success = !lastUpdateTs || lapsedTimeSinceLastUpdateTs >= currentRefreshTokenInterval;
        } else {
          success = !lastUpdateTs || lapsedTimeSinceLastUpdateTs > 2 * currentRefreshTokenInterval;
        }

        if (success) {
          storage.setItem(refreshTokenTabKey, uuid);
        }
      }

      Logger.debug("Trying to register as refresh token tab... ".concat(success ? 'SUCCESS' : 'FAILED', "."));
      return success;
    },

    /**
     Clears the persisted designated tab information.
     @public
     */
    unregisterAsRefreshTokenTab: function unregisterAsRefreshTokenTab() {
      if (this._isRefreshTokenTab(Ember.get(this, 'refreshTokenTabKey'), Ember.get(this, 'uuid'))) {
        Ember.get(this, 'browserStorage').removeItem(Ember.get(this, 'refreshTokenTabKey'));
        Logger.debug('Unregistered as refresh token tab.');
      }
    },

    /**
     Persists the current timestamp as the last successful access token refresh.
     @public
     */
    setSuccessfulRefreshTimestamp: function setSuccessfulRefreshTimestamp() {
      Ember.get(this, 'browserStorage').setItem(Ember.get(this, 'lastRefreshTsKey'), Date.now());
      Logger.debug('Set the current timestamp as last successful refresh.');
    },

    /**
     Persists the given timeout period for the refresh access token process
     @param {String} timeout - refresh access token interval timeout in minutes
     @public
     */
    setRefreshTokenIntervalTimeout: function setRefreshTokenIntervalTimeout(timeout) {
      Ember.get(this, 'browserStorage').setItem(Ember.get(this, 'currentRefreshTokenIntervalKey'), timeout);
      Logger.debug('Set the current interval timeout.');
    },

    /**
     Checks the current tab's uuid against the stored uuid
     @param {String} refreshTokenTabKey - storage key for the persisted current designated refresh tab uuid
     @param {String} uuid - current uuid
     @return {Boolean} Whether the stored uuid exists or is equal to the current uuid
     @private
     */
    _isRefreshTokenTab: function _isRefreshTokenTab(refreshTokenTabKey, uuid) {
      var currentRefreshTabUuid = Ember.get(this, 'browserStorage').getItem(refreshTokenTabKey);
      return currentRefreshTabUuid === uuid;
    }
  });

  _exports.default = _default;
});