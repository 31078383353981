define("snapdragon-common/components/verify-email", ["exports", "snapdragon-common/templates/components/verify-email"], function (_exports, _verifyEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.verificationRequiredMessageError = void 0;
  var verificationRequiredMessageError = Error('`verify-email` component is missing a `verificationRequirementMessage` property');
  _exports.verificationRequiredMessageError = verificationRequiredMessageError;

  var _default = Ember.Component.extend({
    layout: _verifyEmail.default,
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    emailVerificationSent: false,

    /**
     * Default values to disable change email validations on open
     */
    disableNewEmailValidation: true,
    disableConfirmValidation: true,
    disablePasswordValidation: true,

    /**
     * Messaging for verification requirement
     * @type {String|null}
     */
    verificationRequirementMessage: Ember.computed({
      get: function get() {
        throw verificationRequiredMessageError;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * No-ops, email actions passed from controller
     * called from sendEmailVerification and passed into change-email
     */
    onSaveEmailAddress: function onSaveEmailAddress() {},
    onResendEmailAddress: function onResendEmailAddress() {},
    onCancelEmailAddress: function onCancelEmailAddress() {},
    actions: {
      /**
       * Will hit /verifyEmail/send to send user email verification
       */
      sendEmailVerification: function sendEmailVerification() {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        return Ember.get(this, 'onResendEmailAddress')().then(function () {
          flashMessages.success(formatter.formatByNamespace('verification_sent', 'templates.components.account-deletion'));
          var hostModal = Ember.get(_this, 'hostModal');

          if (hostModal) {
            hostModal.close();
          }
        }).catch(function (err) {
          var errMessage = Ember.get(err, 'errors.0.message') || Ember.get(err, 'errors.0.title') || formatter.formatByNamespace('err_resending_email', 'templates.components.profile-settings');
          flashMessages.alert(errMessage, {
            isModal: true
          });
        });
      },

      /**
       * Resets values for the change email address component
       */
      resetValuesForChangeEmail: function resetValuesForChangeEmail() {
        Ember.setProperties(this, {
          disableNewEmailValidation: true,
          disableConfirmValidation: true,
          disablePasswordValidation: true,
          newEmail: '',
          confirmNewEmail: '',
          verifyPassword: ''
        });
      }
    }
  });

  _exports.default = _default;
});