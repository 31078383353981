define('ember-tags-input/components/ember-tags-input/component', ['exports', 'ember-tags-input/components/ember-tags-input/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var KEY_CODES = {
    BACKSPACE: 8
  };

  /**
   Component converts a user's typing into tags. New tags are created when the user types a comma, space, semi colon
   or hits the enter key. Tags can be removed using the backspace key or by clicking the x button on each tag.
   Tags can be edited by click on existing tag.
  
   **Usage:**
   ```handlebars
   \{{#ember-tags-input
      tagsData=tags
      onAddTag=(action addTag)
      onAddTags=(action addTags)
      onReplaceTagAtIndex=(action replaceTagAtIndex)
      onReplaceTagWithTagsAtIndex=(action replaceTagWithTagsAtIndex)
      onRemoveTagAtIndex=(action removeTagAtIndex)
      as |tagLabel|
   }}
     {{tagLabel}}
   {{/ember-tags-input}}
   ```
  
   ```javascript
   import Controller from '@ember/controller';
  
   export default Controller.extend({
     tags: null,
  
     init() {
       this._super(...arguments);
  
       this.set('tags', ['tag-1', 'tag-2', 'tag-3']);
     },
  
     addTag(newTag) {
       this.get('tags').addObject(newTag);
     },
  
     addTags(newTags) {
       this.get('tags').addObject(newTags);
     },
  
     replaceTagAtIndex(tag, index) {
       this.get('tags').replace(index, 1, [tag]);
     },
  
     replaceTagWithTagsAtIndex(tags, index) {
       this.get('tags').replace(index, 1, tags);
     },
  
     removeTagAtIndex(index) {
       this.get('tags').removeAt(index);
     }
   });
   ```
  
   @class EmberTagsInput
   @public
   */
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['eti'],

    classNameBindings: ['readOnly:eti-read-only'],

    /**
     An array of tags to render.
      @property tagsData
     @type Array[String]
     @public
     */
    tagsData: null,

    /**
     @property tags
     @type Array[Object]
     @private
     */
    tags: Ember.computed('tagsData.[]', {
      get: function get() {
        var _this = this;

        return Ember.A(this.get('tagsData') || []).map(function (tagLabel) {
          return {
            label: tagLabel,
            editable: _this.isTagEditable(tagLabel),
            classNames: _this.getTagCssClassNames(tagLabel)
          };
        });
      }
    }),

    /**
     If a read only view of the tags should be displayed. If enabled, existing tags can't be edited or removed
     and new tags can't be added.
      @property readOnly
     @type Boolean
     @public
     */
    readOnly: false,

    /**
     Enables tags edit mode.
      @property isEditTagsModeEnabled
     @type Boolean
     @public
     */
    isEditTagsModeEnabled: true,

    /**
     String of svg id for tag remove button.
      @property tagRemoveButtonSvgId
     @type String
     @public
     */
    tagRemoveButtonSvgId: 'eti-cancel',

    /**
     @property newTagInputValue
     @type String
     @private
     */
    newTagInputValue: null,

    /**
     The edit tag placeholder text to display when the user hasn't typed anything.
      @property editTagInputPlaceholder
     @type String
     @public
     */
    editTagInputPlaceholder: 'Enter a tag...',

    /**
     The new tag placeholder text to display when the user hasn't typed anything.
      @property newTagInputPlaceholder
     @type String
     @public
     */
    newTagInputPlaceholder: 'Add a tag...',

    /**
     @property editTagInputMaxLength
     @type Number
     @public
     */
    editTagInputMaxLength: null,

    /**
     @property newTagInputMaxLength
     @type Number
     @public
     */
    newTagInputMaxLength: null,

    /**
     Enables auto width for new tag input.
      @property isAutoNewInputWidthEnabled
     @type Boolean
     @public
     */
    isAutoNewInputWidthEnabled: true,

    /**
     Enables auto width for edit tag input.
      @property isAutoEditInputWidthEnabled
     @type Boolean
     @public
     */
    isAutoEditInputWidthEnabled: true,

    /**
     If 'x' removal links should be displayed at the right side of each tag.
      @property showRemoveButtons
     @type Boolean
     @public
     */
    showRemoveButtons: true,

    onTagClick: function onTagClick(tag) {
      var _this2 = this;

      if (!this.get('readOnly')) {
        this.get('tags').forEach(function (tag) {
          return _this2.disableEditMode(tag);
        });

        this.enableEditMode(tag);

        if (this.get('isAutoEditInputWidthEnabled')) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this2.updateEditInputWidth();
          });
        }

        this.focusEditInput();
      }
    },
    enableEditMode: function enableEditMode(tag) {
      Ember.set(tag, 'editMode', true);
    },
    disableEditMode: function disableEditMode(tag) {
      Ember.set(tag, 'editMode', false);
    },
    isTagEditable: function isTagEditable() {
      return true;
    },
    getTagCssClassNames: function getTagCssClassNames() {
      return '';
    },
    onNewTagInputKeyDown: function onNewTagInputKeyDown(_ref) {
      var _this3 = this;

      var which = _ref.which,
          value = _ref.target.value;

      if (which === KEY_CODES.BACKSPACE) {
        value = value && value.trim();

        if (!value && this.get('tags.length')) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this3.onRemoveTagAtIndex(_this3.get('tags.length') - 1);
          });
        }
      }
    },
    onNewTagInputInput: function onNewTagInputInput(_ref2) {
      var value = _ref2.target.value;

      if (this.hasSplitSymbols(value)) {
        this.onNewTagInputFocusOut(value);
      }

      if (this.get('isAutoNewInputWidthEnabled')) {
        this.updateNewInputWidth();
      }
    },
    onEditTagInputInput: function onEditTagInputInput(_ref3) {
      var label = _ref3.label;

      if (this.hasSplitSymbols(label)) {
        var $input = this.getNewInputElement();
        $input.focus();
      }

      if (this.get('isAutoEditInputWidthEnabled')) {
        this.updateEditInputWidth();
      }
    },
    hasSplitSymbols: function hasSplitSymbols() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      var splitSymbols = this.getSplitSymbols();

      return Ember.A(splitSymbols || []).any(function (splitSymbol) {
        return value.includes(splitSymbol);
      });
    },
    onNewTagInputEnter: function onNewTagInputEnter(value) {
      this.onNewTagInputFocusOut(value);

      if (this.get('isAutoNewInputWidthEnabled')) {
        this.updateNewInputWidth();
      }
    },
    onEditTagInputEnter: function onEditTagInputEnter() {
      var $input = this.getNewInputElement();
      $input.focus();

      if (this.get('isAutoEditInputWidthEnabled')) {
        this.updateEditInputWidth();
      }
    },
    onNewTagInputFocusOut: function onNewTagInputFocusOut(value) {
      var _this4 = this;

      value = value && value.trim();

      if (value) {
        var tagsLabels = this.getTagsFromInputValue(value);

        if (tagsLabels.length === 1) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this4.onAddTag(tagsLabels[0]);
          });
        } else {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this4.onAddTags(tagsLabels);
          });
        }
      }

      Ember.run.scheduleOnce('afterRender', function () {
        var $input = _this4.getNewInputElement();
        $input.val('');

        if (_this4.get('isAutoNewInputWidthEnabled')) {
          _this4.updateNewInputWidth();
        }
      });
    },
    onEditTagInputFocusOut: function onEditTagInputFocusOut(tag, index) {
      var _this5 = this;

      var value = tag.label.trim();

      if (value.length) {
        var tagsLabels = this.getTagsFromInputValue(value);

        if (tagsLabels.length === 1) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this5.onReplaceTagAtIndex(tagsLabels[0], index);
          });
        } else {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this5.onReplaceTagWithTagsAtIndex(tagsLabels, index);
          });
        }
      } else {
        Ember.run.scheduleOnce('afterRender', function () {
          return _this5.onRemoveTagAtIndex(index);
        });
      }

      if (this.get('isAutoEditInputWidthEnabled')) {
        this.updateEditInputWidth();
      }
    },
    getTagsFromInputValue: function getTagsFromInputValue(value) {
      var splitSymbols = this.getSplitSymbols();

      if (splitSymbols && splitSymbols.length) {
        splitSymbols.forEach(function (splitSymbol) {
          value = value.replace(new RegExp(splitSymbol, 'g'), splitSymbols[0]);
        });

        return value.split(splitSymbols[0]).reduce(function (tags, tag) {
          tag = tag.trim();

          return tag ? [].concat(_toConsumableArray(tags), [tag]) : tags;
        }, []);
      }

      return [value];
    },
    getSplitSymbols: function getSplitSymbols() {
      return [' ', ',', ';'];
    },


    /**
     Action which occurs when tag should be added.
      @method onAddTag
     @public
     */
    onAddTag: function onAddTag() {},


    /**
     Action which occurs when tags should be added.
      @method onAddTags
     @public
     */
    onAddTags: function onAddTags() {},


    /**
     Action which occurs when tag should be replaced with a new tag.
      @method onReplaceTagAtIndex
     @public
     */
    onReplaceTagAtIndex: function onReplaceTagAtIndex() {},


    /**
     Action which occurs when tag should be replaced with several new tags.
      @method onReplaceTagWithTagsAtIndex
     @public
     */
    onReplaceTagWithTagsAtIndex: function onReplaceTagWithTagsAtIndex() {},


    /**
     Action which occurs when tag should be removed.
      @method onRemoveTagAtIndex
     @public
     */
    onRemoveTagAtIndex: function onRemoveTagAtIndex() {},
    focusNewInput: function focusNewInput() {
      var _this6 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var $input = _this6.getNewInputElement();
        $input.focus();
      });
    },
    focusEditInput: function focusEditInput() {
      var _this7 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var $input = _this7.getEditInputElement();
        $input.focus();
      });
    },
    updateNewInputWidth: function updateNewInputWidth() {
      var $input = this.getNewInputElement();

      this.updateInputWidth($input);
    },
    updateEditInputWidth: function updateEditInputWidth() {
      var $input = this.getEditInputElement();

      this.updateInputWidth($input);
    },
    updateInputWidth: function updateInputWidth($input) {
      var $inputBuffer = this.getInputBufferElement();

      $inputBuffer.html(this.getEscapedHtml($input.val()));
      $input.width($inputBuffer.width());
    },
    getEscapedHtml: function getEscapedHtml(html) {
      return html.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;").replace(/ /g, '&nbsp;');
    },
    getNewInputElement: function getNewInputElement() {
      return this.$('.eti-new-input');
    },
    getEditInputElement: function getEditInputElement() {
      return this.$('.eti-edit-input');
    },
    getInputBufferElement: function getInputBufferElement() {
      return this.$('.eti-input-buffer');
    }
  });
});