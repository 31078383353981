define("snapdragon-common/constants/program-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUBSCRIPTION = _exports.FREE = void 0;
  var FREE = 'FREE';
  _exports.FREE = FREE;
  var SUBSCRIPTION = 'SUBSCRIPTION';
  _exports.SUBSCRIPTION = SUBSCRIPTION;
});