define("snapdragon-common/templates/components/journal-survey-question-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d9M+skUY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[11,\"data-test-qa\",\"jsq-order-title\"],[9],[1,[23,[\"question\",\"order\"]],false],[0,\". \"],[1,[23,[\"question\",\"title\"]],false],[10],[0,\"\\n\"],[7,\"h4\"],[11,\"data-test-qa\",\"jsq-subtitle\"],[9],[1,[23,[\"question\",\"subtitle\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"journal-survey-slider\"],[11,\"data-test-qa\",\"journal-survey-slider\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showLegend\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"journal-survey-question-slider-legend\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"change\",\"type\",\"min\",\"max\"],[[23,[\"internalValue\"]],[27,\"action\",[[22,0,[]],\"onChange\",[23,[\"internalValue\"]]],null],\"range\",\"0\",[23,[\"responseCount\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showValue\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"journal-survey-question-value-label\"],[9],[1,[21,\"internalValue\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/journal-survey-question-slider.hbs"
    }
  });

  _exports.default = _default;
});