define("health-programs-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('memberships');
    this.route('tags');
    this.route('membership', {
      path: 'memberships/:id'
    });
    this.route('admin', function () {
      this.route('create');
      this.route('testing');
    });
    this.route('logs', {
      path: 'logs/:program_id/:program_version'
    });
  });

  _exports.default = _default;
});