define("snapdragon-common/constants/program-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GCP_ENVS = _exports.AURORA_GCP = _exports.AURORA_SL = void 0;
  var AURORA_SL = 'https://aurora-dal09.site-ops.fitbit.com';
  _exports.AURORA_SL = AURORA_SL;
  var AURORA_GCP = 'http://aurora-scheduler-us-central1.gcp.fitbit.com';
  _exports.AURORA_GCP = AURORA_GCP;
  var GCP_ENVS = ['devel', 'int', 'stage'];
  _exports.GCP_ENVS = GCP_ENVS;
});