define("snapdragon-common/templates/components/sleep-display-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p9IcEmzW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"sleep-display-time\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[27,\"eq\",[[23,[\"hours\"]],0],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"sleep-value\"],[9],[1,[21,\"hours\"],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"sleep-unit\"],[9],[1,[27,\"format-by-namespace\",[\"hours_short\",\"units\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[11,\"class\",\"sleep-value\"],[9],[1,[21,\"minutes\"],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"sleep-unit\"],[9],[1,[27,\"format-by-namespace\",[\"minutes_short\",\"units\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/sleep-display-time.hbs"
    }
  });

  _exports.default = _default;
});