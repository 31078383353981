define("snapdragon-common/mixins/require-scroll-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    needScroll: true,
    percentScrolled: 0,
    scrollReferenceFunction: null,
    _disableButtonUntilScrolled: function _disableButtonUntilScrolled(element) {
      if (!this.needScroll) {
        return;
      } // We use a threshold here, because sometimes the browser doesn't exactly set the two to be the same


      var percentScrolled = element.scrollHeight <= element.clientHeight ? 1 : (Math.round(element.scrollTop) + 1) / (element.scrollHeight - element.clientHeight); // Add one extra pixel for leniency

      var hasScrolled = percentScrolled >= 1;
      Ember.set(this, 'percentScrolled', 100 * percentScrolled);
      Ember.set(this, 'needScroll', !hasScrolled);
    },
    _initScrollEvent: function _initScrollEvent(id) {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        var element = document.getElementById(id);
        _this.scrollReferenceFunction = _this._disableButtonUntilScrolled.bind(_this, element);
        element.addEventListener('scroll', _this.scrollReferenceFunction);

        _this.scrollReferenceFunction();
      });
    },
    _teardownScrollEvent: function _teardownScrollEvent(id) {
      var element = document.getElementById(id);
      element.removeEventListener('scroll', this.scrollReferenceFunction);
    }
  });

  _exports.default = _default;
});