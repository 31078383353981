define('ember-engines/-private/engine-instance-ext', ['@ember/string'], function (_string) {
  'use strict';

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  Ember.EngineInstance.reopen({
    /**
      The root DOM element of the `EngineInstance` as an element or a
      [jQuery-compatible selector
      string](http://api.jquery.com/category/selectors/).
       @private
      @property {String|DOMElement} rootElement
    */
    rootElement: null,

    /**
      A mapping of dependency names and values, grouped by category.
       `dependencies` should be set by the parent of this engine instance upon
      instantiation and prior to boot.
       During the boot process, engine instances verify that their required
      dependencies, as defined on the parent `Engine` class, have been assigned
      by the parent.
       @private
      @property {Object} dependencies
    */
    dependencies: null,

    /**
      A cache of dependency names and values, grouped by engine name.
       This cache is maintained by `buildChildEngineInstance()` for every engine
      that's a child of this parent instance.
       Only dependencies that are singletons are currently allowed, which makes
      this safe.
       @private
      @property {Object} _dependenciesForChildEngines
    */
    _dependenciesForChildEngines: null,

    buildChildEngineInstance: function buildChildEngineInstance(name) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // Check dependencies cached by engine name
      var dependencies = this._dependenciesForChildEngines && this._dependenciesForChildEngines[name];

      // Prepare dependencies if none are cached
      if (!dependencies) {
        dependencies = {};

        var camelizedName = (0, _string.camelize)(name);

        var engineConfiguration = this.base.engines && this.base.engines[camelizedName];

        if (engineConfiguration) {
          var engineDependencies = engineConfiguration.dependencies;

          if (engineDependencies) {
            ['services'].forEach(function (category) {
              if (engineDependencies[category]) {
                dependencies[category] = {};
                var dependencyType = _this._dependencyTypeFromCategory(category);

                for (var i = 0; i < engineDependencies[category].length; i++) {
                  var engineDependency = engineDependencies[category][i];
                  var dependencyName = void 0;
                  var dependencyNameInParent = void 0;

                  if ((typeof engineDependency === 'undefined' ? 'undefined' : _typeof(engineDependency)) === 'object') {
                    dependencyName = Object.keys(engineDependency)[0];
                    dependencyNameInParent = engineDependency[dependencyName];
                  } else {
                    dependencyName = dependencyNameInParent = engineDependency;
                  }

                  var dependencyKey = dependencyType + ':' + dependencyNameInParent;
                  var dependency = _this.lookup(dependencyKey);

                  (false && !(dependency) && Ember.assert('Engine parent failed to lookup \'' + dependencyKey + '\' dependency, as declared in \'engines.' + camelizedName + '.dependencies.' + category + '\'.', dependency));


                  dependencies[category][dependencyName] = dependency;
                }
              }
            });

            if (engineDependencies.externalRoutes) {
              dependencies.externalRoutes = engineDependencies.externalRoutes;
            }
          }
        }

        // Cache dependencies for child engines for faster instantiation in the future
        this._dependenciesForChildEngines = this._dependenciesForChildEngines || {};
        this._dependenciesForChildEngines[name] = dependencies;
      }

      options.dependencies = dependencies;

      return this._super(name, options);
    },
    _getExternalRoute: function _getExternalRoute(routeName) {
      var route = this._externalRoutes[routeName];

      (false && !(route) && Ember.assert('The external route ' + routeName + ' does not exist', route));


      return route;
    },
    cloneParentDependencies: function cloneParentDependencies() {
      var _this2 = this;

      this._super();

      var requiredDependencies = this.base.dependencies;

      if (requiredDependencies) {
        Object.keys(requiredDependencies).forEach(function (category) {
          var dependencyType = _this2._dependencyTypeFromCategory(category);

          if (category === 'externalRoutes') {
            _this2._externalRoutes = {};
          }

          requiredDependencies[category].forEach(function (dependencyName) {
            var dependency = _this2.dependencies[category] && _this2.dependencies[category][dependencyName];

            (false && !(dependency) && Ember.assert('A dependency mapping for \'' + category + '.' + dependencyName + '\' must be declared on this engine\'s parent.', dependency));


            if (category === 'externalRoutes') {
              _this2._externalRoutes[dependencyName] = dependency;
            } else {
              var key = dependencyType + ':' + dependencyName;
              _this2.register(key, dependency, { instantiate: false });
            }
          });
        });
      }
    },
    _dependencyTypeFromCategory: function _dependencyTypeFromCategory(category) {
      switch (category) {
        case 'services':
          return 'service';
        case 'externalRoutes':
          return 'externalRoute';
      }
      (false && !(false) && Ember.assert('Dependencies of category \'' + category + '\' can not be shared with engines.', false));
    },
    didCreateRootView: function didCreateRootView(view) {
      view.appendTo(this.rootElement);
    }
  });
});