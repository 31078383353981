define("consent-data/utils/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.join = join;
  _exports.split = split;
  _exports.DELIMITER = void 0;

  /**
   * Delimiter between consent ID and category
   * @type {string}
   */
  var DELIMITER = '::';
  /**
   * Join a consent ID and category with the delimeter
   * @param {string} consentId
   * @param {string} category
   * @returns {string}
   */

  _exports.DELIMITER = DELIMITER;

  function join(consentId, category) {
    return [consentId, category].join(DELIMITER);
  }
  /**
   * Split the string with the delimeter.
   * @param {string} str
   * @returns {array}
   */


  function split(str) {
    return str ? str.split(DELIMITER) : [];
  }
});