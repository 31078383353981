define("snapdragon-common/constants/participant-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARTICIPANT_JOINED_STATUS_SET = _exports.PARTICIPANT_STATUS_LEFT = _exports.PARTICIPANT_STATUS_INVITED = _exports.PARTICIPANT_STATUS_INACTIVE = _exports.PARTICIPANT_STATUS_ACTIVE = void 0;
  var PARTICIPANT_STATUS_ACTIVE = 'ACTIVE';
  _exports.PARTICIPANT_STATUS_ACTIVE = PARTICIPANT_STATUS_ACTIVE;
  var PARTICIPANT_STATUS_INACTIVE = 'INACTIVE';
  _exports.PARTICIPANT_STATUS_INACTIVE = PARTICIPANT_STATUS_INACTIVE;
  var PARTICIPANT_STATUS_INVITED = 'INVITED';
  _exports.PARTICIPANT_STATUS_INVITED = PARTICIPANT_STATUS_INVITED;
  var PARTICIPANT_STATUS_LEFT = 'LEFT';
  _exports.PARTICIPANT_STATUS_LEFT = PARTICIPANT_STATUS_LEFT;
  var PARTICIPANT_JOINED_STATUS_SET = [PARTICIPANT_STATUS_ACTIVE, PARTICIPANT_STATUS_INACTIVE];
  _exports.PARTICIPANT_JOINED_STATUS_SET = PARTICIPANT_JOINED_STATUS_SET;
});