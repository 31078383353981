define("snapdragon-common/components/cw-application-form-mixin", ["exports", "ember-cp-validations", "snapdragon-common/constants/corporate-onboarding-fields", "snapdragon-common/constants/corporate-profile-gender", "snapdragon-common/mixins/validations/date-of-birth"], function (_exports, _emberCpValidations, _corporateOnboardingFields, Gender, _dateOfBirth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIN_USER_AGE = void 0;
  var MIN_USER_AGE = 13;
  _exports.MIN_USER_AGE = MIN_USER_AGE;
  var CW_MAX_LENGTH = 45;
  var GENDER_EMPTY_VALUES = [undefined, null, ''];
  var validMessage = Ember.computed(function () {
    return this.model.i18n.formatByNamespace('cw_field_error', 'models.cw-program');
  });
  var FIELD_NAME_RE = /^fields\.(.*)$/;
  var validDisabled = Ember.computed(function () {
    var fieldName = FIELD_NAME_RE.exec(this.attribute)[1]; // fieldName can be a complex path, like "fields.firstName"

    var fieldDisabled = !(this.model.isFieldDisplayed(fieldName) && this.model.isFieldEnabled(fieldName));
    return fieldDisabled;
  });
  var lengthValidator = (0, _emberCpValidations.validator)('length', {
    max: CW_MAX_LENGTH,
    min: 0,
    message: validMessage,
    disabled: validDisabled
  });

  var backendValidatorByField = function backendValidatorByField(fieldName) {
    return (0, _emberCpValidations.validator)('exclusion', {
      in: Ember.computed.oneWay("model.invalid".concat(fieldName, ".[]")),
      message: Ember.computed.oneWay("model.invalid".concat(fieldName, "Message"))
    });
  };

  var Validations = (0, _emberCpValidations.buildValidations)(Ember.assign({
    'fields.firstName': [lengthValidator, backendValidatorByField('firstName')],
    'fields.lastName': [lengthValidator, backendValidatorByField('lastName')],
    'fields.dateOfBirth': {
      allowBlank: true,
      disabled: validDisabled,
      validators: [(0, _emberCpValidations.validator)('alias', 'birthdate'), backendValidatorByField('dateOfBirth')]
    },
    'fields.corporateEmail': [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: true,
      message: Ember.computed(function () {
        return this.model.i18n.formatByNamespace('email_validation_msg', 'components.profile-settings');
      }),
      disabled: validDisabled
    }), lengthValidator, backendValidatorByField('corporateEmail')],
    'fields.gender': [(0, _emberCpValidations.validator)('inclusion', {
      in: GENDER_EMPTY_VALUES.concat([Gender.NOT_AVAILABLE, Gender.MALE, Gender.FEMALE]),
      message: Ember.computed(function () {
        return this.model.i18n.formatByNamespace('cw_select_field_error', 'models.cw-program');
      }),
      disabled: validDisabled
    }), backendValidatorByField('gender')],
    'fields.phoneNumber': [lengthValidator, backendValidatorByField('phoneNumber')],
    'fields.employeeId': [lengthValidator, backendValidatorByField('employeeId')],
    'fields.jobTitle': [lengthValidator, backendValidatorByField('jobTitle')],
    'fields.customField': [lengthValidator, backendValidatorByField('customField')],
    'fields.additionalInfo': [lengthValidator, backendValidatorByField('additionalInfo')],
    'fields.dimensionsById': [(0, _emberCpValidations.validator)('has-many', {
      disabled: Ember.computed(function () {
        var fieldDisabled = !this.model.isFieldDisplayed('dimensions');
        return fieldDisabled;
      })
    }), backendValidatorByField('sliceIds')]
  }, _dateOfBirth.default));

  var _default = Ember.Mixin.create(Validations, {
    i18n: Ember.inject.service(),
    richSelectThreshold: 10,
    maxYear: new Date().getFullYear() - MIN_USER_AGE,
    isInvalid: Ember.computed('validations.isInvalid', 'fields', 'dimensions.@each.selected', function () {
      var fields = this.fields,
          isInvalid = this.validations.isInvalid,
          displayedFields = this.displayedFields,
          displayedDimensions = this.displayedDimensions; // If field is enabled and it is blank

      var hasBlankField = displayedFields.some(function (field) {
        return Ember.isBlank(fields[field]);
      }); // If dimensions are enabled and some of them are blank

      var hasBlankDimension = displayedDimensions.some(function (dimension) {
        return !dimension.selected;
      });
      return isInvalid || hasBlankField || hasBlankDimension;
    }),
    birthdate: Ember.computed.readOnly('fields.dateOfBirth'),
    genders: Ember.computed(function () {
      return [{
        value: Gender.NOT_AVAILABLE,
        displayName: 'not_available'
      }, {
        value: Gender.MALE,
        displayName: 'male'
      }, {
        value: Gender.FEMALE,
        displayName: 'female'
      }];
    }),
    enabledFields: Ember.computed('fields', function () {
      var _this = this;

      return _corporateOnboardingFields.CORPORATE_ONBOARDING_FIELDS.filter(function (field) {
        return _this.isFieldEnabled(field);
      });
    }),
    hasEnabledField: Ember.computed.notEmpty('enabledFields'),
    displayedFields: Ember.computed('fields', function () {
      var _this2 = this;

      return _corporateOnboardingFields.CORPORATE_ONBOARDING_FIELDS.filter(function (field) {
        return _this2.isFieldDisplayed(field);
      });
    }),
    displayedDimensions: Ember.computed('content', function () {
      var dimensions = this.dimensions;
      return this.isDimensionsDisplayed && dimensions || [];
    }),
    // Should be defined in the implemented class
    fields: null,
    dimensions: null,
    isDimensionsDisplayed: false,
    init: function init() {
      var _this3 = this;

      _corporateOnboardingFields.CORPORATE_ONBOARDING_FIELDS.forEach(function (field) {
        return Ember.set(_this3, "invalid".concat(field), []);
      });

      this._super.apply(this, arguments);
    },
    addInvalidFieldValue: function addInvalidFieldValue(fieldName, invalidValue, errorMessage) {
      var invalidFieldValues = this["invalid".concat(fieldName)];

      if (invalidFieldValues && invalidValue) {
        invalidFieldValues.pushObject(invalidValue);
        Ember.set(this, "invalid".concat(fieldName), invalidFieldValues);
        Ember.set(this, "invalid".concat(fieldName, "Message"), errorMessage);
      }
    },
    setTagValue: function setTagValue(tag, slice) {
      Ember.set(tag, 'selected', slice);
    },

    /**
     * This method should be defined in the implemented class
     */
    // eslint-disable-next-line no-unused-vars
    isFieldDisplayed: function isFieldDisplayed(fieldName) {
      return true;
    },

    /**
     * This method should be defined in the implemented class
     */
    // eslint-disable-next-line no-unused-vars
    isFieldEnabled: function isFieldEnabled(fieldName) {
      return true;
    }
  });

  _exports.default = _default;
});