define("ember-crumbly/templates/components/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5qBLs5ln",
    "block": "{\"symbols\":[\"route\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"routeHierarchy\"]]],null,{\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[22,0,[]],[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"bread-crumb\",null,[[\"route\",\"breadCrumbs\"],[[22,1,[]],[22,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-crumbly/templates/components/bread-crumbs.hbs"
    }
  });

  _exports.default = _default;
});