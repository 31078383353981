define("snapdragon-common/components/fit-footer-links", ["exports", "snapdragon-common/templates/components/fit-footer-links"], function (_exports, _fitFooterLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    layout: _fitFooterLinks.default,
    targetBlank: false,
    localeURLContext: Ember.computed(function () {
      var userLocale = this.get('i18n.userLocale');
      var constants = this.get('constants');
      var urlContext = userLocale === 'en_US' ? '' : constants.getUrlContext(userLocale);
      return urlContext ? "/".concat(urlContext.toLowerCase()) : '';
    }),

    /**
     * Check if the user locale should display links to affiliates.
     */
    hasAffiliates: Ember.computed.match('i18n.userLocale', /(en_US|en_CA)/i),

    /**
     * Check if user locale should display links to investor relations.
     */
    hasInvestorRelations: Ember.computed.match('i18n.userLocale', /en_US/i),
    aboutURL: Ember.computed('localeURLContext', function () {
      return "".concat(this.get('localeURLContext'), "/about");
    }),
    retailersURL: Ember.computed('localeURLContext', function () {
      return "".concat(this.get('localeURLContext'), "/retailers");
    }),
    affiliatesURL: Ember.computed(function () {
      var locale = this.get('i18n.userLocale');
      return locale === 'en_US' ? '/affiliates' : '/us/affiliates';
    }),
    groupHealthURL: Ember.computed('localeURLContext', function () {
      return "".concat(this.get('localeURLContext'), "/group-health");
    }),
    helpURL: Ember.computed(function () {
      var baseURL = 'https://help.fitbit.com';
      var urlContext = this.get('constants.FITBIT_LINKS_HELP');
      var languageLocale = this.get('i18n.userLanguageLocale');
      return "".concat(baseURL).concat(urlContext[languageLocale]);
    })
  });

  _exports.default = _default;
});