define('ember-dompurify/helpers/dom-purify', ['exports', 'dompurify', '@ember/string'], function (exports, _dompurify, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var HOOKS = ['beforeSanitizeElements', 'uponSanitizeElement', 'afterSanitizeElements', 'beforeSanitizeAttributes', 'uponSanitizeAttribute', 'afterSanitizeAttributes', 'beforeSanitizeShadowDOM', 'uponSanitizeShadowNode', 'afterSanitizeShadowDOM'];

  exports.default = Ember.Helper.extend({
    /** @private **/
    _owner: null,

    /** @private **/
    _config: null,

    /** @private **/
    _purify: null,

    /** @public **/
    init: function init() {
      this._super.apply(this, arguments);
      this._owner = Ember.getOwner(this);
      this._purify = (0, _dompurify.default)(self);
    },


    /** @public **/
    compute: function compute(_ref, attrs) {
      var _ref2 = _slicedToArray(_ref, 1),
          input = _ref2[0];

      var inputString = Ember.String.isHTMLSafe(input) ? input.toString() : input;

      if (typeof inputString !== 'string' || !inputString) {
        return;
      }

      if (this._config) {
        /* reset purify state between computes */
        this._purify.removeAllHooks();
        this._purify.clearConfig();
        this._config = null;
      }

      /* re-create new purify state */
      this._config = this.parseAttributes(attrs);
      this._purify.setConfig(this._config);

      return Ember.String.htmlSafe(this._purify.sanitize(inputString));
    },


    /** @private **/
    normalizeAttributeName: function normalizeAttributeName(key) {
      return (0, _string.dasherize)(key).toUpperCase().replace(/-/g, '_');
    },


    /** @private **/
    lookupHooks: function lookupHooks(name) {
      var _this = this;

      return name.split(' ').map(function (hookName) {
        return _this._owner.factoryFor('dompurify-hook:' + hookName).class;
      });
    },


    /** @private **/
    parseAttributes: function parseAttributes(attrs) {
      var _this2 = this;

      return Object.keys(attrs).reduce(function (accum, key) {
        var value = attrs[key];

        if (key === 'hook') {
          var hooks = typeof value === 'string' ? _this2.lookupHooks(value) : Ember.makeArray(value);

          hooks.forEach(function (Hook) {
            return new Hook(_this2._purify);
          });
        } else if (HOOKS.includes(key)) {
          _this2._purify.addHook(key, function () {
            return value.apply(undefined, arguments);
          });
        } else {
          accum[_this2.normalizeAttributeName(key)] = value;
        }

        return accum;
      }, {});
    }
  });
});