define('@bagaar/ember-breadcrumbs/components/bgr-breadcrumbs-item', ['exports', '@bagaar/ember-breadcrumbs/templates/components/bgr-breadcrumbs-item'], function (exports, _bgrBreadcrumbsItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * injections
     */

    bgrBreadcrumbs: Ember.inject.service(),

    /**
     * state
     */

    layout: _bgrBreadcrumbsItem.default,
    tagName: ''
  });
});