define("snapdragon-common/components/cw-dropdown-trigger", ["exports", "snapdragon-common/templates/components/cw-dropdown-trigger"], function (_exports, _cwDropdownTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component displays environment-specific (webview or browser environment) trigger
   * for select-like components.
   */
  var _default = Ember.Component.extend({
    isMobile: false,
    layout: _cwDropdownTrigger.default,
    classNames: ['dropdown-trigger']
  });

  _exports.default = _default;
});