define("snapdragon-common/templates/components/fit-digital-code-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A01N4d9m",
    "block": "{\"symbols\":[\"num\",\"index\"],\"statements\":[[4,\"each\",[[27,\"times\",[[23,[\"codeLength\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"class\",\"input\",\"key-up\",\"key-down\",\"value\",\"disabled\",\"type\",\"inputmode\",\"pattern\",\"maxlength\",\"autocomplete\"],[\"digit-input\",[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onInput\",[22,2,[]]],null]],null],[27,\"action\",[[22,0,[]],\"onKeyUp\",[22,2,[]]],null],[27,\"action\",[[22,0,[]],\"onKeyDown\"],null],[27,\"get\",[[23,[\"digits\"]],[22,2,[]]],null],[27,\"unless\",[[27,\"or\",[[27,\"get\",[[23,[\"digits\"]],[27,\"sub\",[[22,2,[]],1],null]],null],[27,\"eq\",[[22,2,[]],0],null]],null],\"disabled\"],null],\"text\",\"numeric\",\"[0-9]*\",\"1\",\"off\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/fit-digital-code-input.hbs"
    }
  });

  _exports.default = _default;
});