define("consent-engine/templates/consent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WngO1qhW",
    "block": "{\"symbols\":[\"consentContent\"],\"statements\":[[7,\"div\"],[11,\"id\",\"consent\"],[11,\"class\",\"row column\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row content\"],[9],[0,\"\\n\"],[4,\"with\",[[23,[\"model\",\"consentContents\",\"reaffirm-existing-eu-user\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n                \"],[7,\"h2\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n                \"],[1,[22,1,[\"body\"]],true],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"button important\"],[11,\"data-test-qa\",\"accept-consent-button\"],[11,\"type\",\"button\"],[9],[0,\"\\n                    \"],[1,[22,1,[\"navigation-button-title\"]],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],[27,\"route-action\",[\"acceptConsent\"],null],[23,[\"model\",\"consent\"]]]],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "consent-engine/templates/consent.hbs"
    }
  });

  _exports.default = _default;
});