define("ember-fitbit-i18n/-private/locale-paths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "en_US": "/assets/translations/messages.json",
    "ar_SA": "/assets/translations/messages_ar_SA.json",
    "cs_CZ": "/assets/translations/messages_cs_CZ.json",
    "de_DE": "/assets/translations/messages_de_DE.json",
    "en_GB": "/assets/translations/messages_en_GB.json",
    "es_ES": "/assets/translations/messages_es_ES.json",
    "es_LA": "/assets/translations/messages_es_LA.json",
    "fr_FR": "/assets/translations/messages_fr_FR.json",
    "id_ID": "/assets/translations/messages_id_ID.json",
    "in_ID": "/assets/translations/messages_in_ID.json",
    "it_IT": "/assets/translations/messages_it_IT.json",
    "ja_JP": "/assets/translations/messages_ja_JP.json",
    "ko_KR": "/assets/translations/messages_ko_KR.json",
    "nb_NO": "/assets/translations/messages_nb_NO.json",
    "nl_NL": "/assets/translations/messages_nl_NL.json",
    "pl_PL": "/assets/translations/messages_pl_PL.json",
    "pt_BR": "/assets/translations/messages_pt_BR.json",
    "ro_RO": "/assets/translations/messages_ro_RO.json",
    "ru_BY": "/assets/translations/messages_ru_BY.json",
    "ru_RU": "/assets/translations/messages_ru_RU.json",
    "sv_SE": "/assets/translations/messages_sv_SE.json",
    "zh_CN": "/assets/translations/messages_zh_CN.json",
    "zh_TW": "/assets/translations/messages_zh_TW.json"
  };
  _exports.default = _default;
});