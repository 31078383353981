define("snapdragon-common/templates/components/device-meridiem-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PfMNsnvp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"select\"],[12,\"class\",[21,\"selectClass\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],[23,[\"onChange\"]]],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n    \"],[7,\"option\"],[11,\"value\",\"AM\"],[12,\"selected\",[27,\"if\",[[27,\"eq\",[[23,[\"meridiem\"]],\"AM\"],null],\"selected\"],null]],[9],[0,\"\\n        \"],[1,[27,\"format\",[\"meridiem_am\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"option\"],[11,\"value\",\"PM\"],[12,\"selected\",[27,\"if\",[[27,\"eq\",[[23,[\"meridiem\"]],\"PM\"],null],\"selected\"],null]],[9],[0,\"\\n        \"],[1,[27,\"format\",[\"meridiem_pm\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/device-meridiem-select.hbs"
    }
  });

  _exports.default = _default;
});