define("snapdragon-common/components/journal-survey-question-slider", ["exports", "snapdragon-common/templates/components/journal-survey-question-slider"], function (_exports, _journalSurveyQuestionSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _journalSurveyQuestionSlider.default,
    classNames: ['journal-survey-question', 'journal-survey-question-single-choice'],
    internalValue: Ember.computed('question.responses.@each.selected', function () {
      var responses = this.question.responses; // toArray needed because ember arrays don't support findIndex

      return responses.toArray().findIndex(function (response) {
        return response.selected;
      });
    }),
    responseCount: Ember.computed('question.responses.length', function () {
      return this.question.responses.length - 1;
    }),
    actions: {
      onChange: function onChange(internalValue) {
        var responses = this.question.responses;
        responses.forEach(function (response, index) {
          Ember.set(response, 'selected', internalValue === String(index));
        });
      }
    }
  });

  _exports.default = _default;
});