define("snapdragon-common/templates/components/fit-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7CAFNZa4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[23,[\"switchID\"]],[23,[\"label\"]],[23,[\"isEnabled\"]],[23,[\"onChange\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"checked\",\"id\",\"change\"],[\"checkbox\",\"switch-input\",[23,[\"isEnabled\"]],[23,[\"switchID\"]],[23,[\"onChange\"]]]]],false],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"switch-paddle\"],[12,\"for\",[28,[[21,\"switchID\"]]]],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"show-for-sr\"],[9],[1,[21,\"label\"],false],[0,\" \"],[1,[27,\"if\",[[23,[\"checked\"]],\"Off\",\"On\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/fit-switch.hbs"
    }
  });

  _exports.default = _default;
});