define("ember-aupac-typeahead/templates/components/aupac-typeahead/pending", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6a+x1+mJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"typeahead-pending\"],[9],[0,\"Loading...\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aupac-typeahead/templates/components/aupac-typeahead/pending.hbs"
    }
  });

  _exports.default = _default;
});