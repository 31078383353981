define("snapdragon-common/components/zf-reveal", ["exports", "ember-cli-foundation-6-sass/components/zf-reveal"], function (_exports, _zfReveal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _zfReveal.default.reopen({
    appendTo: Ember.computed(function () {
      return Ember.testing ? '.ember-application' : 'body';
    }),
    init: function init() {
      var zfOptions = Ember.get(this, 'zfOptions');
      var hasAppendTo = zfOptions.find(function (option) {
        return option === 'appendTo';
      });

      if (!hasAppendTo) {
        zfOptions.push('appendTo');
        Ember.set(this, 'zfOptions', zfOptions);
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Placeholder for onClosed, called before zf-reveal modal is opened.
     */
    onOpen: function onOpen() {},

    /**
     * Placeholder for onClose, called before zf-reveal modal is closed.
     */
    onClose: function onClose() {},

    /**
     * Prepare event handlers for zf-reveal.
     *
     * @override
     */
    didInsertElement: function didInsertElement() {
      var _this = this,
          _arguments = arguments;

      this._super();

      this._onOpen = function () {
        if (!(_this.isDestroyed || _this.isDestroying)) {
          return _this.onOpen.apply(_this, _toConsumableArray(_arguments));
        }
      };

      this._onClose = function () {
        if (!(_this.isDestroyed || _this.isDestroying)) {
          return _this.onClose.apply(_this, _toConsumableArray(_arguments));
        }
      };
    },

    /**
     * Method that's called when the component is being destroyed.
     * @override
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      /**
       * Need to manually close the modal in order to cleanup, otherwise classes will remain on the body element.
       * This has been fixed in foundation-sites@6.5.
       */


      var zfUi = this.zfUi;

      if (zfUi) {
        Ember.run.once(zfUi, 'close');
      }
    },

    /**
     * Attach event handlers to zf-reveal's open, close, and closed events.
     *
     * @override
     */
    handleInsert: function handleInsert() {
      var _this2 = this;

      this._super();

      this.$().on('open.zf.reveal', function () {
        return Ember.run.scheduleOnce('afterRender', _this2, '_onOpen');
      });
      this.$().on('closed.zf.reveal', function () {
        return Ember.run.scheduleOnce('afterRender', _this2, '_onClose');
      });
    }
  });

  _exports.default = _default;
});