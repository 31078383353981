define("snapdragon-common/components/height-view", ["exports", "ember-cp-validations", "snapdragon-common/utils/unit-converter", "snapdragon-common/templates/components/height-view"], function (_exports, _emberCpValidations, _unitConverter, _heightView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var feetAndInchesToCm = _unitConverter.default.feetAndInchesToCm,
      cmToFeetAndInches = _unitConverter.default.cmToFeetAndInches;
  var Validations = (0, _emberCpValidations.buildValidations)({
    feet: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      lte: 9,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').format('feet_validation');
      })
    }),
    inches: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      lt: 12,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').format('inches_validation');
      })
    }),
    height: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      lte: 300,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').format('cm_validation');
      })
    })
  });

  var _default = Ember.Component.extend(Validations, {
    i18n: Ember.inject.service(),
    classNames: ['row'],
    layout: _heightView.default,

    /**
     * One way computed property from height. Used for calculating feet and inches
     */
    _oneWayHeight: Ember.computed.oneWay('height'),
    _feetAndInches: Ember.computed('_oneWayHeight', {
      get: function get() {
        var cm = Ember.get(this, '_oneWayHeight');
        return cmToFeetAndInches(cm);
      },
      set: function set(key, value) {
        var cm = feetAndInchesToCm(value.feet, value.inches);
        Ember.set(this, '_oneWayHeight', cm);
        return value;
      }
    }),
    feet: Ember.computed('_feetAndInches', {
      get: function get() {
        return Ember.get(this, '_feetAndInches.feet');
      },
      set: function set(key, value) {
        var feetAndInches = Ember.get(this, '_feetAndInches');
        Ember.set(feetAndInches, 'feet', value);
        Ember.set(this, '_feetAndInches', feetAndInches);
        return value;
      }
    }),
    inches: Ember.computed('_feetAndInches', {
      get: function get() {
        return Ember.get(this, '_feetAndInches.inches');
      },
      set: function set(key, value) {
        var feetAndInches = Ember.get(this, '_feetAndInches');
        Ember.set(feetAndInches, 'inches', value);
        Ember.set(this, '_feetAndInches', feetAndInches);
        return value;
      }
    }),
    keyDown: function keyDown(e) {
      // If it's the enter key, do a validation check before bubbling up.
      if (e.keyCode === 13) {
        var _this$validateSync = this.validateSync(),
            validations = _this$validateSync.validations;

        if (!Ember.get(validations, 'isValid')) {
          e.preventDefault();
        }
      }
    },
    actions: {
      heightChanged: function heightChanged(unit) {
        var _this = this;

        var height,
            inches = Ember.get(this, 'inches'),
            feet = Ember.get(this, 'feet'),
            heightChangedFnc = Ember.get(this, 'onHeightChanged');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (Ember.get(validations, 'isValid')) {
            if ((unit === 'feet' || unit === 'inches') && inches && feet) {
              height = Ember.get(_this, '_oneWayHeight');
              Ember.set(_this, 'height', height);
            }

            if (heightChangedFnc) {
              heightChangedFnc();
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});