define("consent-data/models/gdpr-status", ["exports", "ember-data", "snapdragon-common/utils/split-locale", "ember-data/attr"], function (_exports, _emberData, _splitLocale, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    consents: hasMany('consent'),
    category: belongsTo('consent-category', {
      async: false
    }),
    meta: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    /**
     * Returns the contents/copy for consent.
     *
     * An Object Proxy instance is returned instead of returning a promise because the promise doesn't provide access
     * to the resolved value without using `then` callbacks. With an object proxy, the resolved promise's value is set
     * as the content of the proxy, so accessing the properties can be direct.
     *
     * @returns {ObjectProxy}
     */
    consentContents: Ember.computed('_language', function () {
      var language = Ember.get(this, '_language');
      return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
        promise: this._loadConsentContents(language)
      });
    }),

    /**
     * Look up language derived from user locale.
     * The language is appended to the consent content files.
     *
     * @returns {string}
     */
    _language: Ember.computed('i18n.userLocale', function () {
      var locale = Ember.get(this, 'i18n.userLocale');
      var language;

      switch (true) {
        case locale === 'zh_TW':
          language = 'zh-Hant';
          break;
        // There's no zh.json file, so use zh-Hans as the fallback in case there's another zh that isn't handled

        case /^zh/.test(locale):
          language = 'zh-Hans';
          break;

        case locale === 'fr_CA':
          language = 'fr-CA';
          break;

        default:
          language = (0, _splitLocale.default)(locale).language;
      }

      return language;
    }),
    _loadConsentContents: function _loadConsentContents(language) {
      var _EmberGetProperties = Ember.getProperties(this, 'meta', 'ajax'),
          _EmberGetProperties$m = _EmberGetProperties.meta['consent-content-base-url'],
          consentContentBaseUrl = _EmberGetProperties$m === void 0 ? '' : _EmberGetProperties$m,
          ajax = _EmberGetProperties.ajax;

      var url = "".concat(consentContentBaseUrl, "consents_").concat(language, ".json");
      return ajax.request(url).then(function (_ref) {
        var consents = _ref.consents;
        return consents;
      });
    }
  });

  _exports.default = _default;
});