define("snapdragon-common/mixins/unit-lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    units: Ember.computed(function () {
      return {
        height: [{
          value: 'en_US',
          displayName: 'height_US'
        }, {
          value: 'METRIC',
          displayName: 'height_metric'
        }],
        weight: [{
          value: 'en_US',
          displayName: 'weight_US'
        }, {
          value: 'en_GB',
          displayName: 'weight_GB'
        }, {
          value: 'METRIC',
          displayName: 'weight_metric'
        }],
        water: [{
          displayName: 'water_unit_US',
          value: 'en_US'
        }, {
          displayName: 'water_unit_US_cup',
          value: 'en_US_cup'
        }, {
          displayName: 'water_unit_metric',
          value: 'METRIC'
        }]
      };
    }),
    unitSymbols: Ember.computed(function () {
      return {
        height: [{
          value: 'en_US',
          displayName: 'height_symbol_US_both'
        }, {
          value: 'METRIC',
          displayName: 'height_symbol_metric'
        }],
        weight: [{
          value: 'en_US',
          displayName: 'weight_symbol_US'
        }, {
          value: 'en_GB',
          displayName: 'weight_symbol_GB'
        }, {
          value: 'METRIC',
          displayName: 'weight_symbol_metric'
        }],
        water: [{
          displayName: 'water_unit_US_symbol',
          value: 'en_US'
        }, {
          displayName: 'water_unit_US_symbol_cup',
          value: 'en_US_cup'
        }, {
          displayName: 'water_unit_metric_symbol',
          value: 'METRIC'
        }]
      };
    })
  });

  _exports.default = _default;
});