define("snapdragon-common/constants/app-prefixes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FITBIT = _exports.BETABIT = _exports.DOGBIT = _exports.DEVBIT = void 0;

  /**
   * Constants for Fitbit mobile app deeplink prefixes
   */
  var DEVBIT = 'devbit';
  _exports.DEVBIT = DEVBIT;
  var DOGBIT = 'dogbit';
  _exports.DOGBIT = DOGBIT;
  var BETABIT = 'betabit';
  _exports.BETABIT = BETABIT;
  var FITBIT = 'fitbit';
  _exports.FITBIT = FITBIT;
});