define("snapdragon-common/mixins/log-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Mixin.create({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    START_DATE_LIMIT: Ember.computed.readOnly('constants.START_DATE_LIMIT'),
    queryParams: ['start-date', 'end-date'],

    /**
     * The bound query param start date. When the query param changes, so does this property.
     * @type {string}
     */
    'start-date': null,

    /**
     * The bound query param end date. When the query param changes, so does this property.
     * @type {string}
     */
    'end-date': null,

    /**
     * Computed Moment instance of start date.
     */
    startDate: Ember.computed('start-date', function () {
      var _startDate = Ember.get(this, 'start-date');

      if (!_startDate) {
        var moment = Ember.get(this, 'moment');
        var endDate = Ember.get(this, 'endDate'); //Moment's subtract method subtracts exclusive. 29 days is used to correctly show 30 dates
        // and include the startDate in the range.

        _startDate = moment.moment(endDate).subtract(29, 'days');
      }

      return this._processDate(_startDate);
    }),

    /**
     * Computed Moment instance of end date.
     */
    endDate: Ember.computed('end-date', function () {
      var _endDate = Ember.get(this, 'end-date');

      return this._processDate(_endDate);
    }),

    /**
     * Start day of week from the current user as a number. 0 - 6, Sunday - Saturday.
     * @returns {number}
     */
    startDayOfWeek: Ember.computed('currentUser.startDayOfWeek', function () {
      var startDayOfWeek = Ember.get(this, 'currentUser.startDayOfWeek');
      return (startDayOfWeek || 'SUNDAY') === 'SUNDAY' ? 0 : 1;
    }),

    /**
     * Date picker configuration to set minimum start date
     */
    minDate: Ember.computed(function () {
      var momentService = Ember.get(this, 'moment');
      var START_DATE_LIMIT = Ember.get(this, 'START_DATE_LIMIT');
      return momentService.moment(START_DATE_LIMIT);
    }),

    /**
     * Date picker configuration to allow choosing today, requires returning end of day.
     */
    maxDate: Ember.computed(function () {
      var momentService = Ember.get(this, 'moment');
      return momentService.moment().endOf('day');
    }),

    /**
     * Manage loading state.
     */
    isLoading: false,

    /**
     * Change a date to a moment instance.
     * @param srcDate
     * @returns {moment}
     * @private
     */
    _processDate: function _processDate(srcDate) {
      var momentService = Ember.get(this, 'moment');
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      var date = [srcDate]; // srcDate is falsey, create a new date.

      if (!srcDate) {
        date = [new Date()]; // srcDate is a string, use a format string.
      } else if (typeof srcDate === 'string') {
        date = [srcDate, YEAR_MONTH_DAY];
      }

      return momentService.moment.apply(momentService, _toConsumableArray(date));
    },
    actions: {
      /**
       * When date range changes, update the start and end date query params.
       * The end date is optional.
       *
       * @param {array} selectedDates - The array of selected dates as moment instances.
       */
      onDateRangeChange: function onDateRangeChange(selectedDates) {
        var momentService = Ember.get(this, 'moment');
        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        var START_DATE_LIMIT = Ember.get(this, 'START_DATE_LIMIT');
        var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');

        var _selectedDates = _slicedToArray(selectedDates, 2),
            startDate = _selectedDates[0],
            endDate = _selectedDates[1];

        var isBeforeDateLimit = momentService.moment(startDate).isBefore(START_DATE_LIMIT); // catches the case where a user clicks back arrow into a pre-2009 date range

        if (isBeforeDateLimit) {
          flashMessages.alert(formatter.formatByNamespace('invalid_start_date', 'common.validators.messages'));
          return;
        }

        var startStr = startDate.format(YEAR_MONTH_DAY);
        var endStr = null; // endDate is optional.

        if (endDate) {
          endStr = endDate.format(YEAR_MONTH_DAY);
        }

        Ember.setProperties(this, {
          'start-date': startStr,
          'end-date': endStr
        });
      }
    }
  });

  _exports.default = _default;
});