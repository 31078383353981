define("snapdragon-common/components/webview-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getHtmlElement() {
    return document.documentElement;
  }

  function getViewportElement() {
    return document.querySelector('meta[name="viewport"]');
  }
  /**
   * This component fixes WebView UI issues
   * - foundation reveal dialog has white space in the bottom of the screen on iOS
   *   https://jira.fitbit.com/browse/CW-28620.
   * - it is more natural to disable zoom for webview
   */


  var _default = Ember.Component.extend({
    mobileView: Ember.inject.service(),
    tagName: '',
    isWebView: Ember.computed.alias('mobileView.isWebView'),
    backupViewport: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.isWebView) {
        // enable webview styles
        var htmlEl = getHtmlElement();
        htmlEl.classList.add('-webview-support'); // disable zooming

        var viewportEl = getViewportElement();

        if (viewportEl) {
          this.backupViewport = viewportEl.content;
          viewportEl.content = 'width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1';
        }
      }
    },
    destroy: function destroy() {
      if (this.isWebView) {
        // disable webview styles
        var htmlEl = getHtmlElement();
        htmlEl.classList.remove('-webview-support'); // enable zooming

        var viewportEl = getViewportElement();

        if (viewportEl) {
          viewportEl.content = this.backupViewport;
        }
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});