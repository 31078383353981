define("snapdragon-common/templates/components/journal-survey-question-slider-legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lDS94gJN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"legend-container\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"format-by-namespace\",[\"very_poor\",\"templates.journal-survey\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"format-by-namespace\",[\"ok\",\"templates.journal-survey\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"format-by-namespace\",[\"very_good\",\"templates.journal-survey\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/journal-survey-question-slider-legend.hbs"
    }
  });

  _exports.default = _default;
});