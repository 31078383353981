define("snapdragon-common/templates/components/cw-confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KbkuEZxl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"corporate-confirm-modal__content\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"corporate-confirm-modal__button-bar\"],[9],[0,\"\\n\"],[4,\"cw-button\",null,[[\"class\",\"data-test-qa\",\"onTap\"],[\"corporate-button-primary\",\"confirm-button\",[27,\"action\",[[22,0,[]],\"confirm\"],null]]],{\"statements\":[[0,\"        \"],[1,[27,\"if\",[[23,[\"confirmText\"]],[23,[\"confirmText\"]],[27,\"format-by-namespace\",[\"confirm\",\"templates.components.fit-confirm-modal\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"cw-button\",null,[[\"class\",\"data-test-qa\",\"onTap\",\"aria-label\"],[\"corporate-button-secondary\",\"cancel-button\",[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"format-by-namespace\",[\"aria_cancel_and_close\",\"common.aria\"],null]]],{\"statements\":[[0,\"        \"],[1,[27,\"if\",[[23,[\"cancelText\"]],[23,[\"cancelText\"]],[27,\"format-by-namespace\",[\"cancel\",\"templates.components.fit-confirm-modal\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});