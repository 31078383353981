define("snapdragon-common/components/cw-button", ["exports", "snapdragon-common/templates/components/cw-button"], function (_exports, _cwButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button'],
    classNameBindings: ['mimicDisabled:disabled', 'isLoading'],
    attributeBindings: ['disabled', 'data-test-qa'],
    layout: _cwButton.default,
    isLoading: false,
    mimicDisabled: false,
    style: 'touch-action: none;',
    // to prevent console warnings
    onTap: function onTap() {},
    click: function click(event) {
      if (this.isLoading) {
        return;
      }

      this.onTap(event);
      event.preventDefault();
    }
  });

  _exports.default = _default;
});