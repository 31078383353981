define("snapdragon-common/mixins/custom-app-prefix", ["exports", "snapdragon-common/constants/app-prefixes"], function (_exports, _appPrefixes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP_PREFIXES_ALLOWLIST = [_appPrefixes.DEVBIT, _appPrefixes.BETABIT, _appPrefixes.FITBIT, _appPrefixes.DOGBIT];

  var _default = Ember.Mixin.create({
    appPrefix: Ember.computed(function () {
      var params = new URLSearchParams(location.search.substring(1));
      return params.get('app-prefix');
    }),
    deeplinkUrlPrefix: Ember.computed('appPrefix', function () {
      var prefix = _appPrefixes.FITBIT;

      if (APP_PREFIXES_ALLOWLIST.includes(Ember.get(this, 'appPrefix'))) {
        prefix = this.appPrefix;
      }

      return "".concat(prefix, "://");
    })
  });

  _exports.default = _default;
});