define("corporate-onboarding-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('welcome');
    this.route('consent');
    this.route('application-form');
    this.route('success');
    this.route('redirect');
    this.route('from-redirect');
    this.route('custom', {
      path: 'custom/:id'
    });
  });

  _exports.default = _default;
});