define('@bagaar/ember-breadcrumbs/components/bgr-breadcrumbs', ['exports', '@bagaar/ember-breadcrumbs/templates/components/bgr-breadcrumbs'], function (exports, _bgrBreadcrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * injections
     */

    bgrBreadcrumbs: Ember.inject.service(),

    /**
     * props
     */

    className: 'breadcrumbs',
    itemClassName: 'breadcrumbs__item',
    linkClassName: 'breadcrumbs__link',

    /**
     * state
     */

    classNameBindings: ['className'],
    layout: _bgrBreadcrumbs.default,
    tagName: 'ul',

    /**
     * hooks
     */

    didInsertElement: function didInsertElement() {
      this.get('bgrBreadcrumbs.instances').addObject(this);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('bgrBreadcrumbs.instances').removeObject(this);
    }
  });
});