define("snapdragon-common/components/fit-confirm-modal", ["exports", "snapdragon-common/components/fit-modal", "snapdragon-common/templates/components/fit-confirm-modal"], function (_exports, _fitModal, _fitConfirmModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _fitModal.default.extend({
    layout: _fitConfirmModal.default,
    classNames: ['fit-confirm-modal'],
    errorMessage: null,
    onConfirm: function onConfirm() {},
    onCancel: function onCancel() {},
    cancelText: null,
    confirmText: null,
    actions: {
      confirm: function confirm() {
        var _this = this,
            _arguments = arguments;

        return Ember.RSVP.resolve().then(function () {
          return Ember.get(_this, 'onConfirm').apply(void 0, _toConsumableArray(_arguments));
        }).then(function () {
          if (!(_this.isDestroyed || _this.isDestroying)) {
            Ember.set(_this, 'errorMessage', '');
          }

          Ember.get(_this, 'zfUi').close();
        }).catch(function (errorMessage) {
          if (!(_this.isDestroyed || _this.isDestroying)) {
            Ember.set(_this, 'errorMessage', errorMessage);
          }
        });
      },
      cancel: function cancel() {
        var _this2 = this,
            _arguments2 = arguments;

        return Ember.RSVP.resolve().then(function () {
          return Ember.get(_this2, 'onCancel').apply(void 0, _toConsumableArray(_arguments2));
        }).then(function () {
          if (!(_this2.isDestroyed || _this2.isDestroying)) {
            Ember.set(_this2, 'errorMessage', '');
          }

          Ember.get(_this2, 'zfUi').close(); // If not closed by onCancel, close here if successful
        }).catch(function (errorMessage) {
          if (!(_this2.isDestroyed || _this2.isDestroying)) {
            Ember.set(_this2, 'errorMessage', errorMessage);
          }
        });
      }
    }
  });

  _exports.default = _default;
});