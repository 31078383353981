define("snapdragon-common/helpers/format-by-namespace", ["exports", "ember-fitbit-i18n/helpers/format-by-namespace"], function (_exports, _formatByNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formatByNamespace.default;
    }
  });
  Object.defineProperty(_exports, "formatByNamespace", {
    enumerable: true,
    get: function get() {
      return _formatByNamespace.formatByNamespace;
    }
  });
});