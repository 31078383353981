define("snapdragon-common/constants/unit-conversions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MINUTES_IN_HOUR = _exports.MINUTES_IN_DAY = _exports.ML_PER_CUP = _exports.OZ_PER_CUP = _exports.ML_PER_OZ = _exports.GRAMS_PER_STONE = _exports.STONES_PER_GRAM = _exports.YARDS_PER_MILE = _exports.INCH_PER_FOOT = _exports.CM_PER_INCH = _exports.POUNDS_PER_STONE = _exports.CM_PER_MILE = _exports.METERS_PER_YARD = _exports.GRAMS_PER_POUND = void 0;
  var GRAMS_PER_POUND = 453.59237;
  _exports.GRAMS_PER_POUND = GRAMS_PER_POUND;
  var METERS_PER_YARD = 0.9144;
  _exports.METERS_PER_YARD = METERS_PER_YARD;
  var CM_PER_MILE = 160934.400000001;
  _exports.CM_PER_MILE = CM_PER_MILE;
  var POUNDS_PER_STONE = 14;
  _exports.POUNDS_PER_STONE = POUNDS_PER_STONE;
  var CM_PER_INCH = 2.54;
  _exports.CM_PER_INCH = CM_PER_INCH;
  var INCH_PER_FOOT = 12;
  _exports.INCH_PER_FOOT = INCH_PER_FOOT;
  var YARDS_PER_MILE = 1760;
  _exports.YARDS_PER_MILE = YARDS_PER_MILE;
  var STONES_PER_GRAM = 1.5747304e-4;
  _exports.STONES_PER_GRAM = STONES_PER_GRAM;
  var GRAMS_PER_STONE = 6350.29318;
  _exports.GRAMS_PER_STONE = GRAMS_PER_STONE;
  var ML_PER_OZ = 29.5735;
  _exports.ML_PER_OZ = ML_PER_OZ;
  var OZ_PER_CUP = 8;
  _exports.OZ_PER_CUP = OZ_PER_CUP;
  var ML_PER_CUP = 236.58799743652344;
  _exports.ML_PER_CUP = ML_PER_CUP;
  var MINUTES_IN_DAY = 1440;
  _exports.MINUTES_IN_DAY = MINUTES_IN_DAY;
  var MINUTES_IN_HOUR = 60;
  _exports.MINUTES_IN_HOUR = MINUTES_IN_HOUR;
});