define("snapdragon-common/components/flexible-prototype-feedback", ["exports", "snapdragon-common/templates/components/flexible-prototype-feedback", "snapdragon-common/constants/prototype-feedback", "snapdragon-common/constants/flexible-prototype-feedback"], function (_exports, _flexiblePrototypeFeedback, _prototypeFeedback, _flexiblePrototypeFeedback2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _flexiblePrototypeFeedback.default,
    tagName: '',
    RESPONSE_NOT: _prototypeFeedback.RESPONSE_NOT,
    RESPONSE_SLIGHTLY: _prototypeFeedback.RESPONSE_SLIGHTLY,
    RESPONSE_FAIRLY: _prototypeFeedback.RESPONSE_FAIRLY,
    RESPONSE_HELPFUL: _prototypeFeedback.RESPONSE_HELPFUL,
    RESPONSE_VERY: _prototypeFeedback.RESPONSE_VERY,

    /**
     * Returns default set of survey questions
     * @returns {SurveyQuestions}
     */
    surveyQuestions: Ember.computed(function () {
      return new _flexiblePrototypeFeedback2.default([_prototypeFeedback.DEFAULT_SURVEY_QUESTION]);
    }),

    /**
     * Text feedback value for textArea
     */
    textFeedback: '',

    /**
     * Overwrite with page name for fsc events
     */
    associatedPageName: 'Flexible Prototype Feedback Form',

    /**
     * Survey Name to be replaced by parent
     */
    surveyName: Ember.computed(function () {
      (false && !(false) && Ember.assert('prototype-feedback: surveyName property must be defined by parent'));
    }),

    /**
     * Action stub to be replaced by parent
     */
    submitSurvey: Ember.computed(function () {
      (false && !(false) && Ember.assert('prototype-feedback: submitSurvey action must be defined by parent'));
    }),

    /**
     * Action stub to be replaced by parent
     */
    logTap: Ember.computed(function () {
      (false && !(false) && Ember.assert('prototype-feedback: logTap action must be defined by parent'));
    }),
    actions: {
      /**
       * Calls submitSurvey after serializing response data
       * @param surveyQuestions {SurveyQuestions[]}
       * @param textFeedback {String}
       * @param surveyName {String}
       * @returns {*}
       */
      submitSurvey: function submitSurvey(surveyQuestions, textFeedback, surveyName) {
        return this.submitSurvey({
          selectedAnswers: surveyQuestions.generateAnswers(),
          textFeedback: surveyQuestions.textFeedback
        }, surveyName);
      },
      logTap: function logTap(logString) {
        return this.logTap(logString);
      },
      toggleResponseValue: function toggleResponseValue(selectedValues, key) {
        var currentVal = Ember.get(selectedValues, key);
        Ember.set(selectedValues, key, !currentVal);
      }
    }
  });

  _exports.default = _default;
});