define("consent-data/adapters/gdpr-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    namespace: '1',

    /**
     * Path for type is the as the model name.
     *
     * @override
     * @param {string} modelName
     * @returns {string}
     */
    pathForType: function pathForType(modelName) {
      return modelName;
    },

    /**
     * URL for finding record. Excludes the ID.
     *
     * @override
     * @param {string} id
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return "".concat(this._super(undefined, modelName, snapshot), ".json");
    }
  });

  _exports.default = _default;
});