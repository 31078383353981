define("snapdragon-common/components/device-meridiem-select", ["exports", "snapdragon-common/templates/components/device-meridiem-select"], function (_exports, _deviceMeridiemSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _deviceMeridiemSelect.default
  });

  _exports.default = _default;
});