define('ember-crumbly/components/bread-crumbs', ['exports', 'ember-copy', '@ember/string', 'ember-crumbly/templates/components/bread-crumbs'], function (exports, _emberCopy, _string, _breadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bool = Ember.computed.bool,
      readOnly = Ember.computed.readOnly;
  exports.default = Ember.Component.extend({
    layout: _breadCrumbs.default,
    tagName: 'ol',
    linkable: true,
    reverse: false,
    classNameBindings: ['breadCrumbClass'],
    hasBlock: bool('template').readOnly(),
    routing: Ember.inject.service('-routing'),
    currentUrl: readOnly('applicationRoute.router.url'),
    currentRouteName: readOnly('applicationRoute.controller.currentRouteName'),

    routeHierarchy: Ember.computed('currentUrl', 'currentRouteName', 'reverse', {
      get: function get() {
        Ember.get(this, 'currentUrl');
        var currentRouteName = Ember.getWithDefault(this, 'currentRouteName', false);

        (false && !(currentRouteName) && Ember.assert('[ember-crumbly] Could not find a current route', currentRouteName));


        var routeNames = currentRouteName.split('.');
        var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
        var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

        return Ember.get(this, 'reverse') ? crumbs.reverse() : crumbs;
      }
    }).readOnly(),

    breadCrumbClass: Ember.computed('outputStyle', {
      get: function get() {
        var className = 'breadcrumb';
        var outputStyle = Ember.getWithDefault(this, 'outputStyle', '');
        if (Ember.isPresent(outputStyle)) {
          Ember.deprecate('outputStyle option will be deprecated in the next major release', false, { id: 'ember-crumbly.outputStyle', until: '2.0.0' });
        }
        var lowerCaseOutputStyle = outputStyle.toLowerCase();

        if (lowerCaseOutputStyle === 'foundation') {
          className = 'breadcrumbs';
        }

        return className;
      }
    }).readOnly(),

    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        var path = name + '.index';

        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },
    _filterIndexAndLoadingRoutes: function _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(function (name) {
        return !(name === 'index' || name === 'loading');
      });
    },


    /*
     * Lookup local route first and fallback to engine,
     * I'm not exactly familiar with local vs engine routes,
     * but my thinking is you should be able to override an
     * engine route locally so it should take priority.
     * I could be totally wrong here...
     */
    _lookupRoute: function _lookupRoute(routeName) {
      return this._lookupLocalRoute(routeName) || this._lookupEngineRoute(routeName);
    },
    _lookupLocalRoute: function _lookupLocalRoute(routeName) {
      return Ember.getOwner(this).lookup('route:' + routeName);
    },
    _lookupEngineRoute: function _lookupEngineRoute(routeName) {
      var router = Ember.get(this, 'routing.router');

      var engineInfo = router._engineInfoByRoute[routeName];

      if (!engineInfo) {
        return;
      }

      return router._getEngineInstance(engineInfo).lookup('route:' + engineInfo.localFullName);
    },
    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = Ember.get(this, 'linkable');
      var pathLength = filteredRouteNames.length;
      var breadCrumbs = Ember.A();

      filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);
        var route = _this._lookupRoute(path);
        var isHead = index === 0;
        var isTail = index === pathLength - 1;

        var crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        (false && !(route) && Ember.assert('[ember-crumbly] `route:' + path + '` was not found', route));


        var multipleBreadCrumbs = route.get('breadCrumbs');

        if (multipleBreadCrumbs) {
          multipleBreadCrumbs.forEach(function (breadCrumb) {
            breadCrumbs.pushObject(breadCrumb);
          });
        } else {
          var breadCrumb = (0, _emberCopy.copy)(Ember.getWithDefault(route, 'breadCrumb', {
            title: (0, _string.classify)(name)
          }));

          if (Ember.typeOf(breadCrumb) === 'null') {
            return;
          } else {
            if (Ember.isPresent(breadCrumb.path)) {
              path = breadCrumb.path;
            }

            Ember.setProperties(breadCrumb, {
              path: path,
              isHead: isHead,
              isTail: isTail,
              linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
            });
          }

          breadCrumbs.pushObject(breadCrumb);
        }
      });

      return Ember.A(breadCrumbs.filter(function (breadCrumb) {
        return Ember.typeOf(breadCrumb) !== 'undefined';
      }));
    }
  });
});