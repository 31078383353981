define("snapdragon-common/templates/components/fit-confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KyqfzHcb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"fit-confirm-modal-container\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"close-button\"],[11,\"data-close\",\"\"],[12,\"aria-label\",[28,[[27,\"format-by-namespace\",[\"cancel\",\"templates.components.fit-confirm-modal\"],null]]]],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[1,[21,\"errorMessage\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"button-row row align-justify\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column large-6 medium-12 small-6 col-left\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"button cancel-button\"],[11,\"data-test-qa\",\"cancel-button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"cancel\"],null]],[12,\"aria-label\",[27,\"format-by-namespace\",[\"aria_cancel_and_close\",\"common.aria\"],null]],[9],[0,\"\\n                \"],[1,[27,\"if\",[[23,[\"cancelText\"]],[23,[\"cancelText\"]],[27,\"format-by-namespace\",[\"cancel\",\"templates.components.fit-confirm-modal\"],null]],null],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column large-6 medium-12 small-6 col-right\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"button important confirm-button\"],[11,\"data-test-qa\",\"confirm-button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"confirm\"],null]],[9],[0,\"\\n                \"],[1,[27,\"if\",[[23,[\"confirmText\"]],[23,[\"confirmText\"]],[27,\"format-by-namespace\",[\"confirm\",\"templates.components.fit-confirm-modal\"],null]],null],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/fit-confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});