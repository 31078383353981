define("snapdragon-common/templates/components/cw-dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rsd4AmXf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isMobile\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"dropdown-trigger-lbl\"],[9],[1,[27,\"format-by-namespace\",[\"choose\",\"common.messages\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[9],[1,[27,\"fit-glyph\",null,[[\"type\"],[\"arrow\"]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"for-browser dropdown-trigger-expand-btn\"],[9],[1,[27,\"fit-glyph\",null,[[\"type\"],[\"arrow\"]]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-dropdown-trigger.hbs"
    }
  });

  _exports.default = _default;
});