define("consent-data/models/v2-consent", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    /**
     * The ID is composed as <categoryId>-<consentId>. Follow the said composed structure
     * when finding a record.
     * @property: id
     */
    categoryId: (0, _attr.default)('string'),
    consentId: (0, _attr.default)('string'),
    consenterId: (0, _attr.default)('string'),
    context: (0, _attr.default)(),
    status: (0, _attr.default)('string', {
      defaultValue: 'disagree'
    }),
    timestamp: (0, _attr.default)('date'),
    userId: (0, _attr.default)('string'),
    version: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    isAgreed: Ember.computed.equal('status', 'agree'),
    agree: function agree() {
      Ember.set(this, 'status', 'agree');
    },
    disagree: function disagree() {
      Ember.set(this, 'status', 'disagree');
    }
  });

  _exports.default = _default;
});