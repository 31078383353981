define("fitbit-labs-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.mount('snore-detection-engine', {
      as: 'snore'
    });
    this.mount('health-updates-engine', {
      as: 'health-updates'
    });
  });

  _exports.default = _default;
});