define("snapdragon-common/components/fit-glyph", ["exports", "snapdragon-common/templates/components/fit-glyph"], function (_exports, _fitGlyph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _fitGlyph.default,
    tagName: 'span',
    prefix: 'fitglyph-',
    classNames: ['fitglyph'],
    classNameBindings: ['_type'],
    attributeBindings: ['data-open'],
    _type: Ember.computed('type', function () {
      var _this$getProperties = this.getProperties('type', 'prefix'),
          type = _this$getProperties.type,
          prefix = _this$getProperties.prefix;

      return prefix + type;
    })
  });

  _exports.default = _default;
});