define("snapdragon-common/components/cw-form-select-field", ["exports", "snapdragon-common/components/cw-form-field", "snapdragon-common/templates/components/cw-form-select-field"], function (_exports, _cwFormField, _cwFormSelectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form component for select field.
   */
  var _default = _cwFormField.default.extend({
    layout: _cwFormSelectField.default,

    /**
     * Set of options to be displayed in select
     */
    options: null,

    /**
     * If true, empty option is added to the top of displayed options
     */
    hasEmpty: false,

    /**
     * Namespace used to translate names
     */
    translationNamespace: null,

    /**
     * Specifies if translation via format* helper should be skipped
     */
    bypassTranslations: false,

    /**
     * Specifies field used to determine ID of option
     */
    optionIdField: null,

    /**
     * Specifies field used to determine text of option
     */
    optionLabelField: null,
    disabled: false,
    mobileView: Ember.inject.service(),
    isMobile: Ember.computed.oneWay('mobileView.isMobile'),
    displayedOptions: Ember.computed('options', 'optionIdField', 'optionLabelField', function () {
      var options = this.options,
          optionIdField = this.optionIdField,
          optionLabelField = this.optionLabelField;

      if (!(optionIdField || optionLabelField)) {
        return options;
      }

      return (options || []).map(function (option) {
        return {
          id: option[optionIdField],
          value: option[optionIdField].toString(),
          displayName: option[optionLabelField]
        };
      });
    }),
    selectedValue: Ember.computed('value', function () {
      var value = this.value;
      return value && value.toString() || null;
    }),
    getOptionById: function getOptionById(id) {
      var optionIdField = this.optionIdField,
          options = this.options;
      return options && options.find(function (option) {
        return option[optionIdField] === id;
      }) || null;
    },
    getOptionByValue: function getOptionByValue(value) {
      return this.displayedOptions.find(function (option) {
        return option.value === value;
      });
    },
    getOptionIdByValue: function getOptionIdByValue(value) {
      if (this.optionIdField) {
        var option = this.getOptionByValue(value);
        return option ? option.id : value;
      } else {
        return value;
      }
    },
    actions: {
      changeValue: function changeValue(value) {
        var id = this.getOptionIdByValue(value);
        this.set('value', id);
        this.onChange(id);
      }
    }
  });

  _exports.default = _default;
});