define("snapdragon-common/templates/components/cw-form-rich-select-before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oIipw+Y/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"searchEnabled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ember-power-select-search\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isMobile\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"rich-select-title\"],[9],[1,[27,\"format-by-namespace\",[\"select\",\"common.messages\"],[[\"name\"],[[23,[\"label\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\"],[11,\"class\",\"rich-select-input-box\"],[9],[0,\"\\n            \"],[7,\"input\"],[11,\"autocomplete\",\"off\"],[11,\"autocorrect\",\"off\"],[11,\"autocapitalize\",\"off\"],[11,\"spellcheck\",\"false\"],[11,\"role\",\"combobox\"],[11,\"class\",\"ember-power-select-search-input\"],[12,\"value\",[23,[\"select\",\"searchText\"]]],[12,\"aria-controls\",[21,\"listboxId\"]],[12,\"placeholder\",[27,\"if\",[[23,[\"isMobile\"]],[27,\"format-by-namespace\",[\"search\",\"common.messages\"],null],[23,[\"searchPlaceholder\"]]],null]],[12,\"oninput\",[21,\"onInput\"]],[12,\"onfocus\",[21,\"onFocus\"]],[12,\"onblur\",[21,\"onBlur\"]],[12,\"onkeydown\",[27,\"action\",[[22,0,[]],\"onKeydown\"],null]],[11,\"type\",\"search\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isMobile\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\"],[11,\"class\",\"rich-select-btn\"],[11,\"type\",\"button\"],[9],[0,\"\\n                    \"],[1,[27,\"format-by-namespace\",[\"cancel\",\"common.messages\"],null],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"onCancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-form-rich-select-before-options.hbs"
    }
  });

  _exports.default = _default;
});