define('ember-metrics/metrics-adapters/google-tag-manager', ['exports', '@ember/string', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _string, _canUseDom, _objectTransforms, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var compact = _objectTransforms.default.compact;
  exports.default = _base.default.extend({
    dataLayer: 'dataLayer',

    toStringExtension: function toStringExtension() {
      return 'GoogleTagManager';
    },
    init: function init() {
      var config = Ember.get(this, 'config');
      var id = config.id,
          envParams = config.envParams;

      var dataLayer = Ember.getWithDefault(config, 'dataLayer', 'dataLayer');
      var envParamsString = envParams ? '&' + envParams : '';

      (false && !(id) && Ember.assert('[ember-metrics] You must pass a valid `id` to the ' + this.toString() + ' adapter', id));


      Ember.set(this, 'dataLayer', dataLayer);

      if (_canUseDom.default) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + envParamsString;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', Ember.get(this, 'dataLayer'), id);
      }
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var compactedOptions = compact(options);
      var dataLayer = Ember.get(this, 'dataLayer');
      var gtmEvent = { 'event': compactedOptions['event'] };

      delete compactedOptions['event'];

      for (var key in compactedOptions) {
        var capitalizedKey = (0, _string.capitalize)(key);
        gtmEvent['event' + capitalizedKey] = compactedOptions[key];
      }

      if (_canUseDom.default) {
        window[dataLayer].push(gtmEvent);
      }

      return gtmEvent;
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var compactedOptions = compact(options);
      var dataLayer = Ember.get(this, 'dataLayer');
      var sendEvent = {
        event: compactedOptions['event'] || 'pageview'
      };

      var pageEvent = Ember.assign(sendEvent, compactedOptions);

      if (_canUseDom.default) {
        window[dataLayer].push(pageEvent);
      }

      return pageEvent;
    },
    willDestroy: function willDestroy() {
      if (_canUseDom.default) {
        Ember.$('script[src*="gtm.js"]').remove();
        delete window.dataLayer;
      }
    }
  });
});