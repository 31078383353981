define("ember-vega/components/vega-vis", ["exports", "vega", "ember-vega/templates/components/vega-vis", "ember-diff-attrs"], function (_exports, _vega, _vegaVis, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * A component for rendering a Vega visualization.
   *
   * ```hbs
   * {{vega-vis
   *    vis=vis
   *    config=config
   *    data=data
   *    spec=spec
   *    height=100
   *    width=200
   *    padding=(hash top=10 bottom=10 left=10 right=10)
   *    background="#000"
   *    enableHover=true
   *    rendererType="svg"
   *    logLevel="None"
   *    events=(hash click=(action "click"))
   *    signalEvents=(hash foo=(action "fooSignalChanged"))
   *    onParseError=(action "parseError")
   *    onNewVis=(action "newVisCreated")
   *}}
   * ```
   *
   * @class VegaVis
   * @public
   */
  var _default = Ember.Component.extend({
    classNames: ['vega-vis'],
    layout: _vegaVis.default,
    LOG_LEVELS: Ember.computed(function () {
      return {
        None: _vega.None,
        Error: _vega.Error,
        Warn: _vega.Warn,
        Info: _vega.Info,
        Debug: _vega.Debug
      };
    }),

    /**
     * Equality check between two padding objects.
     *
     * @method isSamePadding
     * @param {any} a
     * @param {any} b
     * @return {Boolean}
     */
    isSamePadding: function isSamePadding(a, b) {
      if (Ember.isPresent(a) && Ember.isPresent(b)) {
        return a.top === b.top && a.left === b.left && a.right === b.right && a.bottom === b.bottom;
      }

      return a === b;
    },

    /**
     * Simple equality check between two objects.
     *
     * @method isSameData
     * @param {any} a
     * @param {any} b
     * @return {Boolean}
     */
    isSameData: function isSameData(a, b) {
      return a === b;
    },

    /**
     * Equality check by comparing two JSON.stringify'ied objects.
     *
     * @method isSameSpec
     * @param {any} a
     * @param {any} b
     * @return {Boolean}
     */
    isSameSpec: function isSameSpec(a, b) {
      return a === b || JSON.stringify(a) === JSON.stringify(b);
    },

    /**
     * Determine if a value is a `vega-dataflow` changeset or not.
     *
     * @method isChangeSet
     * @param {Vega.Changeset} change
     * @return {Boolean}
     */
    isChangeSet: function isChangeSet(change) {
      return change && change.constructor === _vega.changeset;
    },

    /**
     * Vega visualization instance.
     *
     * [Vega Docs: View](https://vega.github.io/vega/docs/api/view/)
     *
     * @argument vis
     * @default null
     * @type {Vega.View}
     */
    vis: null,

    /**
     * Defines default visual values to set a visualization’s theme.
     *
     * Passed to create Vega visualization's runtime.
     *
     * [Vega Docs: Config](https://vega.github.io/vega/docs/config/)
     *
     * @argument config
     * @optional
     * @default null
     * @type {Object}
     */
    config: null,

    /**
     * Specification of Vega visualization.
     *
     * Used to create Vega visualization's runtime.
     *
     * [Vega Docs: Specification](https://vega.github.io/vega/docs/specification/)
     *
     * @argument spec
     * @computed spec
     * @required
     * @type {Object}
     */
    spec: Ember.computed({
      get: function get() {
        throw new Error('spec parameter is required for vega-vis');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Width of the visualization in pixels. If one is not defined, the `width` defined in the spec will be used.
     *
     * Passed to `view.width` method.
     *
     * [Vega Docs: Specification](https://vega.github.io/vega/docs/specification/)
     * [Vega Docs: View.width](https://vega.github.io/vega/docs/api/view/#view_width)
     *
     * @argument width
     * @default null
     * @optional
     * @type {Number|null}
     */
    width: null,

    /**
     * Height of the visualization in pixels. If one is not defined, the `height` defined in the spec will be used.
     *
     * Passed to `view.height` method
     *
     * [Vega Docs: Specification](https://vega.github.io/vega/docs/specification/)
     * [Vega Docs: View.height](https://vega.github.io/vega/docs/api/view/#view_height)
     *
     * @argument height
     * @default null
     * @optional
     * @type {Number|null}
     */
    height: null,

    /**
     * Vega visualization's background color.
     *
     * Passed to `view.background` method.
     *
     * [Vega Docs: Specification](https://vega.github.io/vega/docs/specification/)
     * [Vega Docs: View.background](https://vega.github.io/vega/docs/api/view/#view_background)
     *
     * @argument background
     * @default null
     * @optional
     * @type {String|null}
     */
    background: null,

    /**
     * Vega visualization's padding in pixels.
     *
     * Passed to `view.padding` method.
     *
     * [Vega Docs: Specification](https://vega.github.io/vega/docs/specification/)
     * [Vega Docs: View.padding](https://vega.github.io/vega/docs/api/view/#view_padding)
     *
     * @argument padding
     * @optional
     * @type {Object|null}
     * @param {Number} top
     * @param {Number} bottom
     * @param {Number} left
     * @param {Number} right
     */
    padding: null,

    /**
     * Enable hover event processing.
     * If an object, properties `hoverSet` and `updateSet` will be passed when enabling hover event processing.
     *
     * ```javascript
     * vis.hover(enableHover.hoverSet, enableHover.updateSet)
     * ```
     *
     * [Vega Docs: View.hover](https://vega.github.io/vega/docs/api/view/#view_hover)
     *
     * @argument enableHover
     * @default true
     * @type {Boolean|Object}
     * @param {String} [hoverSet]
     * @param {String} [updateSet]
     */
    enableHover: true,

    /**
     * KV pairs of dataset name and array of values, a changeset, or a function.
     *
     * Where `value` is an array, the array of values will replace the existing dataset by creating a changeset instance.
     *
     * Where `value is a changeset instance, the changeset will be set on the Vega.View instance via the `change` method.
     *
     * Where `value` is a function, the Vega.View instance, current dataset "live" array, and a changeset instance will be
     * passed as arguments. Inserting and removing of data in the changeset, and setting the changeset on the vega
     * instance will be the responsibility of the function. Refer to the documentation below for helper methods for
     * inserting, removing, or changing data.
     *
     * [Vega Docs: View.change](https://github.com/vega/vega-view#view_change)
     * [Vega Docs: View.insert](https://github.com/vega/vega-view#view_insert)
     * [Vega Docs: View.remove](https://github.com/vega/vega-view#view_remove)
     *
     * An example of the function, removing odd-numbered indexed datum:
     *
     * ```javascript
     * // component.js
     *
     * myData(vis, data, change) {
     *     change.remove((datum) => datum.id % 2 === 0);
     *     vis.change('my-data', change);
     * },
     *
     * data: computed(function() {
     *     return {
     *         'my-data': this.myData.bind(this)
     *     }
     * })
     *
     * ```
     *
     * ```html
     * {{vega-vis spec=spec data=data}}
     * ```
     *
     * [Vega Docs: Data](https://vega.github.io/vega/docs/data/)
     *
     * @argument data
     * @computed data
     * @type {Object.<String, (Array|Function)>}
     */
    data: Ember.computed({
      get: function get() {
        return {};
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Method to execute when a new visualziation has been created.
     *
     * @argument onNewVis
     * @type {Function}
     * @param {Vega.View} vis The visualization view object.
     */
    onNewVis: function onNewVis(vis) {},
    // eslint-disable-line no-unused-vars

    /**
     * Method to execute when creating a visualization throws an error.
     *
     * @argument onParseError
     * @type {Function}
     * @param {Error} error The error that was thrown.
     */
    onParseError: function onParseError(error) {},
    // eslint-disable-line no-unused-vars

    /**
     * Render Vega visualization with `svg` or `canvas`.
     *
     * [Vega Docs: View.renderer](https://vega.github.io/vega/docs/api/view/#view_renderer)
     *
     * @argument rendererType
     * @default 'svg'
     * @type {string}
     */
    rendererType: 'svg',

    /**
     * Set the Vega visualization log level.
     *
     * Valid values: `None`, `Warn`, `Info`, `Debug`
     *
     * [Vega Docs: View.logLevel](https://vega.github.io/vega/docs/api/view/#view_logLevel)
     *
     * @argument logLevel
     * @default 'None'
     * @type {string}
     */
    logLevel: 'None',

    /**
     * Events to add to vega view instance.
     *
     * [Vega Docs: View.addEventListener](https://vega.github.io/vega/docs/api/view/#view_addEventListener)
     * [Vega Docs: View.removeEventListener](https://vega.github.io/vega/docs/api/view/#view_removeEventListener)
     *
     * @argument events
     * @default null
     * @type {Object|null}
     */
    events: null,

    /**
     * Signal events to add to Vega View instance.
     *
     * [Vega Docs: View.addSignalListener](https://vega.github.io/vega/docs/api/view/#view_addSignalListener)
     * [Vega Docs: View.removeSignalListener](https://vega.github.io/vega/docs/api/view/#view_removeSignalListener)
     *
     * @argument signalEvents
     * @default null
     * @type {Object|null}
     */
    signalEvents: null,

    /**
     * Determines if the component is being rendered in Fastboot.
     *
     * @computed fastboot
     * @type {Object|undefined}
     */
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    /**
     * Container element for rendering the Vega View instance.
     *
     * The visualization can be rendered in a different container if desired. This helps with reducing the number of elements.
     *
     * The example also sets the `tagName` property to an empty string, this effectively prevents `vega-vis` from creating its own element.
     *
     * ```hbs
     * {{#foo-thing as |element|}}
     *     {{vega-vis spec=spec container=element tagName=""}}
     * {{/foo-thing}}
     * ```
     *
     * @computed container
     * @argument container
     * @default {Element} The component's element.
     * @type {Element}
     */
    visContainer: Ember.computed({
      get: function get() {
        return Ember.get(this, 'element');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Updates visualization due to attr changes.
     * If the spec changes, the old visualization will be removed and a whole new visualization will be created.
     *
     * @method didReceiveAttrs
     */
    didReceiveAttrs: (0, _emberDiffAttrs.default)('spec', 'width', 'height', 'rendererType', 'logLevel', 'background', 'padding', 'data', 'enableHover', 'events', 'signalEvents', function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (changedAttrs) {
        var spec = changedAttrs.spec;
        var vis = Ember.get(this, 'vis');

        if (spec) {
          var _spec = _slicedToArray(spec, 2),
              oldSpec = _spec[0],
              newSpec = _spec[1];

          if (!this.isSameSpec(oldSpec, newSpec)) {
            // Prepare vis to be replaced by "finalizing", which cleans up events that wre attached to it.
            if (vis) {
              vis.finalize();
            }

            Ember.run.scheduleOnce('afterRender', this, 'createVis', newSpec);
          }
        } else {
          if (vis) {
            var data = changedAttrs.data,
                padding = changedAttrs.padding,
                events = changedAttrs.events,
                signalEvents = changedAttrs.signalEvents,
                rendererType = changedAttrs.rendererType,
                logLevel = changedAttrs.logLevel;
            var changed = false;

            if (events) {
              var _events = _slicedToArray(events, 2),
                  oldEvents = _events[0],
                  newEvents = _events[1];

              this.removeEvents(vis, oldEvents);
              this.addEvents(vis, newEvents);
              changed = true;
            }

            if (signalEvents) {
              var _signalEvents = _slicedToArray(signalEvents, 2),
                  oldSignalEvents = _signalEvents[0],
                  newSignalEvents = _signalEvents[1];

              this.removeSignalEvents(vis, oldSignalEvents);
              this.addSignalEvents(vis, newSignalEvents);
              changed = true;
            }

            ['width', 'height', 'background'].filter(function (method) {
              return changedAttrs[method];
            }).map(function (method) {
              var _changedAttrs$method = _slicedToArray(changedAttrs[method], 2),
                  oldArg = _changedAttrs$method[0],
                  newArg = _changedAttrs$method[1];

              if (oldArg !== newArg) {
                vis[method](newArg);
                changed = true;
              }
            });

            if (logLevel) {
              var newLogLevel = logLevel[1];
              var LOG_LEVELS = Ember.get(this, 'LOG_LEVELS');
              var foundLogLevel = LOG_LEVELS[newLogLevel];

              if (Ember.isPresent(foundLogLevel)) {
                vis.logLevel(foundLogLevel);
              }
            }

            if (rendererType) {
              var _rendererType = _slicedToArray(rendererType, 2),
                  oldRendererType = _rendererType[0],
                  newRendererType = _rendererType[1];

              if (oldRendererType !== newRendererType) {
                vis.renderer(newRendererType);
                changed = true;
              }
            }

            if (padding) {
              var _padding = _slicedToArray(padding, 2),
                  oldPadding = _padding[0],
                  newPadding = _padding[1];

              if (!this.isSamePadding(oldPadding, newPadding)) {
                vis.padding(newPadding || spec.padding);
                changed = true;
              }
            }

            if (data) {
              var _spec2 = Ember.get(this, 'spec');

              var _data$map = data.map(function (dataSet) {
                return _this._normalizeData(dataSet);
              }),
                  _data$map2 = _slicedToArray(_data$map, 2),
                  oldData = _data$map2[0],
                  newData = _data$map2[1];

              _spec2.data.map(function (d) {
                var oldDataSet = oldData && oldData[d.name] || null;
                var newDataSet = newData && newData[d.name] || null;

                if (!_this.isSameData(oldDataSet, newDataSet)) {
                  _this.updateData(vis, d.name, newDataSet);

                  changed = true;
                }
              });
            } // hover API does not support disabling, so commenting out for now.
            // if (enableHover) {
            //     const [oldEnableHover, newEnableHover] = enableHover;
            //
            //     if (oldEnableHover !== newEnableHover) {
            //         vis.hover();
            //         changed = true;
            //     }
            // }


            if (changed) {
              this.visRun(vis);
            }
          }
        }
      }
    }),

    /**
     * Executes creation of visualization.
     *
     * @method didInsertElement
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, 'createVis', Ember.get(this, 'spec'));
    },

    /**
     * Creates a visualization from the spec and attrs.
     * Thrown errors will clear the visualization and execute `onParseError`
     *
     * @method createVis
     * @param {Object} spec The specification used to create a Vega.View instance
     */
    createVis: function createVis(spec) {
      var _this2 = this;

      if (spec) {
        try {
          var methods = ['width', 'height', 'padding', 'background'];

          var _EmberGetProperties = Ember.getProperties(this, 'data', 'config', 'events', 'signalEvents', 'enableHover', 'rendererType', 'logLevel', 'visContainer', 'LOG_LEVELS'),
              data = _EmberGetProperties.data,
              config = _EmberGetProperties.config,
              events = _EmberGetProperties.events,
              signalEvents = _EmberGetProperties.signalEvents,
              enableHover = _EmberGetProperties.enableHover,
              rendererType = _EmberGetProperties.rendererType,
              logLevel = _EmberGetProperties.logLevel,
              visContainer = _EmberGetProperties.visContainer,
              LOG_LEVELS = _EmberGetProperties.LOG_LEVELS;

          var foundLogLevel = LOG_LEVELS[logLevel];
          var methodArgs = Ember.getProperties.apply(void 0, [this].concat(methods));
          var runtime = (0, _vega.parse)(spec, config);
          var vis = new _vega.View(runtime); // Only initialize if not in fastboot.

          if (!Ember.get(this, 'fastboot')) {
            vis.initialize(visContainer);
          }

          this.addEvents(vis, events);
          this.addSignalEvents(vis, signalEvents);

          if (Ember.isPresent(foundLogLevel)) {
            vis.logLevel(foundLogLevel);
          }

          methods.filter(function (method) {
            return Ember.isPresent(methodArgs[method]);
          }).map(function (method) {
            vis[method](methodArgs[method]);
          });
          vis.renderer(rendererType);

          if (spec.data && data) {
            data = this._normalizeData(data);
            spec.data.filter(function (d) {
              return data[d.name];
            }).map(function (d) {
              _this2.updateData(vis, d.name, data[d.name]);
            });
          }

          if (enableHover) {
            var hoverSet, updateSet;

            if (Ember.typeOf(enableHover) === 'object') {
              hoverSet = enableHover.hoverSet;
              updateSet = enableHover.updateSet;
            }

            vis.hover(hoverSet, updateSet);
          }

          this.visRun(vis);
          Ember.set(this, 'vis', vis);
          this.onNewVis(vis);
        } catch (e) {
          Ember.run.scheduleOnce('afterRender', this, 'clearVis');
          this.onParseError(e);
        }
      }
    },

    /**
     * Prepare the visualization to be removed.
     *
     * @method clearVis
     */
    clearVis: function clearVis() {
      var vis = Ember.get(this, 'vis');

      if (vis) {
        // `finalize` will also remove event listeners attached to the DOM
        vis.finalize();
      }

      var _EmberGetProperties2 = Ember.getProperties(this, 'isDestroying', 'isDestroyed'),
          isDestroying = _EmberGetProperties2.isDestroying,
          isDestroyed = _EmberGetProperties2.isDestroyed;

      if (!isDestroyed || isDestroying) {
        Ember.set(this, 'vis', null);
      }
    },

    /**
     * Data is expected to be an object containing name/dataset pairs.
     *
     * In case the data is actually an array, convert it to an object.
     *
     * @method _normalizeData
     * @private
     * @param {Object|Array} data Datasets used for rendering the Vega.View instance
     * @return {Object}
     */
    _normalizeData: function _normalizeData(data) {
      if (Ember.isArray(data)) {
        data = data.reduce(function (acc, dataSet) {
          var name = dataSet.name,
              values = dataSet.values;
          acc[name] = values;
          return acc;
        }, {});
      }

      return data;
    },

    /**
     * Update a dataset. The value can be an array, a function, or a changeset instance.
     *
     * Where `value` is an array, the array of values will replace the existing dataset by creating a changeset instance.
     *
     * Where `value is a changeset instance, the changeset will be set on the Vega.View instance via the `change` method.
     *
     * Where `value` is a function, the Vega.View instance, current dataset "live" array, and a changeset instance will be
     * passed as arguments. Inserting and removing of data in the changeset, and setting the changeset on the vega
     * instance will be the responsibility of the function. Refer to the documentation below for helper methods for
     * inserting, removing, or changing data.
     *
     * `vega.change` https://github.com/vega/vega-view#view_change
     * `vega.insert` https://github.com/vega/vega-view#view_insert
     * `vega.remove` https://github.com/vega/vega-view#view_remove
     *
     * An example of the function, removing odd-numbered indexed datum:
     *
     * ```javascript
     * // component.js
     *
     * myData(vis, data, change) {
     *     change.remove((datum) => datum.id % 2 === 0);
     *     vis.change('my-data', change);
     * },
     *
     * data: computed(function() {
     *     return {
     *         'my-data': this.myData.bind(this)
     *     }
     * })
     *
     * ```
     *
     * ```html
     * {{vega-vis spec=spec data=data}}
     * ```
     *
     * @method updateData
     * @param {Vega.View} vis
     * @param {String} name name of dataset
     * @param {Function|Array|Object} value A function that accepts a changeset, an array of values, or a changeset instance.
     */
    updateData: function updateData(vis, name, value) {
      if (vis) {
        if (value) {
          if (typeof value === 'function') {
            value(vis, vis.data(name), (0, _vega.changeset)());
          } else {
            // If the value isn't a change set,
            if (!this.isChangeSet(value)) {
              value = (0, _vega.changeset)().remove(function () {
                return true;
              }).insert(value);
            }

            vis.change(name, value);
          }
        }
      }
    },

    /**
     * Remove the visualization during before the component is destroyed.
     *
     * @method willDestroyElement
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments); // Clear without scheduling because scheduling introduces a race condition where a two-way-bound property gets nulled
      // on a "parent" component/controller that has been destroyed. Without scheduling, the two-way-bound property changes without
      // trying to set on a destroyed component/controller.


      this.clearVis();
    },

    /**
     * Invoke visualization `run` method to render the visualization.
     * Will only `run` when component hasn't been destroyed or in the process of being destroyed.
     *
     * @method visRun
     * @param {Vega.View} vis Vega.View instance
     * @param {String|undefined} encode
     */
    visRun: function visRun(vis, encode) {
      var _EmberGetProperties3 = Ember.getProperties(this, 'isDestroyed', 'isDestroying'),
          isDestroyed = _EmberGetProperties3.isDestroyed,
          isDestroying = _EmberGetProperties3.isDestroying;

      if (vis && (!isDestroyed || !isDestroying)) {
        vis.run(encode);
      }
    },

    /**
     * Abstract method to help add/remove events/signals to the visulzation.
     *
     * @method _invokeEventMethod
     * @private
     * @param {Vega.View} vis Vega.View instance
     * @param {String} method Name of method, such as addEventListener or removeSignalListener
     * @param {Object} events KV pairs of event/signal names and functions
     */
    _invokeEventMethod: function _invokeEventMethod(vis, method, events) {
      if (vis && events) {
        for (var _i2 = 0, _Object$entries = Object.entries(events); _i2 < _Object$entries.length; _i2++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i2], 2),
              event = _Object$entries$_i[0],
              handler = _Object$entries$_i[1];

          vis[method](event, handler);
        }
      }
    },

    /**
     * Adds event listeners contained in an `events` object.
     *
     * @method addEvents
     * @param {Vega.View} vis Vega.View instance
     * @param {Object} events KV pairs of event names and functions. https://github.com/vega/vega-view#view_addEventListener
     */
    addEvents: function addEvents(vis) {
      var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._invokeEventMethod(vis, 'addEventListener', events);
    },

    /**
     * Removes event listeners contained in an `events` object.
     *
     * @method removeEvents
     * @param {Vega.View} vis Vega.View instance
     * @param {Object} events KV pairs of event names and functions. https://github.com/vega/vega-view#view_removeEventListener
     */
    removeEvents: function removeEvents(vis) {
      var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._invokeEventMethod(vis, 'removeEventListener', events);
    },

    /**
     * Adds signal event listeners contained in a `signalevents` object.
     *
     * @method addSignalEvents
     * @param {Vega.View} vis Vega.View instance
     * @param {Object} signalEvents KV pairs of signal evnet names and functions. https://github.com/vega/vega-view#view_addSignalListener
     */
    addSignalEvents: function addSignalEvents(vis) {
      var signalEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._invokeEventMethod(vis, 'addSignalListener', signalEvents);
    },

    /**
     * Removes signal event listeners contained in a `signalevents` object.
     *
     * @method removeSignalEvents
     * @param {Vega.View} vis Vega.View instance
     * @param {Object} signalEvents KV pairs of signal evnet names and functions. https://github.com/vega/vega-view#view_addSignalListener
     */
    removeSignalEvents: function removeSignalEvents(vis) {
      var signalEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._invokeEventMethod(vis, 'removeSignalListener', signalEvents);
    }
  });

  _exports.default = _default;
});