define("snapdragon-common/helpers/google-account-chooser-link", ["exports", "ember-get-config", "url"], function (_exports, _emberGetConfig, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.googleAccountChooserLink = googleAccountChooserLink;
  _exports.default = _exports.missingContinueUrlParamError = _exports.missingEmailAddressParamError = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var missingEmailAddressParamError = new Error('google-account-chooser-link helper requires an `emailAddress` positional param');
  _exports.missingEmailAddressParamError = missingEmailAddressParamError;
  var missingContinueUrlParamError = new Error('google-account-chooser-link helper requires a `continueUrl` positional param');
  /**
   * Construct a URL that allows Google to choose the correct account that is
   * linked to their Fitbit account before continuing on to the `continueUrl`
   * @param {[emailAddress, continueUrl]: [string, string]} param0
   * @returns
   */

  _exports.missingContinueUrlParamError = missingContinueUrlParamError;

  function googleAccountChooserLink(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 2),
        emailAddress = _ref2[0],
        continueUrl = _ref2[1];

    if (!emailAddress) {
      throw missingEmailAddressParamError;
    }

    if (!continueUrl) {
      throw missingContinueUrlParamError;
    }

    var link = new _url.default(_emberGetConfig.default.googleAccountChooserLink);
    var searchParams = link.searchParams;
    searchParams.append('Email', emailAddress);
    searchParams.append('continue', continueUrl);
    searchParams.append('faa', 1);
    return link.toString();
  }

  var _default = Ember.Helper.helper(googleAccountChooserLink);

  _exports.default = _default;
});