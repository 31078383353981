define("snapdragon-common/templates/components/fit-modal-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ObgsBev",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"close-button\"],[11,\"data-close\",\"\"],[12,\"aria-label\",[28,[[27,\"format-by-namespace\",[\"aria_cancel_and_close\",\"common.aria\"],null]]]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"modal-header-back\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showHeaderArrow\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"href\",\"#\"],[12,\"onclick\",[21,\"backArrowAction\"]],[11,\"data-close\",\"\"],[11,\"class\",\"row-link unstyled-modal-header-button modal-header-arrow-button\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"fitglyph fitglyph-arrow-left2 modal-header-row-focus\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"modal-header-text\"],[9],[1,[21,\"headerTitle\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"div\"],[9],[1,[21,\"headerTitle\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/fit-modal-header.hbs"
    }
  });

  _exports.default = _default;
});