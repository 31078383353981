define("snapdragon-common/utils/split-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = splitLocale;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Split the locale to provide language and region.
   * @param {string} locale
   * @returns {{language: string, region: string}}
   */
  function splitLocale(locale) {
    var _trim$split = (locale || '').trim().split('_'),
        _trim$split2 = _slicedToArray(_trim$split, 2),
        language = _trim$split2[0],
        region = _trim$split2[1];

    return {
      language: language,
      region: region
    };
  }
});