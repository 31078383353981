define("snapdragon-common/components/cw-form-rich-select-before-options", ["exports", "snapdragon-common/templates/components/cw-form-rich-select-before-options"], function (_exports, _cwFormRichSelectBeforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEY_ENTER = 13;
  /**
   * Custom beforeOptions component for power-select.
   *
   * The most part of this component implementation was taken from the default beforeOptions component.
   * (https://github.com/cibernox/ember-power-select).
   *
   */

  var _default = Ember.Component.extend({
    layout: _cwFormRichSelectBeforeOptions.default,
    tagName: '',
    autofocus: true,
    isMobile: false,
    label: '',
    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.isMobile && this.autofocus) {
        this.focusInput();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.searchEnabled) {
        Ember.run.scheduleOnce('actions', this, this.select.actions.search, '');
      }
    },
    // Actions
    actions: {
      onKeydown: function onKeydown(e) {
        if (this.onKeydown(e) === false) {
          return false;
        }

        if (e.keyCode === KEY_ENTER) {
          this.select.actions.close(e);
        }
      },
      onCancel: function onCancel() {
        this.select.actions.close();
      }
    },
    // Methods
    focusInput: function focusInput() {
      var _this = this;

      this.input = document.querySelector(".ember-power-select-search-input[aria-controls=\"".concat(this.listboxId, "\"]"));

      if (this.input) {
        // Backport of this issue https://github.com/cibernox/ember-power-select/pull/1180
        Ember.run.later(function () {
          _this.input.focus();
        }, 0);
      }
    }
  });

  _exports.default = _default;
});