define("snapdragon-common/utils/template-string-generators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateActivityMapOverlay = generateActivityMapOverlay;

  function generateActivityMapOverlay(isEndTrackpoint, distance, unit) {
    return "<div id=\"trackpoint-marker-overlay\" class=\"".concat(isEndTrackpoint ? 'end' : '', "\">\n                <span class=\"value\">").concat(distance.toFixed(2), "</span>\n                <span class=\"unit\">").concat(unit, "</span>\n            </div>");
  }
});