define("snapdragon-common/mixins/models/non-rest-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    nonRestAction: function nonRestAction(action, method, data) {
      var modelName = this.constructor.modelName;
      var adapter = Ember.getOwner(this).lookup("adapter:".concat(modelName));
      return adapter.ajax(this.getActionUrl(action, adapter), method, {
        data: data
      });
    },
    getActionUrl: function getActionUrl(action, adapter) {
      var modelName = this.constructor.modelName;
      var id = this.get('id');
      return "".concat(adapter.buildURL(modelName, id), "/").concat(action);
    }
  });

  _exports.default = _default;
});