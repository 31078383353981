define("snapdragon-common/mixins/animations-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      /**
       * Adds the bounce-element to css class of the element passed in
       * The bounce animation will run for 1 second
       * @param element
       */
      bounceAnimation: function bounceAnimation(element) {
        if (element) {
          element.classList.remove('bounce-element');
          element.offsetWidth; // this is need for the browser to recognize the change in class

          element.classList.add('bounce-element');
        }
      },
      pulseAnimation: function pulseAnimation(element) {
        var isFailure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (element) {
          if (!isFailure) {
            element.classList.remove('box-shadow-pulse-element');
            element.offsetWidth; // this is need for the browser to recognize the change in class

            element.classList.add('box-shadow-pulse-element');
          } else {
            element.classList.remove('box-shadow-pulse-element-failure');
            element.offsetWidth; // this is need for the browser to recognize the change in class

            element.classList.add('box-shadow-pulse-element-failure');
          }
        }
      },
      notificationCardPopout: function notificationCardPopout(element) {
        Ember.run.scheduleOnce('afterRender', function () {
          if (element) {
            element.classList.remove('move-left-element');
            element.offsetWidth; // this is need for the browser to recognize the change in class

            element.classList.add('move-left-element');
          }
        });
      },
      spinAndGrow: function spinAndGrow(element) {
        Ember.run.scheduleOnce('afterRender', function () {
          if (element) {
            element.classList.remove('spin-and-grow');
            element.offsetWidth; // this is need for the browser to recognize the change in class

            element.classList.add('spin-and-grow');
          }
        });
      }
    }
  });

  _exports.default = _default;
});