define("snapdragon-common/templates/components/cw-form-field-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kcX+XeRw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"form-field-label\"],[12,\"data-test-qa\",[27,\"if\",[[23,[\"dataTestQa\"]],[27,\"concat\",[\"label-\",[23,[\"dataTestQa\"]]],null]],null]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"form-field-error\"],[12,\"data-test-qa\",[27,\"if\",[[23,[\"dataTestQa\"]],[27,\"concat\",[\"error-\",[23,[\"dataTestQa\"]]],null]],null]],[9],[1,[27,\"if\",[[23,[\"showError\"]],[23,[\"errorText\"]]],null],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-form-field-group.hbs"
    }
  });

  _exports.default = _default;
});