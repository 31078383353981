define("consent-data/models/consent-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    consents: hasMany('consent', {
      async: false
    })
  });

  _exports.default = _default;
});