define("ember-fitbit-pwd-auth/mixins/fitbit-pwd-auth-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    sessionRefresh: Ember.inject.service(),

    /**
    ember-simple-auth authentication using the fitbit-password authenticator
    @param {String} identification - the account username(email)
    @param {String} password
    @return Promise
    @public
    */
    fitbitPasswordOauth2Login: function fitbitPasswordOauth2Login(identification, password) {
      var session = Ember.get(this, 'session');
      return session.authenticate('authenticator:fitbit-password', identification, password);
    },

    /**
    Calls ember-simple-auth internal session restore method to refresh the session information
    @return Promise
    @public
    */
    refreshAccessToken: function refreshAccessToken() {
      var session = this.get('session');
      var data = session.get('data.authenticated');
      return session.get('session').restore(data);
    },

    /**
    Sets-up an ember-simple-auth session using the session data and fitbit-password authenticator
    @param {Object} data - the session information returned by the Fitbit OAuth API
    @return Promise
    @public
    */
    setupSession: function setupSession(data) {
      var session = this.get('session');
      return session.get('session')._setup('authenticator:fitbit-password', data, true);
    }
  });

  _exports.default = _default;
});