define("snapdragon-common/mixins/fit-deeplink-nav", ["exports", "snapdragon-common/constants/user-agent"], function (_exports, _userAgent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var DEFAULT_DEEPLINK_TARGET_PATH = 'dashboard';
  /**
   * @typedef DeeplinkPathConfig
   * @type {Object}
   * @property {String} path
   * @property {Object} queryParams
   */

  /**
   * To use, include as a mixin, and when you to navigate to the deeplink,
   * call `set(this, 'deeplinkPath', __path__);` where path is valid fitbit://__path__
   */

  var _default = Ember.Mixin.create({
    /**
     * Defined by the caller, should be a valid path for a deeplink to the mobile app
     * @type {String|DeeplinkPathConfig}
     */
    deeplinkPath: null,
    deeplinkUrlPrefix: 'fitbit://',

    /**
     * Redirect the user to the app at fitbit://__path__?<queryParams>
     * Where __path__ is defined when templating the component. Allows to pass additional query params.
     * Triggered by setting deeplinkPath
     */
    deeplinkNavigation: Ember.observer('deeplinkPath', function () {
      var path = Ember.get(this, 'deeplinkPath');
      this.deeplinkTo(path);
    }),

    /**
     * Takes a path, constructs a full path, and redirects.
     * @param {String} path
     */
    deeplinkTo: function deeplinkTo(path) {
      if (path === null) {
        return;
      }

      this._redirectToDeeplink(this.getFullPath(path));
    },

    /**
     * Constructs full path for provided link
     */
    getFullPath: function getFullPath(path) {
      if (path === null) {
        return;
      }

      if (_typeof(path) === 'object') {
        path = this._parsePathConfiguration(path);
      }

      return this._formatFullDeeplinkPath(path);
    },

    /**
     * Added to enable testing/stubbing
     * @param fullPath - the deeplink to navigate to
     * @private
     */
    _redirectToDeeplink: function _redirectToDeeplink(fullPath) {
      if (!Ember.testing && _userAgent.IS_MOBILE) {
        window.location = fullPath;
      }
    },

    /**
     * @private
     * @param {String} path
     * @returns {String}
     */
    _formatFullDeeplinkPath: function _formatFullDeeplinkPath() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_DEEPLINK_TARGET_PATH;
      var deeplinkUrlPrefix = Ember.get(this, 'deeplinkUrlPrefix');

      if (path.includes(deeplinkUrlPrefix)) {
        return path;
      } else {
        return deeplinkUrlPrefix.concat(path);
      }
    },

    /**
     * @private
     * @param {DeeplinkPathConfig} config
     * @returns {String|undefined}
     */
    _parsePathConfiguration: function _parsePathConfiguration(config) {
      var path = Ember.get(config, 'path');

      if (!path) {
        return;
      }

      var queryParams = Ember.get(config, 'queryParams');
      var serializedQueryParams;

      if (queryParams) {
        serializedQueryParams = Object.keys(queryParams).reduce(function (result, key) {
          return result.concat("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(queryParams[key])));
        }, []).join('&');
        return path.concat('?', serializedQueryParams);
      }

      return path;
    }
  });

  _exports.default = _default;
});