define("ember-tags-input/components/eti-svg-icons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ms0nhqZR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"id\",\"eti-icon-catalog\"],[9],[0,\"\\n  \"],[7,\"symbol\"],[11,\"id\",\"eti-cancel\"],[11,\"viewBox\",\"0 0 16 16\"],[9],[0,\"\\n    \"],[7,\"polygon\"],[11,\"points\",\"12 3 7.854 7.147 3.707 3 3 3.707 7.146 7.854 3 12 3.707 12.707 7.854 8.561 12 12.707 12.707 12 8.56 7.854 12.707 3.707\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tags-input/components/eti-svg-icons/template.hbs"
    }
  });

  _exports.default = _default;
});