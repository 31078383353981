define("snapdragon-common/templates/components/cw-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ipRPohU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"fit-glyph\",null,[[\"type\",\"class\"],[\"spinner\",\"corporate-button-loader\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"corporate-button-content\"],[9],[14,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-button.hbs"
    }
  });

  _exports.default = _default;
});