define("snapdragon-common/errors/too-many-connections-error", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Too Many Connections Error is used to check if the friend invite accept response returns a
   * backend "tooManyConnectionsError", meaning the user has reached the 2000 friend limit.
   */
  var _default = _emberData.default.AdapterError.extend({
    message: 'Too Many Connections Error'
  });

  _exports.default = _default;
});