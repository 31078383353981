define("consent-data/mixins/application-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    gdpr: Ember.inject.service(),

    /**
     * Intercept the transition and prevent transition if the transition target is the same as the consentRoute.
     * @param transition
     * @returns {*|void|{then}}
     */
    gdprTransitionIntercept: function gdprTransitionIntercept(transition) {
      var targetName = transition.targetName;
      var route = Ember.get(this, 'gdpr.nextConsentRoute');

      if (route && targetName !== route) {
        var gdpr = Ember.get(this, 'gdpr');
        var queryParams = this.paramsFor('application');
        Ember.set(gdpr, 'previousTransition', transition);
        return this.replaceWith(route, {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});