define("snapdragon-common/mixins/email-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    _saveEmailAddress: function _saveEmailAddress(data) {
      var _this = this;

      if (!Ember.get(this, 'isLoading')) {
        Ember.set(this, 'isLoading', true);
        var email = Ember.get(data, 'email');
        return Ember.get(this, 'ajax').post('/account/change-email.json', {
          data: {
            email: email,
            password: Ember.get(data, 'password')
          },
          namespace: '1'
        }).catch(function (err) {
          throw err; // this is handled by the change-email-address component in the changeEmailAddress action
        }).finally(function () {
          Ember.set(_this, 'isLoading', false);
        });
      }
    },
    _cancelEmailAddress: function _cancelEmailAddress() {
      var _this2 = this;

      if (!Ember.get(this, 'isLoading')) {
        Ember.set(this, 'isLoading', true);
        return Ember.get(this, 'ajax').delete('/account/pending-email.json', {
          namespace: '1'
        }).catch(function (err) {
          throw err; // this is handled by the change-email-address component in the cancelEmailAddress action
        }).finally(function () {
          Ember.set(_this2, 'isLoading', false);
        });
      }
    },
    _resendEmailAddress: function _resendEmailAddress() {
      var _this3 = this;

      if (!Ember.get(this, 'isLoading')) {
        Ember.set(this, 'isLoading', true);
        return Ember.get(this, 'ajax').post('/account/verify-email/resend.json', {
          namespace: '1'
        }).finally(function () {
          Ember.set(_this3, 'isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});