define("food-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Redirecting /mealContent?mealId=<id> to /meals/<id> to keep consistent URLs
    this.route('redirect-meal-content', {
      path: 'mealContent'
    });
    this.route('custom');
    this.route('meals', function () {
      this.route('meal', {
        path: ':food_meal_id'
      }, function () {
        this.route('edit');
      });
      this.route('create');
    });
    this.route('food-date', {
      path: ':food_date'
    });
  });

  _exports.default = _default;
});