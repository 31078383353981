define("consent-engine/routes/consent", ["exports", "snapdragon-common/routes/authenticated", "consent-data/constants/gdpr-consent-ids"], function (_exports, _authenticated, _gdprConsentIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authenticated.default.extend({
    i18n: Ember.inject.service(),
    navigation: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    gdpr: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /**
     * The document title for the route. Displays the route name.
     * @returns {*}
     */
    titleToken: function titleToken() {
      return Ember.get(this, 'i18n').formatByNamespace('route_title', 'routes.consent');
    },

    /**
     * Redirect to the next route if the transition hasn't been aborted and GDPR consents are not required.
     * @param transition
     * @returns {*}
     */
    beforeModel: function beforeModel(transition) {
      var beforeModel = this._super.apply(this, arguments); // GDPR isn't required, so transition out off here.


      if (!transition.isAborted && !Ember.get(this, 'gdpr.isRequired')) {
        var _this$paramsFor = this.paramsFor('application'),
            redirect = _this$paramsFor.redirect;
        /*
        Store the redirect, if it exists, so that it can be used when gdpr.redirectToNextRoute
        is initiated. This is necessary if the user refreshes the page.
         */


        if (redirect) {
          Ember.set(this, 'gdpr.redirectURL', redirect);
        }

        transition.abort();
        return Ember.get(this, 'gdpr').redirectToNextRoute();
      } else {
        return beforeModel;
      }
    },

    /**
     * Load user's consent record and consent contents for rendering.
     * @returns {Promise}
     */
    model: function model() {
      return Ember.RSVP.hash({
        consent: Ember.get(this, 'store').peekRecord('consent', _gdprConsentIds.REAFFIRM_EXISTING_EU_USER),
        consentContents: Ember.get(this, 'gdpr.gdprStatus.consentContents')
      });
    },
    actions: {
      /**
       * Prevent transition if consent is still required.
       * If user is unauthenticated, don't block the transition to logout route.
       * @param transition
       * @returns {*|void}
       */
      willTransition: function willTransition(transition) {
        var gdpr = Ember.get(this, 'gdpr');

        if (Ember.get(this, 'session.isAuthenticated') && Ember.get(gdpr, 'isRequired')) {
          if (!gdpr.validateTransitionRoute(transition.targetName)) {
            return transition.abort();
          }
        }
      },
      acceptConsent: function acceptConsent(consent) {
        var _this = this;

        var _EmberGetProperties = Ember.getProperties(this, 'gdpr', 'controller'),
            gdpr = _EmberGetProperties.gdpr,
            controller = _EmberGetProperties.controller;

        if (!Ember.get(controller, 'isLoading')) {
          Ember.set(controller, 'isLoading', true);
          /*
          After consent has been accepted, invoke `gdpr.redirectToNextRoute` to navigate user to the next route
          The next route is determined by simple checks in `gdpr.nextConsentRoute`, and logic to send user to a
          redirect query param, previous transition callback, previous transition, or the default which is `/`.
           */

          return gdpr.accept(consent).catch(function (err) {
            var _EmberGetProperties2 = Ember.getProperties(_this, 'flashMessages', 'i18n'),
                flashMessages = _EmberGetProperties2.flashMessages,
                i18n = _EmberGetProperties2.i18n;

            flashMessages.alert(i18n.formatByNamespace('consent_accept_error', 'routes.consent'));
            throw err;
          }).finally(function () {
            Ember.set(controller, 'isLoading', false);
          }).then(function () {
            return gdpr.redirectToNextRoute();
          }).catch(function (err) {
            /*
            We don't want to catch the TransitionAborted error. That happens whenever a transition is aborted
            via the abort method, or another transition aborts a previous one.
            We do want to surface other errors though, so throw again if it's not TransitionAborted error.
            */
            if (err.name !== 'TransitionAborted') {
              throw err;
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});