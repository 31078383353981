define("snapdragon-common/components/fit-digital-code-input", ["exports", "snapdragon-common/templates/components/fit-digital-code-input", "snapdragon-common/constants/keys"], function (_exports, _fitDigitalCodeInput, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CodeLengthPropError = CodeLengthPropError;
  _exports.default = void 0;

  /**
   * Checks if passed keyName corresponds to passed keyCode
   * @param {number} keyName - name of the key to be checked
   * @param {number} keyCode - keycode from the event object
   * @return {boolean}
   */
  var isKey = function isKey(keyName, keyCode) {
    return _keys.KEYCODES[keyName] && _keys.KEYCODES[keyName] === keyCode;
  };
  /**
   * Checks if passed keyCode stands for a numeric key
   * Due to a bug in some virtual keyboards this method falls back to checking by target value
   * https://stackoverflow.com/questions/36753548/keycode-on-android-is-always-229
   * @param {number} keyCode - keycode from the event object
   * @param {HTMLElement} target - event.target
   * @return {boolean}
   */


  var isNumericKey = function isNumericKey(keyCode, target) {
    return keyCode >= _keys.KEYCODES[_keys.NUMERIC_START_INDEX] && keyCode <= _keys.KEYCODES[_keys.NUMERIC_END_INDEX] || isKey(_keys.GENERIC, keyCode) && checkGenericKeyCode(target);
  };
  /**
   * Checks if event target's value is numeric
   * @param {HTMLElement} target - event.target
   * @return {boolean}
   */


  var checkGenericKeyCode = function checkGenericKeyCode() {
    var target = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var targetHasValue = Ember.get(target, 'value.length') > 0;
    var targetValueIsNumeric = targetHasValue && target.value.substr(-1).match(/^\d$/);
    return !!targetValueIsNumeric;
  };
  /**
   * Error to be throw when `codeLength` property is not provdied to `fit-digital-code-component`.
   * Written to extend from Error prototype because creating a class that extends from Error is problematic when Babel transpiles code.
   * https://stackoverflow.com/a/33877501
   */


  function CodeLengthPropError(message) {
    this.message = message;
    this.name = 'CodeLengthPropError';
    this.stack = new Error().stack;
  }

  CodeLengthPropError.prototype = new Error();
  /**
   * Component for "digits only" code input
   * @property {number} codeLength - length of the code to be entered and quantity of inputs - 1 per digit
   * @property {function} [onCodeInput] - function to be called on input value change
   */

  var _default = Ember.Component.extend({
    layout: _fitDigitalCodeInput.default,
    classNames: ['fit-digital-code-input'],
    classNameBindings: ['inputError:input-error'],
    inputError: false,
    digitInputSelector: 'input[type=text]',
    uiTimeout: 300,
    actions: {
      onInput: function onInput(index, _ref) {
        var target = _ref.target;
        var value = target.value === '' ? target.value : parseInt(target.value, 10);
        var isValueNumberOrBlank = !isNaN(value) || value === '';
        var isIndexInRange = index >= 0 && index < Ember.get(this, 'digits').length;

        if (isValueNumberOrBlank && isIndexInRange) {
          Ember.set(this, "digits[".concat(index, "]"), value);
          this.handleCodeInput();
        }
      },
      onKeyDown: function onKeyDown(_value, _ref2) {
        var keyCode = _ref2.keyCode,
            target = _ref2.target;
        var isAllowedKey = isNumericKey(keyCode, target) || isKey(_keys.BACKSPACE, keyCode) || isKey(_keys.TAB, keyCode) || isKey(_keys.ENTER, keyCode) || isKey(_keys.V, keyCode);

        if (!isAllowedKey) {
          event.preventDefault();
          return false;
        }
      },
      onKeyUp: function onKeyUp(index, _value, _ref3) {
        var _this = this;

        var keyCode = _ref3.keyCode,
            target = _ref3.target;
        var afterRender = null; // For some reasons mobile safari might leave a space after user erases digit

        target.value = target.value.trim();

        if (isNumericKey(keyCode, target)) {
          afterRender = function afterRender() {
            var digitsLength = Ember.get(_this, 'digits.length');
            var focusIndex = Math.min(index + 1, digitsLength - 1);

            _this.focusInput(focusIndex, false);
          };
        } else if (isKey(_keys.BACKSPACE, keyCode)) {
          afterRender = function afterRender() {
            var focusIndex = Math.max(index - 1, 0);

            _this.focusInput(focusIndex, false, index !== 0);
          };
        } // afterRender is defined and non-null


        if (afterRender != null) {
          Ember.run.scheduleOnce('afterRender', this, afterRender);
        }
      }
    },
    digits: null,
    init: function init() {
      this._super.apply(this, arguments);

      var codeLength = Ember.get(this, 'codeLength');

      if (!codeLength) {
        throw new CodeLengthPropError('codeLength property must be provided for fit-digital-code-component');
      }

      Ember.set(this, 'digits', new Array(codeLength));
      this.boundOnPaste = this.onPaste.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var digits = Ember.get(this, 'digits');
      var inputIndexToFocus = digits.findIndex(function (d) {
        return !d;
      }); // Ember input helper ignores 'paste' event, so we have to do it manually

      this.element.addEventListener('paste', this.boundOnPaste);
      this.focusInput(inputIndexToFocus !== -1 ? inputIndexToFocus : digits.length - 1, inputIndexToFocus !== -1);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('paste', this.boundOnPaste);

      this._super.apply(this, arguments);
    },

    /**
     * Focuses single digit input of a given index
     * @param {number} inputIndex - index of input to focus
     * @param {boolean} [cursorToStart] - indicates whether cursor should be put to start or end of input
     * @param {boolean} [select] - indicates if input contents should be selected
     * @return {void}
     */
    focusInput: function focusInput(inputIndex, cursorToStart, select) {
      var inputs = this.element.querySelectorAll(this.digitInputSelector);
      var inputToFocus = inputs[inputIndex];
      var selectionIndices = select ? [0, 1] : cursorToStart ? [0, 0] : [1, 1]; // inputToFocus is defined and non-null

      if (inputToFocus != null) {
        inputToFocus.focus();
        inputToFocus.setSelectionRange.apply(inputToFocus, selectionIndices);
      }
    },
    handleCodeInput: function handleCodeInput() {
      var codeString = Ember.get(this, 'digits').map(function (str) {
        return str.trim();
      }).join('');
      this.onCodeInput(codeString);
    },
    onPaste: function onPaste(event) {
      var _this2 = this;

      var pasteText = (event.clipboardData || window.clipboardData).getData('text');
      var codeRegExp = new RegExp("^\\d{".concat(this.codeLength, "}$"));
      var validContent = pasteText && pasteText.trim().match(codeRegExp);

      if (validContent) {
        this.populateInputs(validContent[0]);
      } else {
        Ember.set(this, 'inputError', true);
        Ember.run.later(this, function () {
          return Ember.set(_this2, 'inputError', false);
        }, this.uiTimeout);
        event.preventDefault();
        return false;
      }
    },
    populateInputs: function populateInputs(text) {
      var values = text.split('');
      this.$(this.digitInputSelector).each(function (index, inputEl) {
        return inputEl.value = values[index];
      });
      Ember.set(this, 'digits', values);
      this.handleCodeInput();
      this.focusInput(this.codeLength - 1, false);
    },

    /**
     * Default implementation of onCodeInput property - noop
     * @return {void}
     */
    onCodeInput: function onCodeInput() {}
  });

  _exports.default = _default;
});