define("snapdragon-common/constants/badge-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CUSTOM_GROUPS = _exports.BADGE_GROUPS = _exports.OTHER = _exports.CHALLENGE = _exports.FIT_FOR_GOOD = _exports.GOAL_BASED_WEIGHT = _exports.LIFETIME_FLOORS = _exports.LIFETIME_DISTANCE = _exports.DAILY = void 0;
  var DAILY = 'DAILY';
  _exports.DAILY = DAILY;
  var LIFETIME_DISTANCE = 'LIFETIME_DISTANCE';
  _exports.LIFETIME_DISTANCE = LIFETIME_DISTANCE;
  var LIFETIME_FLOORS = 'LIFETIME_FLOORS';
  _exports.LIFETIME_FLOORS = LIFETIME_FLOORS;
  var GOAL_BASED_WEIGHT = 'GOAL_BASED_WEIGHT';
  _exports.GOAL_BASED_WEIGHT = GOAL_BASED_WEIGHT;
  var FIT_FOR_GOOD = 'FIT_FOR_GOOD';
  _exports.FIT_FOR_GOOD = FIT_FOR_GOOD;
  var CHALLENGE = 'CHALLENGE';
  _exports.CHALLENGE = CHALLENGE;
  var OTHER = 'OTHER';
  _exports.OTHER = OTHER;
  var BADGE_GROUPS = [DAILY, LIFETIME_DISTANCE, LIFETIME_FLOORS, GOAL_BASED_WEIGHT, FIT_FOR_GOOD, CHALLENGE, OTHER];
  /**
   * CUSTOM groups are identified by badge category.
   *
   * @type {String[]}
   */

  _exports.BADGE_GROUPS = BADGE_GROUPS;
  var CUSTOM_GROUPS = [FIT_FOR_GOOD, CHALLENGE];
  _exports.CUSTOM_GROUPS = CUSTOM_GROUPS;
});