define("consent-data/services/gdpr", ["exports", "consent-data/constants/gdpr-consent-ids", "url", "ember-window-mock"], function (_exports, _gdprConsentIds, _url, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * Reference to redirect URL, used in next redirectToNextRoute.
     * A possible use would be a query parameter that's passed to the application.
     * Can be absolute or relative URL.
     * @type {null|string}
     */
    redirectURL: null,

    /**
     * Reference to previous transition callback function. Instead of retrying a transition or redirecting to a URL,
     * this function get's executed.
     *
     * @type {null|function}
     */
    previousTransitionCallback: null,

    /**
     * Reference to previous transition. When the initial application transition is intercepted, the transition needs
     * to be stored to be retried later.
     *
     * @type {null|object}
     */
    previousTransition: null,

    /**
     * Application config registered under `config:environment`. Used to look up consent-data config, and the environment.
     * @returns {object}
     */
    _config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment') || {};
    }),

    /**
     * Consent-data config stored on application's config.
     * @returns {object}
     */
    _consentDataConfig: Ember.computed('_config.consent-data', function () {
      return Ember.getWithDefault(this, '_config.consent-data', {});
    }),

    /**
     * Whitelist of URL objects.
     * @private
     */
    _consentDataUrlWhitelist: Ember.computed('_config', function () {
      var _EmberGet = Ember.get(this, '_consentDataConfig'),
          _EmberGet$urlWhitelis = _EmberGet.urlWhitelist,
          urlWhitelist = _EmberGet$urlWhitelis === void 0 ? {} : _EmberGet$urlWhitelis;

      var _location = location,
          href = _location.href;
      return Object.keys(urlWhitelist).map(function (url) {
        return new _url.default(url, href);
      });
    }),

    /**
     * Cookie options, merged with default cookie options and config cookie options.
     * @returns {object}
     */
    cookieOptions: Ember.computed('_config.environment', 'DEFAULT_COOKIE_OPTS', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'DEFAULT_COOKIE_OPTS', '_config'),
          DEFAULT_COOKIE_OPTS = _EmberGetProperties.DEFAULT_COOKIE_OPTS,
          environment = _EmberGetProperties._config.environment;

      var cookies = {};

      if (environment === 'production') {
        Ember.setProperties(cookies, {
          domain: _emberWindowMock.default.location.hostname,
          secure: true
        });
      }

      return Object.assign({}, DEFAULT_COOKIE_OPTS, cookies);
    }),

    /**
     * The name of the cookie to be read and written to store GDPR state.
     * Used to cache whether gdpr-status needs to be looked up.
     *
     * @readonly
     * @returns {string}
     */
    COOKIE_KEY: Ember.computed(function () {
      var _EmberGet2 = Ember.get(this, '_config'),
          environment = _EmberGet2.environment; // Make sure the cookies aren't mixed up during development vs tests


      if (environment === 'test') {
        return 'fitbit_gdpr_ok_test';
      } else {
        return 'fitbit_gdpr_ok';
      }
    }).readOnly(),

    /**
     * Default cookie options.
     *
     * @readonly
     * @returns {object}
     */
    DEFAULT_COOKIE_OPTS: Ember.computed(function () {
      return {
        // Expires in 1 hour
        maxAge: 3600
      };
    }).readOnly(),

    /**
     * An array pairing a consent ID to a route. Used to determine what route the user should be sent to based on
     * the order in the array.
     *
     * @readonly
     * @returns {array}
     */
    CONSENT_ROUTE_MAP: Ember.computed(function () {
      return [[_gdprConsentIds.REAFFIRM_EXISTING_EU_USER, 'consent'], [_gdprConsentIds.SETTINGS_CHECKUP, 'settings']];
    }).readOnly(),

    /**
     * Path to consent route. User is usually redirected to this route to start consent flow.
     * @type {string}
     */
    consentRoute: 'consent',

    /**
     * Path for after consents have been agreed. User is redirected to this route at the end of consent, but only
     * after a series of checks, like if the user needs to redirect away via query param or to retry a transition.
     * @type {string}
     */
    consentsAgreedRoute: 'index',

    /**
     * Returns an array of objects containing a route and test to determine whether the route should be
     * transitioned to.
     *
     * @readonly
     * @returns {object[]}
     */
    consentRouteMap: Ember.computed(function () {
      var _this = this;

      return Ember.get(this, 'CONSENT_ROUTE_MAP').map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            id = _ref2[0],
            route = _ref2[1];

        return {
          route: route,
          shouldTransitionToRoute: function shouldTransitionToRoute() {
            return _this.hasConsentId(id);
          }
        };
      });
    }).readOnly(),

    /**
     * Returns the route to transition to next by checking against the `consentRouteMap`.
     *
     * It's a very basic test against the consents required.
     *
     * Need to depend on gdprConsentIds because is the source for checking if a consent ID exists.
     *
     * @readonly
     * @returns {null|string}
     */
    nextConsentRoute: Ember.computed('isRequired', 'gdprConsentIds.length', function () {
      var route = null;

      if (Ember.get(this, 'isRequired')) {
        /*
        To determine which route to transition to next, loop through the consentRouteMap and run it's
        `shouldTransitionToRoute` method. If it's true, then store the route and move on. If it's false, stop
        immediately. The previously stored route will be the next route to transition to. This relies on order.
        */
        Ember.get(this, 'consentRouteMap').some(function (routeObj) {
          var shouldTransitionToRoute = routeObj.shouldTransitionToRoute();

          if (shouldTransitionToRoute) {
            route = routeObj.route;
          }

          return route;
        });
      }

      return route;
    }).readOnly(),

    /**
     * Check a url origin against a array of whitelisted URL objects. Only url's matching the whitelist are trusted
     * to allow redirect.
     *
     * @param {URL} url A URL instance
     * @returns {boolean}
     * @private
     */
    validateUrlAgainstWhitelist: function validateUrlAgainstWhitelist(url) {
      var _consentDataUrlWhitelist = Ember.get(this, '_consentDataUrlWhitelist');

      var urlObj; // When the url is invalid, createURL will throw.
      // When createURL throws, just return false.

      try {
        urlObj = this.createURL(url, _emberWindowMock.default.location.href);
      } catch (err) {
        return false;
      }

      var _urlObj = urlObj,
          origin = _urlObj.origin;
      return _consentDataUrlWhitelist.some(function (_ref3) {
        var whitelistOrigin = _ref3.origin;
        return whitelistOrigin === origin;
      });
    },

    /**
     * Transition to the next route by determined by:
     *
     * 1. Next consent route.
     * 2. A stored callback in case there is an async event that happens that can't be controlled by
     * promises (sessionAuthenticated event, for example)
     * 3. Redirect query parameter from the `application` route.
     * 4. A previously stored transition. Will retry.
     * 5. The default, transition to the `consentsAgreedRoute`.
     *
     * @returns {*}
     */
    redirectToNextRoute: function redirectToNextRoute() {
      var nextConsentRoute = Ember.get(this, 'nextConsentRoute');

      if (nextConsentRoute) {
        return this.replaceWithRoute(nextConsentRoute);
      } else {
        var previousTransitionCallback = Ember.get(this, 'previousTransitionCallback');

        if (previousTransitionCallback) {
          Ember.set(this, 'previousTransitionCallback', null);
          return previousTransitionCallback();
        } else {
          var _EmberGetProperties2 = Ember.getProperties(this, 'redirectURL', 'previousTransition'),
              redirectURL = _EmberGetProperties2.redirectURL,
              previousTransition = _EmberGetProperties2.previousTransition;

          var isValidRedirect = false;

          if (redirectURL) {
            redirectURL = decodeURIComponent(redirectURL);
            isValidRedirect = this.validateUrlAgainstWhitelist(redirectURL);
          }

          if (isValidRedirect) {
            return this.locationHref(redirectURL);
          } else if (previousTransition) {
            Ember.set(this, 'previousTransition', null);
            return previousTransition.retry();
          } else {
            return this.replaceWithRoute(Ember.get(this, 'consentsAgreedRoute'));
          }
        }
      }
    },

    /**
     * Create a URL instance.
     * This may be a function to override to catch errors in case the url string is invalid
     *
     * @param {string|URL} url
     * @param {string} base
     */
    createURL: function createURL(url, base) {
      return new _url.default(url, base);
    },

    /**
     * Redirect with location href. Does not set location.href if environment is testing
     * @param {string} url
     */
    locationHref: function locationHref(url) {
      var _EmberGet3 = Ember.get(this, '_config'),
          environment = _EmberGet3.environment;

      if (environment !== 'test') {
        _emberWindowMock.default.location.href = url;
      }
    },

    /**
     * Replace the current route path with the passed in route.
     *
     * @param {string} route Route to replace with.
     * @returns {object}
     */
    replaceWithRoute: function replaceWithRoute(route) {
      return Ember.get(this, 'router').replaceWith(route);
    },

    /**
     * Required Consent IDs used to determine whether user is transitioned to consent route or not.
     * @returns {array}
     */
    consentIdsRequired: Ember.computed(function () {
      return [_gdprConsentIds.REAFFIRM_EXISTING_EU_USER, _gdprConsentIds.SETTINGS_CHECKUP];
    }),

    /**
     * gdpr-status record should be set by the `load` method, otherwise it will throw.
     * @throws {error} Will throw if not set before accessing.
     * @returns {object|void}
     */
    gdprStatus: Ember.computed(function () {
      throw new Error('gdprStatus has not been loaded.');
    }),

    /**
     * Return a list of consent IDs that are required for GDPR. It doesn't represent what is required for the app,
     * which is defined in `consentIdsRequired`.
     * @returns {string[]}
     */
    gdprConsentIds: Ember.computed('gdprStatus.consents.length', function () {
      var gdprStatus = Ember.get(this, 'gdprStatus'); // Look up ids via hasMany because `get` will trigger request to find consent records, which we is unnecessary.

      return gdprStatus.hasMany('consents').ids();
    }),

    /**
     * Checks whether gdpr-status record has a specific consent ID.
     * @param {string} id Consent ID.
     * @returns {boolean}
     */
    hasConsentId: function hasConsentId(id) {
      return Ember.get(this, 'gdprConsentIds').includes(id);
    },

    /**
     * Determines whether consent is required
     * @returns {boolean}
     */
    isRequired: Ember.computed('gdprConsentIds.length,consentIdsRequired.length', function () {
      var _EmberGetProperties3 = Ember.getProperties(this, 'gdprConsentIds', 'consentIdsRequired'),
          consentIds = _EmberGetProperties3.gdprConsentIds,
          consentIdsRequired = _EmberGetProperties3.consentIdsRequired;

      return consentIds.some(function (id) {
        return consentIdsRequired.includes(id);
      });
    }),

    /**
     * Determine if gdpr cookie exists or is true.
     * @returns {boolean}
     */
    hasCookie: function hasCookie() {
      var _EmberGetProperties4 = Ember.getProperties(this, 'cookies', 'COOKIE_KEY'),
          cookies = _EmberGetProperties4.cookies,
          COOKIE_KEY = _EmberGetProperties4.COOKIE_KEY;

      var value = cookies.read(COOKIE_KEY);
      return Ember.isPresent(value) && value === 'true';
    },

    /**
     * Create/update the gdpr cookie
     * @param {boolean} value
     */
    writeCookie: function writeCookie(value) {
      var _EmberGetProperties5 = Ember.getProperties(this, 'cookies', 'COOKIE_KEY', 'cookieOptions'),
          cookies = _EmberGetProperties5.cookies,
          COOKIE_KEY = _EmberGetProperties5.COOKIE_KEY,
          cookieOptions = _EmberGetProperties5.cookieOptions;

      cookies.write(COOKIE_KEY, value, cookieOptions);
    },

    /**
     * Check that a route is valid for transitioning.
     * @param {string} route Route path.
     * @returns {boolean}
     */
    validateTransitionRoute: function validateTransitionRoute(route) {
      var nextConsentRoute = Ember.get(this, 'nextConsentRoute');

      var _route$split = route.split('.'),
          _route$split2 = _slicedToArray(_route$split, 1),
          parentRoute = _route$split2[0];

      return parentRoute === nextConsentRoute;
    },

    /**
     * Load gdpr-status by id
     * @param {string} id
     * @returns {*}
     */
    load: function load(id) {
      var _this2 = this;

      var store = Ember.get(this, 'store');
      var gdprOk = this.hasCookie();
      var record;

      if (gdprOk) {
        store.pushPayload('gdpr-status', {
          data: {
            id: id,
            type: 'gdpr-status',
            attributes: {}
          }
        });
        record = Ember.RSVP.resolve(store.peekRecord('gdpr-status', id));
      } else {
        record = Ember.get(this, 'store').findRecord('gdpr-status', id);
      }

      return record.then(function (gdprStatus) {
        Ember.set(_this2, 'gdprStatus', gdprStatus);
        var isRequired = Ember.get(_this2, 'isRequired'); // Write cookie only if gdpr is not required

        if (!isRequired) {
          _this2.writeCookie(true);
        }

        return gdprStatus;
      }).catch(function (e) {
        /**
         * Since failure occurred, push a gdpr-status record into the store to prevent looking up gdprStatus
         * from throwing an error.
         */
        store.pushPayload('gdpr-status', {
          data: {
            id: id,
            type: 'gdpr-status',
            attributes: {}
          }
        });
        var gdprStatus = store.peekRecord('gdpr-status', id);
        Ember.set(_this2, 'gdprStatus', gdprStatus);
        /**
         * Writing the cookie as not required because the gdpr-status record produced and error.
         * In this case, we probably don't want to prevent the user from using the page and have constant redirect.
         * The cookie also has a max age of 1 hour.
         */

        _this2.writeCookie(true);

        throw e;
      });
    },

    /**
     * Agree to a consent, save, and store cookie determining whether GDPR is still required or not.
     *
     * @param consent
     * @returns {Promise}
     */
    accept: function accept(consent) {
      var _this3 = this;

      Ember.set(consent, 'agree', true);
      return consent.save().then(function () {
        var gdprStatus = Ember.get(_this3, 'gdprStatus'); // Remove the consent from GDPRStatus once the agreement has been accepted.
        // Consent will only show up on GDPRStatus if they have not been accepted.

        Ember.get(gdprStatus, 'consents').removeObject(consent);
        var isRequired = Ember.get(_this3, 'isRequired'); // Write cookie only if gdpr no longer required.

        if (!isRequired) {
          _this3.writeCookie(true);
        }
      }).catch(function (e) {
        // Undo change to agree attribute because save failed.
        consent.rollbackAttributes();
        throw e;
      });
    }
  });

  _exports.default = _default;
});