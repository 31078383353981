define("snapdragon-common/components/cw-form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ERROR_OVERLAY_ALWAYS = _exports.ERROR_OVERLAY_AUTO = void 0;
  var ERROR_OVERLAY_AUTO = 'auto';
  _exports.ERROR_OVERLAY_AUTO = ERROR_OVERLAY_AUTO;
  var ERROR_OVERLAY_ALWAYS = 'always';
  /**
   * Basic class for all form components.
   * Each form component displays label, error (if any) and main content (like `input`, `select`, etc)
   */

  _exports.ERROR_OVERLAY_ALWAYS = ERROR_OVERLAY_ALWAYS;

  var _default = Ember.Component.extend({
    /**
     * Current value
     * This property provides two-way data binding.
     */
    value: null,

    /**
     * Label text
     */
    label: null,

    /**
     * If component is validated, error is shown regardless touched state
     */
    validated: false,

    /**
     * Specifies if error should be displayed
     */
    error: false,

    /**
     * Text of error
     */
    errorText: null,
    dataTestQa: null,

    /**
     * Optional handler of blur event
     */
    onFocus: function onFocus() {},

    /**
     * Optional handler of blur event
     */
    onBlur: function onBlur() {},

    /**
     * Optional handler of change event
     */
    onChange: function onChange() {},
    touched: false,
    focused: false,
    empty: Ember.computed.not('value'),
    shouldShowError: Ember.computed('touched', 'validated', 'error', function () {
      var touched = this.touched,
          validated = this.validated,
          error = this.error;
      return (touched || validated) && error;
    }),
    setFocused: function setFocused(focused) {
      this.set('focused', focused);
    },
    setTouched: function setTouched(touched) {
      this.set('touched', touched);
    },
    actions: {
      onFocus: function onFocus() {
        this.setFocused(true);
        this.onFocus.apply(this, arguments);
      },
      onBlur: function onBlur() {
        this.setFocused(false);
        this.setTouched(true);
        this.onBlur.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});