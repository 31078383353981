define("snapdragon-common/components/validate-input-provider", ["exports", "snapdragon-common/templates/components/validate-input-provider"], function (_exports, _validateInputProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _validateInputProvider.default,
    tagName: '',

    /**
     * The value that get's validated.
     *
     * @type {*}
     */
    value: null,

    /**
     * Validation related to the value.
     *
     * @type {Object|null}
     */
    validation: null,

    /**
     * Collection of validations. `validation` will be looked up using `validationPath`.
     *
     * @type {Object|null}
     */
    validations: null,

    /**
     * Flag to determine whether to show validations. Changed by focus out action.
     *
     * @type {Boolean}
     */
    showValidations: false,

    /**
     * Determine whether validation has ever occurred by passing in this property.
     *
     * @type {Boolean}
     */
    didValidate: false,

    /**
     * Determine whether validation is in progress.
     *
     * @returns {Boolean}
     */
    notValidating: Ember.computed.not('validation.isValidating').readOnly(),

    /**
     * Determine whether the value is empty or not.
     *
     * @returns {Boolean}
     */
    hasContent: Ember.computed.notEmpty('value').readOnly(),

    /**
     * Determine whether validation has any warnings.
     *
     * @returns {Boolean}
     */
    hasWarnings: Ember.computed.notEmpty('validation.warnings').readOnly(),

    /**
     * Validation is valid when `value` is not empty, and `validation.isTruelyValid` is true.
     *
     * @returns {Boolean}
     */
    isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly(),

    /**
     * Determine whether validations should be shown.
     *
     * Flag will be true if `showValidations`, `didValidate` or `hasContent` are true.
     *
     * @returns {Boolean}
     */
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),

    /**
     * Determine whether to add the error class when validation is no longer in progress, there are error messages,
     * value is not empty, and there is a validation.
     *
     * @returns {Boolean|Object|null}
     */
    showErrorClass: Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly(),

    /**
     * Determine whether to show error messages when input has focused out, didValidate is true, or value is not empty,
     * and validation is invalid.
     *
     * @returns {Boolean}
     */
    showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),

    /**
     * Determine whether to show warning messages when input has focused out, didValidate is true, or value is not empty,
     * and validation has warnings, and validation is valid.
     *
     * @returns {Boolean}
     */
    showWarningMessage: Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    /**
     * Initialize class by setting a validation computed property by looking up the validation with the `validationPath`.
     *
     * This simplifies usage of the component provider so that users don't have to manually look up the validation
     * themselves.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var validationPath = Ember.get(this, 'validationPath');
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly("validations.attrs.".concat(validationPath)));
    },
    actions: {
      /**
       * Display validations when focus leaves an element.
       *
       * This is useful for dislaying validation error messages when user hasn't submitted the form yet.
       */
      focusOut: function focusOut() {
        Ember.set(this, 'showValidations', true);
      }
    }
  });

  _exports.default = _default;
});