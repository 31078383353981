define("snapdragon-common/helpers/dasherize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dasherize = dasherize;
  _exports.default = void 0;

  function dasherize(params) {
    var word = params[0];
    return word && Ember.String.dasherize(word);
  }

  var _default = Ember.Helper.helper(dasherize);

  _exports.default = _default;
});