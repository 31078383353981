define("snapdragon-common/constants/program-visibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ARCHIVED = _exports.HIDDEN = _exports.ENTERPRISE = _exports.PRIVATE = _exports.VISIBLE = _exports.ALL_VISIBILITY = void 0;
  var ALL_VISIBILITY = 'ALL';
  _exports.ALL_VISIBILITY = ALL_VISIBILITY;
  var VISIBLE = 'VISIBLE';
  _exports.VISIBLE = VISIBLE;
  var PRIVATE = 'PRIVATE';
  _exports.PRIVATE = PRIVATE;
  var ENTERPRISE = 'ENTERPRISE';
  _exports.ENTERPRISE = ENTERPRISE;
  var HIDDEN = 'HIDDEN';
  _exports.HIDDEN = HIDDEN;
  var ARCHIVED = 'ARCHIVED';
  _exports.ARCHIVED = ARCHIVED;
});