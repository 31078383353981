define("snapdragon-common/components/fit-spinner", ["exports", "snapdragon-common/templates/components/fit-spinner"], function (_exports, _fitSpinner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  Usage: {{fit-spinner}} where optionally class='medium-spinner'
  can be added to make the spinner a larger, medium size.
  */
  var _default = Ember.Component.extend({
    layout: _fitSpinner.default,
    classNames: ['fit-spinner-position']
  });

  _exports.default = _default;
});