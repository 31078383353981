define("sleep-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // eslint-disable-next-line ember/routes-segments-snake-case
    this.route('details', {
      path: ':sleepDate/:sleepId'
    });
    this.mount('sleep-journal-engine', {
      as: '/'
    });
  });

  _exports.default = _default;
});