define("ember-attacher/templates/components/attach-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6mF5Slvv",
    "block": "{\"symbols\":[\"emberPopper\",\"&default\"],\"statements\":[[1,[27,\"unbound\",[[23,[\"_parentFinder\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"_currentTarget\"]],[27,\"or\",[[27,\"not\",[[23,[\"lazyRender\"]]],null],[23,[\"_mustRender\"]]],null]],null]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[23,[\"ariaRole\"]],\"ember-attacher\",false,[23,[\"id\"]],[23,[\"_modifiers\"]],[23,[\"placement\"]],[23,[\"popperContainer\"]],[23,[\"_currentTarget\"]],[27,\"action\",[[22,0,[]],\"registerAPI\"],null],[23,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"_class\"]]]],[12,\"style\",[21,\"_style\"]],[9],[0,\"\\n      \"],[14,2,[[27,\"hash\",null,[[\"emberPopper\",\"hide\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"hide\"],null]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"arrow\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"x-arrow\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"animation\"]],\"fill\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"x-circle\",\"\"],[12,\"style\",[21,\"_circleTransitionDuration\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-attacher/templates/components/attach-popover.hbs"
    }
  });

  _exports.default = _default;
});