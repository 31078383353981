define("snapdragon-common/components/cw-confirm-modal", ["exports", "snapdragon-common/components/fit-confirm-modal", "snapdragon-common/templates/components/cw-confirm-modal"], function (_exports, _fitConfirmModal, _cwConfirmModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fitConfirmModal.default.extend({
    layout: _cwConfirmModal.default,
    classNames: ['cw-confirm-modal']
  });

  _exports.default = _default;
});