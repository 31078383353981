define("ember-fitbit-pwd-auth/services/browser-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LOCAL_STORAGE_TEST_KEY = '_test_key';
  var isLocalStorageAvailable = true;

  try {
    localStorage.setItem(LOCAL_STORAGE_TEST_KEY, true);
    localStorage.removeItem(LOCAL_STORAGE_TEST_KEY);
    isLocalStorageAvailable = !(navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1);
  } catch (e) {
    isLocalStorageAvailable = false;
  }

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    getItem: function getItem(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (isLocalStorageAvailable) {
        return localStorage.getItem(key);
      } else {
        return Ember.get(this, 'cookies').read(key, options);
      }
    },
    setItem: function setItem(key, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (isLocalStorageAvailable) {
        return localStorage.setItem(key, value);
      } else {
        return Ember.get(this, 'cookies').write(key, value, options);
      }
    },
    removeItem: function removeItem(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (isLocalStorageAvailable) {
        return localStorage.removeItem(key);
      } else {
        return Ember.get(this, 'cookies').clear(key, options);
      }
    }
  });

  _exports.default = _default;
});