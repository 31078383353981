define("snapdragon-common/components/fit-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Attributes
    attributeBindings: ['data-test-qa'],
    classNameBindings: ['hasError:error'],
    classNames: ['fit-input'],
    'data-test-qa': '',
    // -------------------------------------------------------------------------
    // Actions
    // -------------------------------------------------------------------------
    // Events
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Flag to add error class to component. Useful for validation to add an error class.
     * @type {Boolean}
     */
    hasError: false // -------------------------------------------------------------------------
    // Observers
    // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});