define("snapdragon-common/components/cw-select-box", ["exports", "snapdragon-common/templates/components/cw-select-box"], function (_exports, _cwSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copmponent for select on corporate onboarding page
   */
  var _default = Ember.Component.extend({
    layout: _cwSelectBox.default,
    classNames: ['select-box'],
    classNameBindings: ['className', 'corporate', 'isMobile'],
    options: null,
    hasEmpty: false,
    emptyLabel: '',
    isMobile: false,
    hasError: false,
    disabled: false,
    dataTestQa: null,
    translationNamespace: null,
    onChange: function onChange() {},
    onFocus: function onFocus() {},
    onBlur: function onBlur() {}
  });

  _exports.default = _default;
});