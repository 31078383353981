define("snapdragon-common/constants/performance", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.performanceClearMarks = _exports.performanceGetEntriesByType = _exports.performanceMark = _exports.HAS_PERFORMANCE_CLEAR_MARKS = _exports.HAS_PERFORMANCE_GET_ENTRIES_BY_TYPE = _exports.HAS_PERFORMANCE_MARK = void 0;
  var HAS_PERFORMANCE_MARK = _emberWindowMock.default.performance && _emberWindowMock.default.performance.mark;
  _exports.HAS_PERFORMANCE_MARK = HAS_PERFORMANCE_MARK;
  var HAS_PERFORMANCE_GET_ENTRIES_BY_TYPE = _emberWindowMock.default.performance && _emberWindowMock.default.performance.getEntriesByType;
  _exports.HAS_PERFORMANCE_GET_ENTRIES_BY_TYPE = HAS_PERFORMANCE_GET_ENTRIES_BY_TYPE;
  var HAS_PERFORMANCE_CLEAR_MARKS = _emberWindowMock.default.performance && _emberWindowMock.default.performance.clearMarks;
  _exports.HAS_PERFORMANCE_CLEAR_MARKS = HAS_PERFORMANCE_CLEAR_MARKS;

  var performanceMark = function performanceMark(markName) {
    if (HAS_PERFORMANCE_MARK) {
      _emberWindowMock.default.performance.mark(markName);
    }
  };

  _exports.performanceMark = performanceMark;

  var performanceGetEntriesByType = function performanceGetEntriesByType(type) {
    return HAS_PERFORMANCE_GET_ENTRIES_BY_TYPE ? _emberWindowMock.default.performance.getEntriesByType(type) : [];
  };

  _exports.performanceGetEntriesByType = performanceGetEntriesByType;

  var performanceClearMarks = function performanceClearMarks() {
    if (HAS_PERFORMANCE_CLEAR_MARKS) {
      _emberWindowMock.default.performance.clearMarks();
    }
  };

  _exports.performanceClearMarks = performanceClearMarks;
});