define("snapdragon-common/helpers/i18n-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.i18nUrl = i18nUrl;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var SUPPORTED_CODES = ['en_US', 'zh_TW', 'zh_CN', 'ko', 'ja', 'fr', 'it', 'es', 'de'];

  function i18nUrl(url, userLanguageLocale) {
    // For Fitbit Help URLs, the locale in the url is usually the language code. For en_US, zh_TW,
    // and zh_CN, the locale in the url uses the full language code and country code.
    if (userLanguageLocale !== 'en_US' && userLanguageLocale !== 'zh_TW' && userLanguageLocale !== 'zh_CN') {
      userLanguageLocale = userLanguageLocale.split('_')[0];
    }

    if (!SUPPORTED_CODES.includes(userLanguageLocale)) {
      userLanguageLocale = 'en_US';
    }

    return url.replace('{{locale}}', userLanguageLocale);
  }
  /*
   * Used to format simple (probably Fitbit help) urls to account for the users setting
   * Example url input: https://help.fitbit.com/articles/{{locale}}/Help_article/1877
   * This would then output https://help.fitbit.com/articles/en_US/Help_article/1877 for a US user
   */


  var _default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          url = _ref2[0];

      var formatter = this.get('i18n'),
          userLanguageLocale = formatter.get('userLanguageLocale');
      return i18nUrl(url, userLanguageLocale);
    }
  });

  _exports.default = _default;
});