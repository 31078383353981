define("snapdragon-common/services/feature-metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * This service simplifies sending of feature-scoped FSC events.
   *
   * Typically, each ember engine sends events for specific feature.
   * Using this service developer can scope metric events (tracked by the engine) to this feature.
   *
   * Before start to use this service, it is necessary to provide default FSC configuration
   * in `config/environment.js` file. For example,
   * ```
   * fsc: {
   *     feature: 'FHS Onboarding',
   *     owner: 'corporate-wellness',
   * }
   * ```
   *
   * Upon that, all events sent by 'feature-metrics' service will be sent to this feature.
   * To provide the same experience as `ember-metrics/metrics` service,
   * `feature-metrics` service implements the same API.
   *
   * ```
   * featureMetrics.trackPage(...);
   * featureMetrics.trackEvent(...);
   * ```
   */
  var _default = Ember.Service.extend({
    fscOptions: null,
    metrics: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments); // Take FSC configuration from the current engine


      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      this.fscOptions = config.fsc;
    },
    trackPage: function trackPage() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._invoke.apply(this, ['trackPage'].concat(args));
    },
    trackEvent: function trackEvent() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      this._invoke.apply(this, ['trackEvent'].concat(args));
    },
    _invoke: function _invoke(methodName) {
      if ((arguments.length <= 1 ? 0 : arguments.length - 1) > 1) {
        return this.metrics[methodName](arguments.length <= 1 ? undefined : arguments[1], this._decorateEvent(arguments.length <= 2 ? undefined : arguments[2]));
      } else {
        return this.metrics[methodName](this._decorateEvent(arguments.length <= 1 ? undefined : arguments[1]));
      }
    },

    /**
     * Merge FSC options into event (but only if event does not provide them already).
     */
    _decorateEvent: function _decorateEvent(event) {
      var fscOptions = _objectSpread({}, this.fscOptions, {}, event.fscOptions);

      if (!(fscOptions.owner && fscOptions.feature)) {
        // eslint-disable-next-line no-console
        console.error('Both feature and owner should be defined for event', event);
      }

      return _objectSpread({}, event, {
        fscOptions: fscOptions
      });
    }
  });

  _exports.default = _default;
});