define("snapdragon-common/constants/program-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GAME = _exports.SHADOW = _exports.PROGRAM = _exports.ALL_TYPES = void 0;
  var ALL_TYPES = 'ALL';
  _exports.ALL_TYPES = ALL_TYPES;
  var PROGRAM = 'PROGRAM';
  _exports.PROGRAM = PROGRAM;
  var SHADOW = 'SHADOW';
  _exports.SHADOW = SHADOW;
  var GAME = 'GAME';
  _exports.GAME = GAME;
});