define("snapdragon-common/constants/onboarding-user-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CW_FRIEND_FINDER = _exports.CW_COACHING_PREMIUM = _exports.CW_PREMIUM = void 0;

  /**
   * Constants for onboarding user features names
   */
  var CW_PREMIUM = 'CW_PREMIUM';
  _exports.CW_PREMIUM = CW_PREMIUM;
  var CW_COACHING_PREMIUM = 'CW_COACHING_PREMIUM';
  _exports.CW_COACHING_PREMIUM = CW_COACHING_PREMIUM;
  var CW_FRIEND_FINDER = 'CW_FRIEND_FINDER';
  _exports.CW_FRIEND_FINDER = CW_FRIEND_FINDER;
});