define("ember-fitbit-webview-comms/services/webview-comms", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var createWrapperMethod = function createWrapperMethod(methodName) {
    return function () {
      var _this$__webview_comms;

      return (_this$__webview_comms = this.__webview_comms__)[methodName].apply(_this$__webview_comms, arguments);
    };
  };

  var _default = Ember.Service.extend({
    __webview_comms__: Ember.computed(function () {
      return Ember.getOwner(this).lookup('webview-comms:lib');
    }),

    /**
     * Check if webview-comms is supported.
     *
     * Need to check for either `window.WebViewJsInterface || window.isWebviewCommsSupported` because Android and iOS are inconsistent.
     * Note that Android does add `window.isWebviewCommsSupported` but it is not always available.
     *
     * @returns {Boolean}
     */
    isSupported: Ember.computed(function () {
      return !!(_emberWindowMock.default.WebViewJsInterface || _emberWindowMock.default.isWebviewCommsSupported);
    }),
    getWithComms: createWrapperMethod('get'),
    launchWithComms: createWrapperMethod('launch'),
    navigateWithComms: createWrapperMethod('navigate'),
    requestWithComms: createWrapperMethod('request'),
    triggerWithComms: createWrapperMethod('trigger'),
    configureWithComms: createWrapperMethod('configure'),
    onNotify: createWrapperMethod('onNotify'),
    offNotify: createWrapperMethod('offNotify')
  });

  _exports.default = _default;
});