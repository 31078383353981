define("snapdragon-common/components/fit-modal-header", ["exports", "snapdragon-common/templates/components/fit-modal-header"], function (_exports, _fitModalHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _fitModalHeader.default
  });

  _exports.default = _default;
});