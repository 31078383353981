define("snapdragon-common/components/journal-survey-question-single-choice", ["exports", "snapdragon-common/templates/components/journal-survey-question"], function (_exports, _journalSurveyQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _journalSurveyQuestion.default,
    classNames: ['journal-survey-question', 'journal-survey-question-single-choice'],
    actions: {
      setResponse: function setResponse(question, response) {
        question.setResponse(response);
      }
    }
  });

  _exports.default = _default;
});