define("snapdragon-common/constants/badge-types", ["exports", "snapdragon-common/constants/badge-groups"], function (_exports, BADGE_GROUPS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OTHER = _exports.CHALLENGE = _exports.FIT_FOR_GOOD = _exports.CUSTOM = _exports.GOAL_BASED_WEIGHT_GAIN_ACHIEVEMENT = _exports.GOAL_BASED_WEIGHT_LOSS_ACHIEVEMENT = _exports.GOAL_BASED_WEIGHT_LOSS = _exports.GOAL_BASED_WEIGHT_GAIN = _exports.LIFETIME_WEIGHT_GOAL_SETUP = _exports.LIFETIME_LOSS = _exports.LIFETIME_GAIN = _exports.LIFETIME_FLOORS = _exports.LIFETIME_DISTANCE = _exports.DAILY_FLOORS = _exports.DAILY_STEPS = void 0;
  var DAILY_GROUP = BADGE_GROUPS.DAILY,
      LIFETIME_DISTANCE_GROUP = BADGE_GROUPS.LIFETIME_DISTANCE,
      LIFETIME_FLOORS_GROUP = BADGE_GROUPS.LIFETIME_FLOORS,
      GOAL_BASED_WEIGHT_GROUP = BADGE_GROUPS.GOAL_BASED_WEIGHT,
      FIT_FOR_GOOD_GROUP = BADGE_GROUPS.FIT_FOR_GOOD,
      CHALLENGE_GROUP = BADGE_GROUPS.CHALLENGE,
      OTHER_GROUP = BADGE_GROUPS.OTHER;
  var DAILY_STEPS = {
    type: 'DAILY_STEPS',
    group: DAILY_GROUP
  };
  _exports.DAILY_STEPS = DAILY_STEPS;
  var DAILY_FLOORS = {
    type: 'DAILY_FLOORS',
    group: DAILY_GROUP
  };
  _exports.DAILY_FLOORS = DAILY_FLOORS;
  var LIFETIME_DISTANCE = {
    type: 'LIFETIME_DISTANCE',
    group: LIFETIME_DISTANCE_GROUP
  };
  _exports.LIFETIME_DISTANCE = LIFETIME_DISTANCE;
  var LIFETIME_FLOORS = {
    type: 'LIFETIME_FLOORS',
    group: LIFETIME_FLOORS_GROUP
  };
  _exports.LIFETIME_FLOORS = LIFETIME_FLOORS;
  var LIFETIME_GAIN = {
    type: 'LIFETIME_GAIN',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.LIFETIME_GAIN = LIFETIME_GAIN;
  var LIFETIME_LOSS = {
    type: 'LIFETIME_LOSS',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.LIFETIME_LOSS = LIFETIME_LOSS;
  var LIFETIME_WEIGHT_GOAL_SETUP = {
    type: 'LIFETIME_WEIGHT_GOAL_SETUP',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.LIFETIME_WEIGHT_GOAL_SETUP = LIFETIME_WEIGHT_GOAL_SETUP;
  var GOAL_BASED_WEIGHT_GAIN = {
    type: 'GOAL_BASED_WEIGHT_GAIN',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.GOAL_BASED_WEIGHT_GAIN = GOAL_BASED_WEIGHT_GAIN;
  var GOAL_BASED_WEIGHT_LOSS = {
    type: 'GOAL_BASED_WEIGHT_LOSS',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.GOAL_BASED_WEIGHT_LOSS = GOAL_BASED_WEIGHT_LOSS;
  var GOAL_BASED_WEIGHT_LOSS_ACHIEVEMENT = {
    type: 'GOAL_BASED_WEIGHT_LOSS_ACHIEVEMENT',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.GOAL_BASED_WEIGHT_LOSS_ACHIEVEMENT = GOAL_BASED_WEIGHT_LOSS_ACHIEVEMENT;
  var GOAL_BASED_WEIGHT_GAIN_ACHIEVEMENT = {
    type: 'GOAL_BASED_WEIGHT_GAIN_ACHIEVEMENT',
    group: GOAL_BASED_WEIGHT_GROUP
  };
  _exports.GOAL_BASED_WEIGHT_GAIN_ACHIEVEMENT = GOAL_BASED_WEIGHT_GAIN_ACHIEVEMENT;
  var CUSTOM = {
    type: 'CUSTOM'
  };
  _exports.CUSTOM = CUSTOM;
  var FIT_FOR_GOOD = {
    type: 'CUSTOM',
    category: 'FIT_FOR_GOOD',
    group: FIT_FOR_GOOD_GROUP
  };
  _exports.FIT_FOR_GOOD = FIT_FOR_GOOD;
  var CHALLENGE = {
    type: 'CUSTOM',
    category: 'CHALLENGE',
    group: CHALLENGE_GROUP
  };
  /**
   * Catch all for unknown types.
   * @type {string}
   */

  _exports.CHALLENGE = CHALLENGE;
  var OTHER = {
    type: 'OTHER',
    group: OTHER_GROUP
  };
  _exports.OTHER = OTHER;
});