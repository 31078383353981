define("ember-aupac-typeahead/templates/components/aupac-typeahead/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KEFy6V6g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"typeahead-footer\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aupac-typeahead/templates/components/aupac-typeahead/footer.hbs"
    }
  });

  _exports.default = _default;
});