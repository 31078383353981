define("snapdragon-common/components/fit-tags-input", ["exports", "ember-tags-input/components/ember-tags-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    KEYCODES: Ember.computed(function () {
      return {
        BACKSPACE: 8,
        TAB: 9
      };
    }),

    /**
     * Extending onNewTagInputKeyDown to capture the tab input.  The original
     * library does not capture tab for completion.
     * @param event
     * @returns {*|void}
     */
    onNewTagInputKeyDown: function onNewTagInputKeyDown(event) {
      var _this = this;

      var which = event.which,
          value = event.target.value;

      if (which === Ember.get(this, 'KEYCODES.TAB') && value) {
        event.preventDefault();
        return this.onNewTagInputEnter(value);
      }

      if (which === Ember.get(this, 'KEYCODES.BACKSPACE')) {
        value = value && value.trim();

        if (!value && this.get('tags.length')) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this.onRemoveTagAtIndex(_this.get('tags.length') - 1);
          });
        }
      }
    }
  });

  _exports.default = _default;
});