define("snapdragon-common/constants/prototype-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_SURVEY_QUESTION = _exports.RESPONSE_VERY = _exports.RESPONSE_HELPFUL = _exports.RESPONSE_FAIRLY = _exports.RESPONSE_SLIGHTLY = _exports.RESPONSE_NOT = void 0;

  /**
   * Constants for prototype feedback survey responses
   */
  var RESPONSE_NOT = 'NOT';
  _exports.RESPONSE_NOT = RESPONSE_NOT;
  var RESPONSE_SLIGHTLY = 'SLIGHTLY';
  _exports.RESPONSE_SLIGHTLY = RESPONSE_SLIGHTLY;
  var RESPONSE_FAIRLY = 'FAIRLY';
  _exports.RESPONSE_FAIRLY = RESPONSE_FAIRLY;
  var RESPONSE_HELPFUL = 'HELPFUL';
  _exports.RESPONSE_HELPFUL = RESPONSE_HELPFUL;
  var RESPONSE_VERY = 'VERY';
  /**
   *
   * @type {SurveyQuestions}
   */

  _exports.RESPONSE_VERY = RESPONSE_VERY;
  var DEFAULT_SURVEY_QUESTION = {
    question: 'How helpful was this report',
    type: 'single',
    responses: [{
      text: 'Not helpful',
      value: RESPONSE_NOT
    }, {
      text: 'Slightly helpful',
      value: RESPONSE_SLIGHTLY
    }, {
      text: 'Fairly helpful',
      value: RESPONSE_FAIRLY
    }, {
      text: 'Helpful',
      value: RESPONSE_HELPFUL
    }, {
      text: 'Very helpful',
      value: RESPONSE_VERY
    }]
  };
  _exports.DEFAULT_SURVEY_QUESTION = DEFAULT_SURVEY_QUESTION;
});