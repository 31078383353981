define("consent-data/serializers/consent-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO need to move the application serializers to a separate addon so the engine can be more easily shared.
  var _default = _emberData.default.JSONAPISerializer.extend({});

  _exports.default = _default;
});