define("consent-data/serializers/v2-consent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SERVICE_ID = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  /**
   * Default service ID sent to the API to log consent
   */

  var SERVICE_ID = 'snapdragon';
  _exports.SERVICE_ID = SERVICE_ID;

  var _default = _emberData.default.RESTSerializer.extend(EmbeddedRecordsMixin, {
    attrs: {
      timestamp: {
        serialize: false
      },
      // Only accepted for delegation consent
      consenterId: {
        serialize: false
      },
      userId: {
        serialize: false
      }
    },

    /**
     * Overrides method to return `user-consent` as the model name.
     * @override
     * @param {String} payloadKey
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'consents') {
        return 'v2-consent';
      } else {
        return this._super.apply(this, arguments);
      }
    },

    /**
     * Overrides method to return `consents` as the payload key.
     * @override
     * @param {String} modelName
     */
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      if (modelName === 'v2-consent') {
        return 'consents';
      } else {
        return this._super.apply(this, arguments);
      }
    },

    /**
     * Extract an ID that is composed of the data's categoryId and consentId because the data
     * doesn't actually have an Id.
     * @param {Object} modelClass
     * @param {Object} payload Payload data
     * @param {String} payload.categoryId
     * @param {String} payload.consentId
     */
    extractId: function extractId(modelClass, _ref) {
      var categoryId = _ref.categoryId,
          consentId = _ref.consentId;

      if (categoryId && consentId) {
        return "".concat(categoryId, "-").concat(consentId);
      } else {
        return this._super.apply(this, arguments);
      }
    },

    /**
     * Override top lookup a single record from `payload.consents` array, which is always an array.
     * @param {Object} store
     * @param {Object} primaryModelClass
     * @param {Object} payload
     * @param {String} id
     * @param {String} requestType
     */
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass) {
      var _payload$consents;

      var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        consents: []
      };
      var id = arguments.length > 3 ? arguments[3] : undefined;
      var requestType = arguments.length > 4 ? arguments[4] : undefined;
      return this._super(store, primaryModelClass, {
        consents: (payload === null || payload === void 0 ? void 0 : (_payload$consents = payload.consents) === null || _payload$consents === void 0 ? void 0 : _payload$consents[0]) || {}
      }, id, requestType);
    },

    /**
     * Override to form an object expected by the API.
     */
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var _arr = Object.entries(json);

      for (var _i = 0; _i < _arr.length; _i++) {
        var _arr$_i = _slicedToArray(_arr[_i], 2),
            key = _arr$_i[0],
            value = _arr$_i[1];

        if (value === null || value === undefined) {
          delete json[key];
        }
      }

      if (json.context && Object.keys(json.context).length === 0) {
        delete json.context;
      }

      return json;
    },

    /**
     * Override to include a `serviceId` as a root level property required by the API.
     * @param {Object} hash
     * @param {Object} typeClass
     * @param {Object} snapshot
     */
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot) {
      var _snapshot$adapterOpti;

      this._super.apply(this, arguments);

      var serviceId = (snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.serviceId) || SERVICE_ID;
      var consents = hash.consents;
      hash.consents = [consents]; // serviceId at the root level of the hash is required

      hash.serviceId = serviceId;
    }
  });

  _exports.default = _default;
});