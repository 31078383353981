define("consent-data/models/consent", ["exports", "consent-data/utils/string", "ember-data", "ember-data/attr"], function (_exports, _string, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    /**
     * Provides the real consent ID. The model's id is a composite of <consent ID>::<category>
     * @returns {string|null}
     */
    consentId: Ember.computed('id', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'id'),
          id = _EmberGetProperties.id;

      var _split = (0, _string.split)(id),
          _split2 = _slicedToArray(_split, 1),
          consentId = _split2[0];

      if (consentId) {
        return consentId;
      } else {
        return null;
      }
    }),

    /**
     * Belongs to consent-category relationship.
     * @returns {object}
     */
    category: belongsTo('consent-category'),

    /**
     * Contains whether user has given consented or not.
     * @returns {boolean}
     */
    agree: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});