define("ember-fitbit-webview-auth/instance-initializers/load-webview-auth-token", ["exports", "ember-get-config", "snapdragon-common/constants/performance"], function (_exports, _emberGetConfig, _performance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Perform the task to load the webview auth token.
   *
   * Run during instance init so that the token is available sooner.
   * Alternative approach is to load the token when the session is being restored.
   *
   * @param appInstance
   */
  function initialize(appInstance) {
    var webviewAuth = appInstance.lookup('service:webview-auth');
    /**
     * Perform the task if webview-auth is supported.
     */

    if (webviewAuth.isSupported) {
      (0, _performance.performanceMark)('loadTokenTaskInitTime');
      webviewAuth.performLoadTokenTask(_emberGetConfig.default.fitbit.clientId);
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});