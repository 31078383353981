define("snapdragon-common/components/fit-modal", ["exports", "snapdragon-common/components/zf-reveal", "snapdragon-common/templates/components/fit-modal"], function (_exports, _zfReveal, _fitModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _zfReveal.default.extend({
    layout: _fitModal.default,
    classNames: ['fit-modal'],
    flashMessages: Ember.inject.service(),
    modalFlashMessagesQueue: Ember.computed('flashMessages.{queue,queue.[]}', function () {
      var queue = Ember.get(this, 'flashMessages.queue');

      if (Ember.isPresent(queue)) {
        return queue.filter(function (flashMessage) {
          return !!flashMessage.isModal;
        });
      }
    })
  });

  _exports.default = _default;
});