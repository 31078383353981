define("snapdragon-common/services/ajax-care", ["exports", "ember-ajax/services/ajax", "ember-get-config"], function (_exports, _ajax, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: Ember.computed(function () {
      var corporateUrl = _emberGetConfig.default.fitbit.corporateUrl;
      return corporateUrl || 'https://corporate.fitbit.com';
    }),
    namespace: '/v2/rest'
  });

  _exports.default = _default;
});