define("snapdragon-common/templates/components/cw-form-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NONijA3Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"cw-form-field-group\",null,[[\"label\",\"focused\",\"empty\",\"showError\",\"errorText\",\"dataTestQa\"],[[23,[\"label\"]],[23,[\"focused\"]],[23,[\"empty\"]],[23,[\"shouldShowError\"]],[23,[\"errorText\"]],[23,[\"dataTestQa\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"input\",[[27,\"-input-type\",[[23,[\"type\"]]],null]],[[\"class\",\"value\",\"data-test-qa\",\"type\",\"disabled\",\"readonly\",\"autocomplete\",\"autocorrect\",\"spellcheck\",\"change\",\"focus-in\",\"focus-out\"],[\"corporate\",[23,[\"value\"]],[27,\"if\",[[23,[\"dataTestQa\"]],[27,\"concat\",[\"field-\",[23,[\"dataTestQa\"]]],null]],null],[23,[\"type\"]],[23,[\"disabled\"]],[23,[\"readOnly\"]],\"off\",\"off\",\"false\",[27,\"action\",[[22,0,[]],\"changeValue\"],[[\"value\"],[\"target.value\"]]],[27,\"action\",[[22,0,[]],\"onFocus\"],null],[27,\"action\",[[22,0,[]],\"onBlur\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-form-text-field.hbs"
    }
  });

  _exports.default = _default;
});