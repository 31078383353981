define("snapdragon-common/errors/client-error", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Client Error can be used to blanket catch any 400 error in the case that it should be specifically handled.
   * Example usage: /app/adapters/activity, where on any 400 error, we want to redirect to the 404 page.
   * This error type is just used to ensure distinction in error handling from a 400 and 500 error.
   */
  var _default = _emberData.default.AdapterError.extend({
    message: 'Client Error'
  });

  _exports.default = _default;
});