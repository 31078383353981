define('ember-dompurify/hook', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Hook = function () {
    function Hook(_purify) {
      _classCallCheck(this, Hook);

      this._purify = _purify;
      this._setup();
    }

    /** @private **/


    _createClass(Hook, [{
      key: '_setup',
      value: function _setup() {
        this._purify.addHook('beforeSanitizeElements', this.beforeSanitizeElements.bind(this));
        this._purify.addHook('uponSanitizeElement', this.uponSanitizeElement.bind(this));
        this._purify.addHook('afterSanitizeElements', this.afterSanitizeElements.bind(this));
        this._purify.addHook('beforeSanitizeAttributes', this.beforeSanitizeAttributes.bind(this));
        this._purify.addHook('uponSanitizeAttribute', this.uponSanitizeAttribute.bind(this));
        this._purify.addHook('afterSanitizeAttributes', this.afterSanitizeAttributes.bind(this));
        this._purify.addHook('beforeSanitizeShadowDOM', this.beforeSanitizeShadowDOM.bind(this));
        this._purify.addHook('uponSanitizeShadowNode', this.uponSanitizeShadowNode.bind(this));
        this._purify.addHook('afterSanitizeShadowDOM', this.afterSanitizeShadowDOM.bind(this));
      }
    }, {
      key: 'beforeSanitizeElements',
      value: function beforeSanitizeElements() {}
    }, {
      key: 'uponSanitizeElement',
      value: function uponSanitizeElement() {}
    }, {
      key: 'afterSanitizeElements',
      value: function afterSanitizeElements() {}
    }, {
      key: 'beforeSanitizeAttributes',
      value: function beforeSanitizeAttributes() {}
    }, {
      key: 'uponSanitizeAttribute',
      value: function uponSanitizeAttribute() {}
    }, {
      key: 'afterSanitizeAttributes',
      value: function afterSanitizeAttributes() {}
    }, {
      key: 'beforeSanitizeShadowDOM',
      value: function beforeSanitizeShadowDOM() {}
    }, {
      key: 'uponSanitizeShadowNode',
      value: function uponSanitizeShadowNode() {}
    }, {
      key: 'afterSanitizeShadowDOM',
      value: function afterSanitizeShadowDOM() {}
    }]);

    return Hook;
  }();

  exports.default = Hook;
});