define("snapdragon-common/components/fit-aupac-typeahead", ["exports", "snapdragon-common/templates/components/fit-aupac-typeahead", "ember-aupac-typeahead/components/aupac-typeahead"], function (_exports, _fitAupacTypeahead, _aupacTypeahead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aupacTypeahead.default.extend({
    layout: _fitAupacTypeahead.default,

    /**
     * @public
     * @param selection the item selected by the user
     */
    setValue: function setValue(selection) {
      if (Ember.get(this, '_typeahead')) {
        // Was failing in tests with this probably due to a stray observer
        selection = this.get('transformSelection')(selection);

        if (selection) {
          Ember.get(this, '_typeahead').typeahead('val', selection);
        } else if (selection === null) {
          Ember.get(this, '_typeahead').typeahead('val', '');
        }
      }
    }
  });

  _exports.default = _default;
});