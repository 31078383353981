define("snapdragon-common/utils/flash-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flashErrors;

  function flashErrors(flashMessages, e) {
    if (!flashMessages) {
      return false;
    }

    if (e && e.errors) {
      e.errors.forEach(function (error) {
        if (error.detail) {
          flashMessages.alert("".concat(error.detail));
        } else if (error.title) {
          flashMessages.alert("".concat(error.title));
        } else if (error.message) {
          flashMessages.alert("".concat(error.message));
        } else {
          flashMessages.alert("".concat(error));
        }
      });
    } else {
      flashMessages.alert("".concat(e));
    }

    return true;
  }
});