define("@bagaar/ember-breadcrumbs/templates/components/bgr-breadcrumbs-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITjgNn8+",
    "block": "{\"symbols\":[\"instance\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"bgrBreadcrumbs\",\"instances\"]]],null,{\"statements\":[[4,\"in-element\",[[22,1,[\"element\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"class\",[28,[[22,1,[\"itemClassName\"]]]]],[9],[0,\"\\n      \"],[14,2,[[22,1,[\"linkClassName\"]]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@bagaar/ember-breadcrumbs/templates/components/bgr-breadcrumbs-item.hbs"
    }
  });

  _exports.default = _default;
});