define("snapdragon-common/components/fit-date-range-picker", ["exports", "moment", "ember-cp-validations", "snapdragon-common/mixins/escape-key-handler", "snapdragon-common/templates/components/fit-date-range-picker"], function (_exports, _moment, _emberCpValidations, _escapeKeyHandler, _fitDateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Create a `presence` validator to validate date is present.
   *
   * Start and End both need this, so making a function to reduce copy/paste.
   *
   * @returns {object}
   */
  function createDatePresenceValidator() {
    return (0, _emberCpValidations.validator)('presence', {
      presence: true
    });
  }
  /**
   * A moment instance.
   * @typedef {object} moment
   */

  /**
   * @typedef {object} LocaleConfig
   * @property {number} firstDayOfWeek - The first day of the week to display.
   * @property {string} rangeSeparator - The range separator. e.g. ` to ` or ` - `.
   * @property {function} ordinal - A function to convert a number to ordinal string.
   * @property {object} weekdays
   * @property {array} weekdays.longhand - Array of weekdays in longhand.
   * @property {array} weekdays.shorthand - Array of weekdays in shorthand.
   * @property {object} months
   * @property {array} months.longhand - Array of months in longhand.
   * @property {array} months.shorthand - Array of months in shorthand.
   */

  /**
   * @typedef {object} DateRange
   * @property {moment} momentDate - The moment instance of a date.
   * @property {date} date - The date from momentDate.
   * @property {string} formatted - The formatted string from momentDate in the format 'YYYY-MM-DD'
   */


  var Validations = (0, _emberCpValidations.buildValidations)({
    'start.formatted': {
      dependentKeys: ['model.end.formatted'],
      validators: [// Field cannot be empty.
      createDatePresenceValidator(), // Alias validations so that the valid state and error messages can surface here
      (0, _emberCpValidations.validator)('alias', 'validStartFormat'), (0, _emberCpValidations.validator)('alias', 'startDateBeforeEndDate'), (0, _emberCpValidations.validator)('alias', 'validStartDateLimit')]
    },
    'end.formatted': {
      dependentKeys: ['model.start.formatted'],
      validators: [// Field cannot be empty.
      createDatePresenceValidator(), // Alias validations so that the valid state and error messages can surface here
      (0, _emberCpValidations.validator)('alias', 'validEndFormat'), (0, _emberCpValidations.validator)('alias', 'endDateAfterStartDate')]
    },

    /**
     * Validate `start.formatted` is a valid format and same or before today.
     *
     * Separate into it's own validation instead of in `start.formatted` so that it can be used referenced
     * to disable other validations.
     */
    validStartFormat: (0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.start.formatted'],
      value: function value(model) {
        return Ember.get(model, 'start.formatted');
      },
      precision: 'day',
      onOrBefore: 'now',
      format: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      errorFormat: Ember.computed.alias('model.YEAR_MONTH_DAY')
    }),

    /**
     * Validate `end.formatted` is a valid format and same or before today.
     *
     * Separate into it's own validation instead of in `end.formatted` so that it can be used referenced
     * to disable other validations.
     */
    validEndFormat: (0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.end.formatted'],
      value: function value(model) {
        return Ember.get(model, 'end.formatted');
      },
      precision: 'day',
      onOrBefore: 'now',
      format: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      errorFormat: Ember.computed.alias('model.YEAR_MONTH_DAY')
    }),

    /**
     * Validate `start.formatted` is before the end date.
     *
     * Disabled if the `end.formatted` is a valid format.
     */
    startDateBeforeEndDate: (0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.{start,end}.formatted'],
      value: function value(model) {
        return Ember.get(model, 'start.formatted');
      },
      precision: 'day',
      onOrBefore: Ember.computed.alias('model.end.formatted'),
      format: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      errorFormat: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      disabled: Ember.computed.alias('model.validations.attrs.validEndFormat.isInvalid')
    }),

    /**
     * Validate `end.formatted` is before the end date.
     *
     * Disabled if the `start.formatted` is a valid format.
     */
    endDateAfterStartDate: (0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.{start,end}.formatted'],
      value: function value(model) {
        return Ember.get(model, 'end.formatted');
      },
      precision: 'day',
      onOrAfter: Ember.computed.alias('model.start.formatted'),
      format: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      errorFormat: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      disabled: Ember.computed.alias('model.validations.attrs.validStartFormat.isInvalid')
    }),

    /**
     * Validate `start.formatted` is not before earliest allowed start date
     *
     */
    validStartDateLimit: (0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.{start,end}.formatted'],
      value: function value(model) {
        return Ember.get(model, 'start.formatted');
      },
      precision: 'day',
      onOrAfter: '2009-01-01',
      format: Ember.computed.alias('model.YEAR_MONTH_DAY'),
      errorFormat: Ember.computed.alias('model.YEAR_MONTH_DAY')
    }),
    dateRangeCheck: [(0, _emberCpValidations.validator)('number', {
      value: function value(dateRangeModel) {
        var startDateFormatted = Ember.get(dateRangeModel, 'start.formatted');
        var endDateFormatted = Ember.get(dateRangeModel, 'end.formatted');
        var diff = (0, _moment.default)(endDateFormatted).diff(startDateFormatted, 'days'); //Diff is exclusive of the end date, so add one to account for all dates to be viewed.

        return diff + 1;
      },
      message: Ember.computed(function () {
        var formatter = Ember.get(this, 'model.i18n');
        var translationNamespace = 'components.fit-date-range-picker';
        var minPeriod = Ember.get(this, 'model.dateRangeMinPeriod');
        var maxPeriod = Ember.get(this, 'model.dateRangeMaxPeriod'); // get the correct number for the date unit e.g. days, months, years

        var convertDays = Ember.get(this, 'model._getConvertedDays');
        var gte = convertDays(minPeriod, this.gte);
        var lte = convertDays(maxPeriod, this.lte); // do translation of date unit string

        minPeriod = formatter.formatByNamespace(minPeriod, translationNamespace);
        maxPeriod = formatter.formatByNamespace(maxPeriod, translationNamespace);
        return formatter.formatByNamespace('date_picker_range_validation_msg', translationNamespace, {
          gte: gte,
          minPeriod: minPeriod,
          lte: lte,
          maxPeriod: maxPeriod
        });
      }),
      gte: Ember.computed.oneWay('model.dateRangeMin'),
      lte: Ember.computed.oneWay('model.dateRangeMax'),
      disabled: Ember.computed.or('model.validations.attrs.start.formatted.isInvalid', 'model.validations.attrs.end.formatted.isInvalid', 'model.allowDisplayAll')
    })]
  });

  var _default = Ember.Component.extend(_escapeKeyHandler.default, Validations, {
    layout: _fitDateRangePicker.default,
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    classNames: ['fit-date-range-picker'],

    /**
     * Elements to exclude from outside-click component.
     * The class names will be excluded from triggering the onOutsideClick behavior of outside-click component.
     * Changing this will require changing the class names on elements
     * @type {String}
     */
    excludedOutsideDateRangeClasses: Ember.computed(function () {
      return '.toggle-date-picker';
    }),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),

    /**
     * The default number of days to shift.
     * @type {number}
     */
    defaultDaysToShift: 1,

    /**
     * Map of days to shift by period.
     * Defined here to control how many days are actually shifted over.
     * `months` could be 28, 29, 30, 31 depending on moment.js logic.
     * `year` could be 365 or leap year.
     */
    daysByPeriod: Ember.computed(function () {
      return {
        weeks: 7,
        months: 30,
        years: 365
      };
    }),

    /**
     * If a period is 'days', return the number of days between start and end date
     * @returns {number|null} - number of days or null if not days period
     */
    daysForCustomPeriod: Ember.computed('period', 'startDate', 'endDate', function () {
      var period = Ember.get(this, 'period');

      if (period === 'days') {
        var startDate = Ember.get(this, 'startDate');
        var endDate = Ember.get(this, 'endDate');
        return endDate.diff(startDate, 'days') + 1;
      }

      return null;
    }),

    /**
     * Data attribute for testing.
     */
    'data-test-qa': 'fit-date-range-picker',

    /**
     * Date string format, default is short hand.
     * E.g. "Mar 3, 2017"
     * @type {string}
     */
    dateStrFormat: 'll',

    /**
     * Determine whether the date picker is visible.
     * @type {boolean}
     */
    isDatePickerVisible: false,

    /**
     * Date range picker third-party reference.
     * @type {object}
     */
    flatpickrRef: undefined,

    /**
     * Date range picker periods. E.g. days, weeks, months, years.
     * @type {string}
     */
    period: Ember.computed('startDate', 'endDate', function () {
      var startDate = Ember.get(this, 'startDate');
      var endDate = Ember.get(this, 'endDate');
      var period;
      var numDates = endDate.diff(startDate, 'days') + 1;

      switch (numDates) {
        case 7:
          period = 'weeks';
          break;

        case 30:
          period = 'months';
          break;

        case 365:
          period = 'years';
          break;

        default:
          period = 'days';
      }

      return period;
    }),

    /**
     * Minimum date that can be selected, e.g. 2009-01.
     * @type {?moment}
     */
    minDate: null,

    /**
     * Minimum date from moment object.
     * @returns {date}
     */
    minDateFromMoment: Ember.computed('minDate', function () {
      var minDate = Ember.get(this, 'minDate');
      return minDate ? minDate.toDate() : minDate;
    }),

    /**
     * Maximum date that can be selected, e.g. today.
     * @type {?moment}
     */
    maxDate: null,

    /**
     * Maximum date from moment object.
     * @returns {date}
     */
    maxDateFromMoment: Ember.computed('maxDate', function () {
      var maxDate = Ember.get(this, 'maxDate');
      return maxDate ? maxDate.toDate() : maxDate;
    }),

    /**
     * Set to false to hide "1yr" button on date range picker
     * @type {boolean} - true displays, false hides
     */
    allowDisplayYear: true,

    /**
     * Set to true to display "All" button on date range picker
     * @type {boolean} - true displays, false hides
     */
    allowDisplayAll: false,

    /**
     * Minimum number of dates for viewing
     * @type {number} - 7 days
     */
    dateRangeMin: 7,

    /**
     * Translated label to go along with dateRangeMin
     * @type {string} - 7 'days'
     */
    dateRangeMinPeriod: 'days',

    /**
     * Maximum number of dates for viewing
     * @type {number} - 730 days === 2 years
     */
    dateRangeMax: 730,

    /**
     * Translated label to go along with dateRangeMax
     * @type {string} - 2 'years'
     */
    dateRangeMaxPeriod: 'years',

    /**
     * Source Moment instance of date range start,
     * @type {moment}
     */
    startDate: undefined,

    /**
     * Source Moment instance of date range end,
     * @type {moment}
     */
    endDate: undefined,

    /**
     * Start day of week.
     * @type {number}
     */
    startDayOfWeek: 0,

    /**
     * Provide locale configuration to the date picker.
     * @method
     * @returns {LocaleConfig}
     */
    localeConfig: Ember.computed(function () {
      var i18n = Ember.get(this, 'i18n');
      var constants = Ember.get(this, 'constants');
      var locale = Ember.get(i18n, 'locale');
      var momentLocale = constants.getVendorLocale(locale, 'MOMENT_LOCALES');

      var momentLocaleData = _moment.default.localeData(momentLocale);

      return {
        firstDayOfWeek: Ember.get(this, 'startDayOfWeek'),
        rangeSeparator: ' - ',
        ordinal: momentLocaleData.ordinal,
        weekdays: {
          longhand: momentLocaleData.weekdays(),
          shorthand: momentLocaleData.weekdaysShort()
        },
        months: {
          longhand: momentLocaleData.months(),
          shorthand: momentLocaleData.monthsShort()
        }
      };
    }),

    /**
     * Computed property for start date range object.
     */
    start: Ember.computed('startDate', function () {
      return this._createDateRangeObject('startDate');
    }),

    /**
     * Computed property for end date range object.
     */
    end: Ember.computed('endDate', function () {
      return this._createDateRangeObject('endDate');
    }),

    /**
     * Initial date picker range values.
     * Not dependent on other keys since this is only used on initialization.
     */
    initialDatePickerValue: Ember.computed(function () {
      var start = Ember.get(this, 'start.date');
      var end = Ember.get(this, 'end.date');
      return [start, end].filter(Ember.isPresent);
    }),

    /**
     * Display formatted start - end date range.
     * Show one day if start and end equal.
     * Otherwise split start/end with range separator from locale config .
     * @method
     * @returns {string}
     */
    dateStr: Ember.computed('startDate', 'endDate', function () {
      var dateStrFormat = Ember.get(this, 'dateStrFormat');
      var start = Ember.get(this, 'start.momentDate');
      var end = Ember.get(this, 'end.momentDate');
      var dateStr = '';
      var startFormatted;
      var endFormatted;

      if (start) {
        startFormatted = start.format(dateStrFormat);
      } // Set the datestr as the end.


      if (end) {
        dateStr = endFormatted = end.format(dateStrFormat);
      } // Show only one day if start and end date equal..


      if (startFormatted && endFormatted && startFormatted !== endFormatted) {
        var localeConfig = Ember.get(this, 'localeConfig');
        dateStr = [startFormatted, endFormatted].join(localeConfig.rangeSeparator);
      }

      return dateStr;
    }),

    /**
     * Return true if today is in view on chart, so the 'today' button can be disabled
     */
    isTodayInView: Ember.computed('startDate', 'endDate', function () {
      var moment = Ember.get(this, 'moment');
      var today = moment.moment(),
          startDate = Ember.get(this, 'startDate'),
          endDate = Ember.get(this, 'endDate');
      return today.isBetween(startDate, endDate, 'day', '[]');
    }),

    /**
     * Hook for after inserting the component.
     *
     * Handle updating the date range picker immediately if the component is to be visible when created.
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var isDatePickerVisible = Ember.get(this, 'isDatePickerVisible');

      if (isDatePickerVisible) {
        Ember.run.scheduleOnce('afterRender', this, 'updateDateRangePicker');
      }
    },

    /**
     * Hide the date picker one escape key up.
     * Triggered by HandleEscapeKeyMixin.
     * @private
     */
    onEscapeKeyUp: function onEscapeKeyUp() {
      if (Ember.get(this, 'isDatePickerVisible')) {
        this.toggleDatePicker();
      }
    },

    /**
     * Toggle date picker. Updates the date picker with the selected values, or resets it when hidden.
     */
    toggleDatePicker: function toggleDatePicker() {
      this.toggleProperty('isDatePickerVisible');

      if (!Ember.get(this, 'isDatePickerVisible')) {
        this.resetDatePicker();
      }

      this.updateDateRangePicker();
    },

    /**
     * Close the date picker.
     */
    closeDatePicker: function closeDatePicker() {
      Ember.set(this, 'isDatePickerVisible', false);
    },

    /**
     * Update the date range picker.
     * Set the date range without having the date picker trigger its onChange event, otherwise
     * there will be a continuous loop of onChange -> set properties -> update datepicker -> onChange
     */
    updateDateRangePicker: function updateDateRangePicker() {
      var flatpickrRef = Ember.get(this, 'flatpickrRef');

      if (flatpickrRef) {
        var start = Ember.get(this, 'start.date');
        var end = Ember.get(this, 'end.date');
        flatpickrRef.setDate([start, end], false);
      }
    },

    /**
     * Reset the date picker to startDate and endDate source values.
     */
    resetDatePicker: function resetDatePicker() {
      var _EmberGetProperties = Ember.getProperties(this, 'startDate', 'endDate'),
          startDate = _EmberGetProperties.startDate,
          endDate = _EmberGetProperties.endDate;

      Ember.setProperties(this, {
        'start.momentDate': startDate,
        'end.momentDate': endDate,
        startDate: startDate,
        endDate: endDate
      });
    },

    /**
     * Create a date range object containing momentDate, date, and formatted computed properties.
     * @param {string} sourceDatePropertyName - The source date key to look up from the component.
     * @returns {DateRange}
     * @private
     */
    _createDateRangeObject: function _createDateRangeObject(sourceDatePropertyName) {
      var momentService = Ember.get(this, 'moment');
      return Ember.Object.extend({
        moment: momentService,
        momentDate: Ember.computed("model.".concat(sourceDatePropertyName), {
          get: function get() {
            return Ember.get(this, "model.".concat(sourceDatePropertyName));
          },
          set: function set(_, date) {
            if (date) {
              (false && !(_moment.default.isMoment(date)) && Ember.assert('Date must be a moment instance.', _moment.default.isMoment(date)));
            }

            return date;
          }
        }),
        date: Ember.computed('momentDate', {
          get: function get() {
            var momentDate = Ember.get(this, 'momentDate');
            var ret = momentDate;

            if (momentDate) {
              ret = momentDate.toDate();
            }

            return ret;
          },
          set: function set(_, date) {
            var momentService = Ember.get(this, 'moment');
            var value = date;

            if (value) {
              value = momentService.moment(date);
            }

            Ember.set(this, 'momentDate', value);
            return date;
          }
        }),
        formatted: Ember.computed('momentDate', {
          get: function get() {
            var momentDate = Ember.get(this, 'momentDate');
            var YEAR_MONTH_DAY = Ember.get(this, 'model.YEAR_MONTH_DAY');
            var ret = momentDate;

            if (momentDate) {
              ret = momentDate.format(YEAR_MONTH_DAY);
            }

            return ret;
          },
          set: function set(_, date) {
            var momentService = Ember.get(this, 'moment');
            var YEAR_MONTH_DAY = Ember.get(this, 'model.YEAR_MONTH_DAY');
            var value = date;

            if (value) {
              value = momentService.moment(date, YEAR_MONTH_DAY);
            }

            Ember.set(this, 'momentDate', value);
            return date;
          }
        })
      }).create({
        model: this,
        sourceDatePropertyName: sourceDatePropertyName
      });
    },

    /**
     * Shift the date range via moment calculation method (add/subract) of 1 period.
     * A valid period is one that is supported by moment (day, week, month, year).
     * The date range is only shifted if it does not pass the maxDate or precede the minDate.
     *
     * @param method
     * @private
     */
    _shiftDateRange: function _shiftDateRange(method) {
      var period = Ember.get(this, 'period');

      var _this$_calculateDateR = this._calculateDateRangeShift(method, period),
          startDate = _this$_calculateDateR.startDate,
          endDate = _this$_calculateDateR.endDate;

      if (startDate && endDate) {
        this.closeDatePicker();
        Ember.setProperties(this, {
          period: period,
          'start.momentDate': startDate,
          'end.momentDate': endDate,
          startDate: startDate,
          endDate: endDate
        });

        this._triggerOnDateChange([startDate, endDate], period);
      }
    },

    /**
     * Calculates the date range shift over 1 whole period.
     * @param {string} method - The method name to caluclate, e.g. add, subtract
     * @param {string} period - A date range picker period to shift dates by. If `daysByPeriod` contains
     *  The number of days to shift, period is ignored.
     * @returns {object} - start and end dates shifted over 1 period.
     * @private
     */
    _calculateDateRangeShift: function _calculateDateRangeShift(method, period) {
      var momentService = Ember.get(this, 'moment');
      var daysToShift = Ember.get(this, 'daysForCustomPeriod') || Ember.get(this, "daysByPeriod.".concat(period));
      var minDate = Ember.get(this, 'minDate');
      var maxDate = Ember.get(this, 'maxDate');
      var startDate = Ember.get(this, 'start.momentDate');
      var endDate = Ember.get(this, 'end.momentDate');
      var dateRange = {};

      if (!endDate) {
        endDate = startDate;
      }

      var newStart = momentService.moment(startDate)[method](daysToShift, 'days');
      var newEnd = momentService.moment(endDate)[method](daysToShift, 'days');

      if (!minDate || newStart.isSameOrBefore(minDate, 'day')) {
        // Do not shift before min date
        if (newStart.isBefore(minDate, 'day')) {
          newStart = minDate;
        }

        var newDuration = _moment.default.duration(newEnd.diff(newStart));

        if (Math.round(newDuration.asDays()) < 7) {
          newEnd = period === 'months' ? newStart.clone().add(29, 'd') : newStart.clone().add(6, 'd');
        }

        Ember.setProperties(dateRange, {
          startDate: newStart,
          endDate: newEnd
        });
      } else if (!maxDate || newStart.isSameOrBefore(maxDate, 'day')) {
        // Do not shift past max date.
        if (newEnd.isAfter(maxDate, 'day')) {
          newEnd = maxDate;
        }

        var _newDuration = _moment.default.duration(newEnd.diff(newStart));

        if (Math.round(_newDuration.asDays()) < 7) {
          newStart = newEnd.clone().subtract(6, 'd');
        }

        Ember.setProperties(dateRange, {
          startDate: newStart,
          endDate: newEnd
        });
      }

      return dateRange;
    },

    /**
     * Triggers onDateRangeChange, if it exists, with selected dates and period.
     * @param {array} selectedDates - Array of start/end date objects.
     * @param {string} period - A date range picker periods. E.g. days, weeks, months, years.
     * @private
     */
    _triggerOnDateChange: function _triggerOnDateChange(selectedDates, period) {
      var onDateRangeChange = Ember.get(this, 'onDateRangeChange');

      if (onDateRangeChange) {
        return onDateRangeChange(selectedDates, period);
      }
    },

    /**
     * Generates the correct number string based on date unit
     * @param {string} dateUnit - 'years'
     * @param {number} numDays - 730
     * @return {string} e.g. '2' for dateUnit 'years'
     * whole number converted to string for translations
     * @private
     */
    _getConvertedDays: function _getConvertedDays(dateUnit, numDays) {
      var val;

      switch (dateUnit) {
        case 'weeks':
          val = numDays / 7;
          break;

        case 'months':
          val = numDays / 30;
          break;

        case 'years':
          val = numDays / 365;
          break;

        default:
          val = numDays;
      }

      return Math.round(val).toString();
    },
    actions: {
      /**
       * Triggered by date picker when a change occurs. Update the start and end objects.
       * @param {array} selectedDates - An array of Date objects.
       */
      dateRangeChange: function dateRangeChange(selectedDates) {
        Ember.setProperties(this, {
          'start.date': selectedDates[0],
          'end.date': selectedDates[1]
        });
      },

      /**
       * Triggered by input. Update start or end objects. Updates date range picker if validation is valid.
       * @param {object} dateRangeObject - The date range object to change `formatted` on.
       * @param {object} value - The date string to set.
       */
      inputChange: function inputChange(dateRangeObject, value) {
        Ember.set(dateRangeObject, 'formatted', value);
        var isValid = Ember.get(this, 'validations.isValid');

        if (isValid) {
          Ember.run.scheduleOnce('afterRender', this, 'updateDateRangePicker');
        }
      },

      /**
       * Finalize date range changes after validation. Closes the date picker.
       * @param {object} e - The event object
       */
      submitDateChange: function submitDateChange(e) {
        e.preventDefault();
        var isValid = Ember.get(this, 'validations.isValid');

        if (isValid) {
          var DAYS_BY_PERIOD = Ember.get(this, 'daysByPeriod');
          var selectedDates = [Ember.get(this, 'start.momentDate'), Ember.get(this, 'end.momentDate')];
          var daysDisplayed = selectedDates[1].diff(selectedDates[0], 'days') + 1;

          if (daysDisplayed !== DAYS_BY_PERIOD.weeks && daysDisplayed !== DAYS_BY_PERIOD.months && daysDisplayed !== DAYS_BY_PERIOD.years) {
            Ember.set(this, 'daysForCustomPeriod', daysDisplayed);
            Ember.set(this, 'period', 'days');
          }

          var period = Ember.get(this, 'period');
          Ember.set(this, 'startDate', selectedDates[0]);
          Ember.set(this, 'endDate', selectedDates[1]);

          this._triggerOnDateChange(selectedDates, period);

          this.toggleDatePicker();
        }
      },

      /**
       * Toggle the date picker.
       */
      toggleDatePicker: function toggleDatePicker() {
        this.toggleDatePicker();
      },

      /**
       * Change the date range period. Update the date range picker if it is visible.
       * @param {string} period - Period type, e.g. days, weeks, months, years.
       *  A date range picker period to shift dates by. If `daysByPeriod` contains
       *  the number of days to shift, period is ignored.
       * @param {boolean} isToday - If true, endDate becomes today and the period remains the same.
       */
      periodChange: function periodChange(period, isToday) {
        var momentService = Ember.get(this, 'moment');
        var startDate;
        var endDate;

        if (period === 'all') {
          Ember.set(this, 'period', period);
          return;
        }

        if (isToday) {
          var today = momentService.moment();
          var currentEndDate = Ember.get(this, 'endDate');

          if (currentEndDate && !currentEndDate.isSame(today, 'day')) {
            var daysToSubtract = Ember.get(this, 'daysForCustomPeriod') || Ember.get(this, "daysByPeriod.".concat(period));
            endDate = momentService.moment();
            startDate = endDate.clone().subtract(daysToSubtract - 1, 'days');
          } else {
            endDate = currentEndDate;
            startDate = Ember.get(this, 'startDate');
          }
        } else {
          var DAYS_BY_PERIOD = Ember.get(this, 'daysByPeriod');
          var daysToShift = Ember.get(DAYS_BY_PERIOD, period);
          var minDate = Ember.get(this, 'minDate'); // end date could be empty, so fallback to the start date.

          endDate = Ember.get(this, 'end.momentDate') || Ember.get(this, 'start.momentDate');
          startDate = momentService.moment(endDate).subtract(daysToShift - 1, 'days'); // If the minimum date is set and the start date is before the min date
          // Then use the startDate as the anchor point and shift the end date.

          if (minDate && startDate.isBefore(minDate)) {
            startDate = momentService.moment(minDate);
            endDate = momentService.moment(startDate).add(daysToShift - 1, 'days');
          }
        }

        Ember.setProperties(this, {
          period: period,
          'start.momentDate': startDate,
          'end.momentDate': endDate,
          startDate: startDate,
          endDate: endDate
        });

        this._triggerOnDateChange([startDate, endDate], period);

        if (Ember.get(this, 'isDatePickerVisible')) {
          Ember.run.scheduleOnce('afterRender', this, 'updateDateRangePicker');
        }
      },

      /**
       * Trigger `onIncrementDateRange` method that's passed in.
       * Update the date ranger picker if it is visible.
       */
      incrementDateRange: function incrementDateRange() {
        this._shiftDateRange('add');
      },

      /**
       * Trigger `onDecrementDateRange` method that's passed in.
       * Update the date ranger picker if it is visible.
       */
      decrementDateRange: function decrementDateRange() {
        this._shiftDateRange('subtract');
      },

      /**
       * Trigger toggleDatePicker when clicked outside of the date range picker
       */
      outsideClickCloseDatePicker: function outsideClickCloseDatePicker() {
        if (Ember.get(this, 'isDatePickerVisible')) {
          this.toggleDatePicker();
        }
      }
    }
  });

  _exports.default = _default;
});