define("snapdragon-common/mixins/validations/date-of-birth", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.birthdate = _exports.year = void 0;

  /**
   * Validate a year that is before today's year, formatted in YYYY
   */
  var year = {
    message: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('year_validation_msg', 'components.profile-settings');
    }),
    validators: [(0, _emberCpValidations.validator)('date', {
      allowString: true,
      onOrAfter: '1890',
      format: 'YYYY'
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 4
    })]
  };
  /**
   * Validate a birthdate that is at minimum 13 years from today.
   */

  _exports.year = year;
  var birthdate = (0, _emberCpValidations.validator)('date', {
    allowBlank: true,
    format: 'YYYY-MM-DD',
    onOrAfter: '1890/01/01',
    precision: 'day',
    onOrBefore: Ember.computed(function () {
      var _EmberGetProperties = Ember.getProperties(this, 'format', 'model'),
          format = _EmberGetProperties.format,
          model = _EmberGetProperties.model;

      return Ember.get(model, 'moment').moment().subtract(13, 'years').startOf('day').format(format);
    }),
    message: function message(type) {
      var i18n = Ember.get(this, 'model.i18n');

      if (type === 'wrongDateFormat') {
        return i18n.formatByNamespace('wrong_date_format', 'common.mixins.validations.date-of-birth');
      } else {
        return i18n.formatByNamespace('birthdate_validation_msg', 'components.profile-settings');
      }
    }
  });
  _exports.birthdate = birthdate;
  var _default = {
    // -------------------------------------------------------------------------
    // Properties
    year: year,
    birthdate: (0, _emberCpValidations.validator)('date', Ember.assign({
      disabled: Ember.computed.oneWay('model.validations.attrs.year.isInvalid')
    }, birthdate.options))
  };
  _exports.default = _default;
});