define("snapdragon-common/constants/onboarding-destinations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HEALTH_COACHING = _exports.PARTNER = _exports.DEFAULT = void 0;

  /**
   * Constants for onboarding success scenarios names
   */
  var DEFAULT = 'DEFAULT';
  _exports.DEFAULT = DEFAULT;
  var PARTNER = 'PARTNER';
  _exports.PARTNER = PARTNER;
  var HEALTH_COACHING = 'HEALTH_COACHING';
  _exports.HEALTH_COACHING = HEALTH_COACHING;
});