define("ember-fitbit-webview-comms/initializers/setup-webview-comms", ["exports", "@fitbit/webview-comms-lib", "ember-window-mock", "snapdragon-common/constants/performance"], function (_exports, _webviewCommsLib, _emberWindowMock, _performance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var webviewComms = _emberWindowMock.default.__webview_comms__;
    /**
     * Defer application readiness when webviewComms is supported.
     * Don't defer if the application used in tests though.
     *
     * Need to check for either `window.WebViewJsInterface || window.isWebviewCommsSupported` because Android and iOS are inconsistent.
     * Note that Android does add `window.isWebviewCommsSupported` but it is not always available.
     */

    if (webviewComms && !!(_emberWindowMock.default.WebViewJsInterface || _emberWindowMock.default.isWebviewCommsSupported) && !application.testing) {
      /**
       * Register the lib so it can be looked up by webview-comms service.
       */
      (0, _performance.performanceMark)('setupWVCRegister');
      application.register('webview-comms:lib', webviewComms, {
        instantiate: false
      });
      application.deferReadiness();
      /**
       * Once webviewComms is ready, advance the application readiness so it continues booting up.
       */

      webviewComms.on('ready', function () {
        application.advanceReadiness();
        (0, _performance.performanceMark)('setupWVCReady');
      });
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});