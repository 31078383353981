define("snapdragon-common/constants/corporate-profile-gender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FEMALE = _exports.MALE = _exports.NOT_AVAILABLE = void 0;

  /**
   * @type {String}
   */
  var NOT_AVAILABLE = 'na';
  /**
   * @type {String}
   */

  _exports.NOT_AVAILABLE = NOT_AVAILABLE;
  var MALE = 'male';
  /**
   * @type {String}
   */

  _exports.MALE = MALE;
  var FEMALE = 'female';
  _exports.FEMALE = FEMALE;
});