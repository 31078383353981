define("snapdragon-common/mixins/weight-data", ["exports", "ember-cp-validations", "snapdragon-common/utils/unit-converter", "snapdragon-common/constants/query-params"], function (_exports, _emberCpValidations, _unitConverter, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FatValidator = _exports.StonePoundsValidator = _exports.StonesValidator = _exports.KilogramsValidator = _exports.PoundsValidator = void 0;
  var PoundsValidator = (0, _emberCpValidations.validator)('number', {
    allowString: true,
    gte: 5,
    lt: 1500,
    unit: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_US', 'units');
    }),
    message: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
        gt: '{gte}',
        lt: '{lt}',
        unit: '{unit}'
      });
    }),
    disabled: Ember.computed.not('model.isUSWeight')
  });
  _exports.PoundsValidator = PoundsValidator;
  var KilogramsValidator = (0, _emberCpValidations.validator)('number', {
    allowString: true,
    gte: 2.5,
    lt: 680,
    unit: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_metric', 'units');
    }),
    message: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
        gt: '{gte}',
        lt: '{lt}',
        unit: '{unit}'
      });
    }),
    disabled: Ember.computed.not('model.isMetricWeight')
  });
  _exports.KilogramsValidator = KilogramsValidator;
  var StonesValidator = (0, _emberCpValidations.validator)('number', {
    allowString: true,
    gte: 0,
    lt: 107,
    unit: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_GB', 'units');
    }),
    message: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
        gt: '{gte}',
        lt: '{lt}',
        unit: '{unit}'
      });
    }),
    disabled: Ember.computed.not('model.isUKWeight')
  });
  _exports.StonesValidator = StonesValidator;
  var StonePoundsValidator = (0, _emberCpValidations.validator)('number', {
    allowString: true,
    gt: Ember.computed('model.stones', function () {
      var stones = Ember.get(this, 'model.stones');
      return stones <= 0 ? 5 : -1;
    }),
    lt: 14,
    unit: Ember.computed(function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_US', 'units');
    }),
    message: Ember.computed('model.stones', function () {
      var stones = Ember.get(this, 'model.stones');

      if (stones > 0) {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
          gt: 0,
          lt: '{lt}',
          unit: '{unit}'
        });
      }

      return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
        gt: '{gt}',
        lt: '{lt}',
        unit: '{unit}'
      });
    }),
    disabled: Ember.computed('model.{isUKWeight,validations.attrs.stones.isInvalid}', function () {
      var isUKWeight = Ember.get(this, 'model.isUKWeight');
      var stonesIsInvalid = Ember.get(this, 'model.validations.attrs.stones.isInvalid');
      return !isUKWeight || stonesIsInvalid;
    })
  });
  _exports.StonePoundsValidator = StonePoundsValidator;
  var FatValidator = (0, _emberCpValidations.validator)('number', {
    allowString: true,
    allowBlank: true,
    gte: 5,
    lte: 75,
    message: Ember.computed('model.fat', function () {
      return Ember.get(this, 'model.i18n').formatByNamespace('percentage_error', 'templates.components.weight-log-add');
    })
  });
  _exports.FatValidator = FatValidator;
  var Validations = (0, _emberCpValidations.buildValidations)({
    pounds: PoundsValidator,
    kilograms: KilogramsValidator,
    stones: StonesValidator,
    stonePounds: StonePoundsValidator,
    fat: FatValidator
  });

  var _default = Ember.Mixin.create(Validations, {
    i18n: Ember.inject.service(),
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    isUSWeight: Ember.computed.readOnly('currentUser.isUSWeight'),
    isUKWeight: Ember.computed.readOnly('currentUser.isUKWeight'),
    isMetricWeight: Ember.computed.readOnly('currentUser.isMetricWeight'),
    weight: Ember.computed.oneWay('currentUser.weight'),

    /**
     * Placeholder which components using WeightData will overwrite
     */
    fat: null,

    /**
     * Get/set user's weight in kilograms
     */
    kilograms: Ember.computed('weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');

        if (grams > 0) {
          return _unitConverter.default.gramsToKilograms(grams, 1, true);
        } else {
          return 0;
        }
      },
      set: function set(key, value) {
        var grams = _unitConverter.default.kilogramsToGrams(value);

        Ember.set(this, 'weight', grams);
        return value;
      }
    }),

    /**
     * Get/set user's weight in pounds
     */
    pounds: Ember.computed('weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');

        if (grams > 0) {
          return _unitConverter.default.gramsToPounds(grams);
        } else {
          return 0;
        }
      },
      set: function set(key, value) {
        var grams = _unitConverter.default.poundsToGrams(value);

        Ember.set(this, 'weight', grams);
        return value;
      }
    }),

    /**
     * Get/set user's weight in stones and pounds
     */
    _stonesAndPounds: Ember.computed('weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');

        if (grams < 0) {
          return {
            stones: 0,
            pounds: 0
          };
        }

        return _unitConverter.default.gramsToStonesAndPounds(grams);
      },
      set: function set(key, value) {
        var grams = _unitConverter.default.stonesAndPoundsToGrams(value.stones, value.pounds);

        Ember.set(this, 'weight', grams);
        return value;
      }
    }),

    /**
     * Get/set user's weight in stones
     */
    stones: Ember.computed('_stonesAndPounds', 'weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');

        if (grams < 0) {
          return 0;
        }

        var stonesAndPounds = _unitConverter.default.gramsToStonesAndPounds(grams, 1);

        return Ember.get(stonesAndPounds, 'stones');
      },
      set: function set(key, value) {
        var stonesAndPounds = Ember.get(this, '_stonesAndPounds');
        Ember.set(stonesAndPounds, 'stones', value);
        Ember.set(this, '_stonesAndPounds', stonesAndPounds);
        return value;
      }
    }),

    /**
     * User's weight in stones and pounds
     */
    stonePounds: Ember.computed('_stonesAndPounds', 'weight', {
      get: function get() {
        var grams = Ember.get(this, 'weight');

        if (grams < 0) {
          return 0;
        }

        var stonesAndPounds = _unitConverter.default.gramsToStonesAndPounds(grams, 1);

        return Ember.get(stonesAndPounds, 'pounds');
      },
      set: function set(key, value) {
        var stonesAndPounds = Ember.get(this, '_stonesAndPounds');
        Ember.set(stonesAndPounds, 'pounds', value);
        Ember.set(this, '_stonesAndPounds', stonesAndPounds);
        return value;
      }
    }),

    /**
     * Converts weight in grams to the user's weight unit
     * @param {number} weight - weight in grams
     * @returns {*} number if user uses US weight or metric weight; an object in the form {stones: val, pounds: val} if
     * the user uses UK weight.
     */
    convertWeight: function convertWeight(weight) {
      var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var roundDown = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var converter = null;

      if (Ember.get(this, 'currentUser.isUSWeight')) {
        converter = _unitConverter.default.gramsToPounds;
      } else if (Ember.get(this, 'currentUser.isUKWeight')) {
        converter = _unitConverter.default.gramsToStonesAndPounds;
      } else {
        // metric weight
        converter = _unitConverter.default.gramsToKilograms;
      }

      return digits ? converter(weight, digits, roundDown) : converter(weight);
    },

    /**
     * Converts weight in grams to the user's weight unit in decimal format rounded to the tenths place
     * I.e. if weight is in stones, will return a number only in stones, not in stones and pounds.
     * Used to format weight chart tooltips which round to first decimal.
     * @param {number} weight - weight in grams
     * @returns {number}
     */
    convertWeightDecimal: function convertWeightDecimal(grams) {
      if (Ember.get(this, 'currentUser.isUKWeight')) {
        return _unitConverter.default.gramsToStones(grams, 1);
      } else {
        return this.convertWeight(grams, 1, true);
      }
    },

    /**
     * Body weight formatted by user's weight unit preference.
     * @param {*} bodyWeight - weight converted into user's unit preference
     * @returns {string} formatted weight
     */
    formatWeight: function formatWeight(bodyWeight) {
      var formatter = Ember.get(this, 'i18n');

      if (Ember.get(this, 'currentUser.isUSWeight')) {
        return formatter.formatByNamespace('pounds', 'templates.components.weight', {
          weight: bodyWeight
        });
      } else if (Ember.get(this, 'currentUser.isUKWeight')) {
        return formatter.formatByNamespace('stones_and_pounds', 'templates.components.weight', {
          stones: bodyWeight.stones,
          pounds: bodyWeight.pounds
        });
      } else {
        // metric weight
        return formatter.formatByNamespace('kilograms', 'templates.components.weight', {
          weight: bodyWeight
        });
      }
    },

    /**
     * Returns the weight unit based on the user's preferred weight settings
     * @returns { string }
     */
    weightUnit: function weightUnit() {
      var formatter = Ember.get(this, 'i18n');
      var units = null;

      if (Ember.get(this, 'currentUser.isUSWeight')) {
        units = formatter.formatByNamespace('weight_symbol_US', 'units');
      } else if (Ember.get(this, 'currentUser.isUKWeight')) {
        units = formatter.formatByNamespace('weight_symbol_GB', 'units');
      } else {
        // metric weight
        units = formatter.formatByNamespace('weight_symbol_metric', 'units');
      }

      return units.trim();
    },

    /**
     * Convert weight to a string with weight and units
     * @param weight as a number or an object with stones/pounds
     * @returns {string}
     */
    _getFormattedWeightStr: function _getFormattedWeightStr(weight) {
      var formatter = Ember.get(this, 'i18n');

      if (!weight) {
        return '';
      }

      if (Ember.get(this, 'isUSWeight')) {
        return "".concat(weight, " ").concat(formatter.formatByNamespace('weight_symbol_US', 'units'));
      } else if (Ember.get(this, 'isUKWeight')) {
        var userWeight;

        if (typeof weight === 'number') {
          /* if the typeof weight is a number than even though units are UK we just return pounds */
          userWeight = "".concat(weight.toString(), " ").concat(formatter.formatByNamespace('weight_symbol_GB', 'units'));
        } else {
          var _EmberGetProperties = Ember.getProperties(weight, 'stones', 'pounds'),
              stones = _EmberGetProperties.stones,
              pounds = _EmberGetProperties.pounds;

          userWeight = "".concat(stones.toString(), " ").concat(formatter.formatByNamespace('weight_symbol_GB', 'units'), " ");
          userWeight += "".concat(pounds.toString(), " ").concat(formatter.formatByNamespace('weight_symbol_US', 'units'));
        }

        return userWeight;
      } else {
        return "".concat(weight, " ").concat(formatter.formatByNamespace('weight_symbol_metric', 'units'));
      }
    },

    /**
     * Update the current weight of the user
     * Private function is used when the current weight of the user needs to be updated
     * upon creating, updating, or deleting a weight.
     * @private
     */
    _updateCurrentWeight: function _updateCurrentWeight() {
      var weightList = Ember.get(this, 'controller.model.weightList.list');

      if (Ember.isPresent(weightList)) {
        var currentUser = Ember.get(this, 'currentUser');
        var userId = Ember.get(currentUser, 'id');
        var userRecord = this.store.peekRecord('profile', userId);
        return userRecord.reload();
      }
    },

    /**
     * Given a set of weight logs for a day, determine if there is a weight log for 11:59pm
     * Return that weight log if it exists, otherwise, return null
     * @param logForDay
     * @param date
     * @private
     * @returns {object | null}
     */
    _parseLogsForDay: function _parseLogsForDay(logForDay, date) {
      if (logForDay === null) {
        return;
      }

      var _EmberGetProperties2 = Ember.getProperties(logForDay, 'time', 'date'),
          logTime = _EmberGetProperties2.time,
          logDate = _EmberGetProperties2.date;

      return logTime === '23:59:59' && logDate === date ? logForDay : null;
    },

    /**
     * Given a fat, and a weight, update the weight log on both front end and server
     * If the fat is null, delete the fat on the server if necessary
     * The controller must be set to the isLoading=true state before calling this function
     * @param fat
     * @param weight
     * @param weightLog
     * @returns {promise}
     * @private
     */
    _updateWeightLog: function _updateWeightLog(fat, weight, weightLog) {
      var ajax = Ember.get(this, 'ajax');
      var deleteFatPromise = null;
      var updateWeightPromise = null;
      var curWeight = Ember.get(weightLog, 'weight');
      var curFat = Ember.get(weightLog, 'fat');

      if (curFat === fat && curWeight === weight) {
        return Ember.RSVP.resolve();
      } // If Weight has changed or if Fat has changed and is not null


      if (weight && weight !== curWeight || fat && fat !== curFat) {
        if (!weight) {
          weight = weightLog.get('weight');
        }

        if (fat) {
          fat = Math.round(fat * 10) / 10;
        }

        weightLog.set('weight', weight);
        weightLog.set('fat', fat);
        updateWeightPromise = weightLog.save();
      } // If fat has been set to null when it was previously a value


      if (curFat !== fat && !fat) {
        deleteFatPromise = ajax.delete("/user/-/body/log/fat/".concat(Ember.get(weightLog, 'logId'), ".json"), {
          namespace: '1.1'
        });
        weightLog.set('fat', null);
      }

      return Ember.RSVP.hash({
        weight: updateWeightPromise,
        fat: deleteFatPromise
      });
    },

    /**
     * Creates a weight log in the case that there is no manual log for specified date
     * If a manual log for today exists, update that record instead
     * @param {Number} fat
     * @param {Number} weight
     * @param {String} date - YYYY-MM-DD string
     * @returns {promise}
     */
    _createWeightLog: function _createWeightLog(fat, weight, date, controller) {
      var _this = this;

      var store = Ember.get(this, 'store');

      if (!Ember.get(controller, 'isLoading')) {
        Ember.set(controller, 'isLoading', true);
        var momentService = Ember.get(this, 'moment');
        var dayAfterDate = momentService.moment(date).add(1, 'd');
        var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
        var dateParams = {
          beforeDate: dayAfterDate.format(YEAR_MONTH_DAY),
          sort: _queryParams.DESC_SORT,
          limit: 1,
          offset: _queryParams.OFFSET,
          isWeightList: true
        }; // Query weight-logs by date to determine if there is a weight-log for the specific date
        // If there is, update that record, otherwise create a new one.  Because we want the last
        // record for the day, query with date=(day after) params, limit one to return the most recent
        // weight log before the day after.

        return store.queryRecord('weight-log', dateParams).then(function (weightLogResponse) {
          var logForDay = _this._parseLogsForDay(weightLogResponse, date);

          if (logForDay) {
            // Update
            return _this._updateWeightLog(fat, weight, logForDay).then(function () {
              _this.refresh();

              _this._updateCurrentWeight();
            }).finally(function () {
              Ember.set(controller, 'isLoading', false);
            });
          } else {
            // Create
            var _store = Ember.get(_this, 'store');

            if (!date) {
              var _momentService = Ember.get(_this, 'moment');

              var dateFormat = Ember.get(_this, 'YEAR_MONTH_DAY');
              date = _momentService.moment().format(dateFormat);
            }

            if (fat) {
              fat = Math.round(fat * 10) / 10;
            }

            var weightRecord = _store.createRecord('weight-log', {
              date: date,
              fat: fat,
              weight: weight,
              time: Ember.get(_this, 'END_HOUR_MIN_SEC')
            });

            return weightRecord.save().then(function () {
              _this.refresh();

              _this._updateCurrentWeight();
            }).finally(function () {
              Ember.set(controller, 'isLoading', false);
            });
          }
        });
      }
    },

    /**
     * Convert the weight passed in to grams
     * @param weight in either pounds, kg, or in an object with stone and pounds
     * @returns {number} user weight in grams
     */
    _weightToGrams: function _weightToGrams(weight) {
      if (Ember.get(this, 'isUSWeight')) {
        return _unitConverter.default.poundsToGrams(weight);
      } else if (Ember.get(this, 'isMetricWeight')) {
        return _unitConverter.default.kilogramsToGrams(weight);
      } else {
        return _unitConverter.default.stonesAndPoundsToGrams(Ember.get(weight, 'stones'), Ember.get(weight, 'pounds'));
      }
    },

    /**
     * Convert weight to a string with weight and units
     * @param weight as a number or an object with stones/pounds
     * @returns {string}
     */
    _getWeightStr: function _getWeightStr(weight) {
      var userWeight;
      var formatter = Ember.get(this, 'i18n');

      if (!weight) {
        return '';
      }

      if (Ember.get(this, 'isUSWeight')) {
        userWeight = "".concat(weight, " ").concat(formatter.formatByNamespace('weight_symbol_US', 'units'));
      } else if (Ember.get(this, 'isUKWeight')) {
        /* if the typeof weight is a number than even though units are UK we just return pounds */
        if (typeof weight === 'number') {
          userWeight = weight.toString() + ' ' + formatter.formatByNamespace('weight_symbol_GB', 'units');
        } else {
          var _EmberGetProperties3 = Ember.getProperties(weight, 'stones', 'pounds'),
              stones = _EmberGetProperties3.stones,
              pounds = _EmberGetProperties3.pounds;

          userWeight = stones.toString() + ' ' + formatter.formatByNamespace('weight_symbol_GB', 'units') + ' ';
          userWeight += pounds.toString() + ' ' + formatter.formatByNamespace('weight_symbol_US', 'units');
        }
      } else {
        userWeight = weight + ' ' + formatter.formatByNamespace('weight_symbol_metric', 'units');
      }

      return userWeight;
    }
  });

  _exports.default = _default;
});