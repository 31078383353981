define("snapdragon-common/components/cw-form-date-field", ["exports", "snapdragon-common/components/cw-form-field", "snapdragon-common/templates/components/cw-form-date-field"], function (_exports, _cwFormField, _cwFormDateField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getModifiedDateParts(oldDate, newDate) {
    var oldDateDecomposed = oldDate ? oldDate.split('-') : [];
    var newDateDecomposed = newDate ? newDate.split('-') : [];
    return ['year', 'month', 'day'].filter(function (_, i) {
      return oldDateDecomposed[i] !== newDateDecomposed[i];
    });
  }

  var MIN_YEAR = 1890;
  /**
   * Form component for date field.
   */

  var _default = _cwFormField.default.extend({
    layout: _cwFormDateField.default,

    /**
     * Constraint min date displayed in year `select`.
     */
    minYear: MIN_YEAR,

    /**
     * Constraint max date displayed in year `select`.
     */
    maxYear: new Date().getFullYear(),
    disabled: false,
    classNames: ['form-date-field'],
    mobileView: Ember.inject.service(),
    isMobile: Ember.computed.oneWay('mobileView.isMobile'),
    moment: Ember.inject.service(),
    focusedField: null,
    displayedFormat: 'L',
    years: Ember.computed('minYear', 'maxYear', function () {
      var minYear = this.minYear,
          maxYear = this.maxYear;
      return Ember.A(new Array(maxYear - minYear + 1).fill(maxYear).map(function (year, i) {
        var yearStr = (year - i).toString();
        return {
          value: yearStr,
          displayName: yearStr
        };
      }));
    }),
    displayedDate: Ember.computed('value', function () {
      var value = this.value;
      return value ? this.moment.moment(value).format(this.displayedFormat) : '';
    }),
    init: function init() {
      this._super(arguments); // On desktop component is considered touched as soon as it gets valid value


      if (!this.isMobile && this.value) {
        this.setTouched(true);
      }
    },
    focusDatePart: function focusDatePart(name) {
      var datePartEl = this.element.querySelector(".form-date-field-".concat(name, " select"));

      if (datePartEl) {
        datePartEl.focus();
      }
    },
    actions: {
      changeValue: function changeValue(year, month, day) {
        var hasEmptyValue = !(year && month && day);
        var isAllEmpty = !(year || month || day);
        var newValue = isAllEmpty ? '' : "".concat(year, "-").concat(month, "-").concat(day);
        var oldValue = this.value,
            isMobile = this.isMobile;
        var modifiedParts = getModifiedDateParts(oldValue, newValue);
        this.set('value', newValue); // On desktop component is considered touched as soon as it gets valid value

        if (!isMobile && !hasEmptyValue) {
          this.setTouched(true);
        } // On Desktop
        // - if user changes year => navigate to month
        // - if user changes months => navigate to day


        if (!isMobile) {
          if (modifiedParts.includes('year')) {
            this.focusDatePart('month');
          } else if (modifiedParts.includes('month')) {
            this.focusDatePart('day');
          }
        }

        this.onChange(newValue);
      },
      onFocusField: function onFocusField(field) {
        this.setFocused(true);
        this.set('focusedField', field);

        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this.onFocus.apply(this, args);
      },
      onBlurField: function onBlurField() {
        this.setFocused(false);
        this.set('focusedField', null);
        this.onBlur.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});