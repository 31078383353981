define("snapdragon-common/mixins/is-ios-webview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    webviewComms: Ember.inject.service(),
    userAgent: Ember.inject.service(),

    /**
     * Check if the app is loaded in an iOS WebView.
     * @returns {Boolean}
     */
    isIOSWebView: Ember.computed.and('webviewComms.isSupported', 'userAgent.os.isIOS')
  });

  _exports.default = _default;
});