define("consent-data/adapters/consent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    namespace: '1/user/-',

    /**
     * API path for user-consents
     * @override
     * @returns {string}
     */
    pathForType: function pathForType() {
      return 'user-consents';
    },

    /**
     * URL for updating the record. API does not require the ID as part of the path.
     *
     * @override
     * @param {string} id
     * @param {string} modelName
     * @param {object} snapshot
     * @returns {string}
     */
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var record = snapshot.record;
      var category = Ember.get(record, 'category.id');
      return "".concat(this._super(undefined, modelName, snapshot), "/").concat(category, "/consents.json");
    }
  });

  _exports.default = _default;
});