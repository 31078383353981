define("snapdragon-common/mixins/escape-key-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    initEscapeKeyUp: Ember.on('init', function () {
      this._escapeKeyUp = this._escapeKeyUp.bind(this);
    }),
    addEscapeKeyUpListener: Ember.on('didInsertElement', function () {
      document.addEventListener('keyup', this._escapeKeyUp, true);
    }),
    removeEscapeKeyUpListener: Ember.on('willDestroyElement', function () {
      document.removeEventListener('keyup', this._escapeKeyUp, true);
    }),

    /**
     * Handle escape key up.
     * Check if the component is destroyed or being destroyed before checking if escape key was pressed before
     * triggering onEscapeKeyUp method
     *
     * @param {Object} event Event interface object from DOm
     * @private
     */
    _escapeKeyUp: function _escapeKeyUp(event) {
      if (!(this.isDestroyed || this.isDestroying)) {
        if (event.keyCode === 27) {
          this.onEscapeKeyUp.apply(this, arguments);
        }
      }
    },
    onEscapeKeyUp: function onEscapeKeyUp() {}
  });

  _exports.default = _default;
});