define("snapdragon-common/components/fit-switch", ["exports", "snapdragon-common/templates/components/fit-switch"], function (_exports, _fitSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _fitSwitch.default,
    classNames: ['switch'],
    IDSuffix: '-switch',
    switchID: Ember.computed(function () {
      var _this$getProperties = this.getProperties('elementId', 'IDSuffix'),
          elementId = _this$getProperties.elementId,
          IDSuffix = _this$getProperties.IDSuffix;

      return "".concat(elementId).concat(IDSuffix);
    })
  });

  _exports.default = _default;
});