define("snapdragon-common/components/sleep-display-time", ["exports", "snapdragon-common/templates/components/sleep-display-time"], function (_exports, _sleepDisplayTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sleepDisplayTime.default,
    tagName: 'span'
  });

  _exports.default = _default;
});