define("snapdragon-common/constants/program-version-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EXPERIMENT = _exports.WHITELISTED = _exports.DEFAULTED = void 0;
  var DEFAULTED = 'DEFAULTED';
  _exports.DEFAULTED = DEFAULTED;
  var WHITELISTED = 'WHITELISTED';
  _exports.WHITELISTED = WHITELISTED;
  var EXPERIMENT = 'EXPERIMENT';
  _exports.EXPERIMENT = EXPERIMENT;
});