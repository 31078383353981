define("snapdragon-common/helpers/get-belongs-to-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBelongsToId = getBelongsToId;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Retrieve the ID of a model that "belongs to" the source model.
   *
   * The general approach of retrieving the ID of a model that "belongs to" a source model triggers a findRecord request.
   *
   * ```javascript
   * const id = model.get('some-relationship.id'); // request is triggered
   * ```
   *
   * Sometimes only the ID is needed, so `ember-data` provides a simple method for retrieving the relationship without
   * triggering a request, [Model.belongsTo](https://emberjs.com/api/ember-data/2.16/classes/DS.Model/methods/belongsTo?anchor=belongsTo) method.
   *
   * @param model
   * @param name
   */
  function getBelongsToId(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        model = _ref2[0],
        name = _ref2[1];

    (false && !(typeof model !== 'undefined') && Ember.assert('The model argument is missing', typeof model !== 'undefined'));
    (false && !(Ember.isPresent(name)) && Ember.assert('The name of the belongsTo relationship should be passed to `get-belongs-to-id` helper', Ember.isPresent(name)));
    (false && !(typeof model.belongsTo === 'function') && Ember.assert('model.belongsTo method is missing, model should be an instance of Model', typeof model.belongsTo === 'function'));
    return model.belongsTo(name).id();
  }

  var _default = Ember.Helper.helper(getBelongsToId);

  _exports.default = _default;
});