define("snapdragon-common/mixins/extract-transition-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds ability to derive route name and route path from `transition` object and build a full URL
   * using data from `window.location` and `transition` data. Is meant to be used in routes.
   *
   * Depends on system routing service.
   */
  var _default = Ember.Mixin.create({
    routing: Ember.inject.service('-routing'),
    buildUrl: function buildUrl(transition) {
      var _this$_getWindowLocat = this._getWindowLocation(),
          protocol = _this$_getWindowLocat.protocol,
          host = _this$_getWindowLocat.host;

      var pathname = this.getPathFromTransition(transition);
      return "".concat(protocol, "//").concat(host).concat(pathname);
    },
    buildUrlByPathname: function buildUrlByPathname(pathname) {
      var _this$_getWindowLocat2 = this._getWindowLocation(),
          protocol = _this$_getWindowLocat2.protocol,
          host = _this$_getWindowLocat2.host;

      return "".concat(protocol, "//").concat(host).concat(pathname);
    },
    getRouteNameFromTransition: function getRouteNameFromTransition(transition) {
      var routePath = transition.targetName.split('.');
      return routePath[routePath.length - 1];
    },
    getPathFromTransition: function getPathFromTransition(transition) {
      var params = Object.values(transition.params).filter(function (param) {
        return Object.values(param).length;
      });
      return this.get('routing').generateURL(transition.targetName, params, transition.queryParams);
    },
    addQueryParams: function addQueryParams(url, paramsObj) {
      var initialUrlHasQueryParams = url.indexOf('?') >= 0;
      var queryParamsStr = Object.keys(paramsObj).reduce(function (result, key) {
        result += "&".concat(key, "=").concat(paramsObj[key]);
        return result;
      }, '');

      if (!initialUrlHasQueryParams && queryParamsStr) {
        queryParamsStr = "?".concat(queryParamsStr.substring(1));
      }

      return url + queryParamsStr;
    },
    _getWindowLocation: function _getWindowLocation() {
      return window.location;
    }
  });

  _exports.default = _default;
});