define("snapdragon-common/mixins/food-nutrition", ["exports", "food-engine/constants/food-constants", "food-engine/constants/food-nutrients"], function (_exports, _foodConstants, _foodNutrients) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  Extend EmberObject to inlcude computed properties for displayName and textValue
   */
  var MacroNutrient = Ember.Object.extend({
    name: '',
    displayName: Ember.computed('value', 'name', function () {
      var formatter = Ember.get(this, 'formatter');
      return formatter.formatByNamespace(Ember.get(this, 'name'), 'templates.components.nutritional-facts');
    }),
    textValue: Ember.computed('value', 'name', function () {
      var formatter = Ember.get(this, 'formatter');
      var value = Ember.get(this, 'value');
      var name = Ember.get(this, 'name');
      var gramsStr = ['cholesterol', 'sodium', 'potassium'].includes(name) || _foodNutrients.VITAMIN_MINERALS.includes(name) ? formatter.formatByNamespace('milligrams_abbr', 'units') : formatter.formatByNamespace('grams_abbr', 'units');
      return value >= _foodConstants.MIN_NUTRIENT_DISPLAYED ? "".concat(value.toString(), "  ").concat(gramsStr) : '-';
    })
  });

  var _default = Ember.Mixin.create({
    i18n: Ember.inject.service(),

    /**
     * Take the nutrionalValues passed in and create an object for mainNutrients and vitaminMinerals
     *
     * @param nutritionalValues
     * @return {{mainNutrients, vitaminMinerals}}
     */
    createMacroStructs: function createMacroStructs(nutritionalValues) {
      var formatter = Ember.get(this, 'i18n');
      var vitaminMinerals = {};
      var mainNutrients = {};

      for (var macro in nutritionalValues) {
        var macroValue = Ember.get(nutritionalValues, macro);

        if (_foodNutrients.MAIN_NUTRIENTS.includes(macro)) {
          Ember.set(mainNutrients, macro, MacroNutrient.create({
            formatter: formatter,
            name: macro,
            value: macroValue % 1 !== 0 ? macroValue.toFixed(2) : macroValue
          }));
        }

        if (_foodNutrients.VITAMIN_MINERALS.includes(macro)) {
          // We always show vitamin A, vitamin C, calcium, iron
          if (macroValue !== 0 || ['vitaminA', 'vitaminC', 'calcium', 'iron'].includes(macro)) {
            Ember.set(vitaminMinerals, macro, MacroNutrient.create({
              formatter: formatter,
              name: macro,
              value: macroValue % 1 !== 0 ? macroValue.toFixed(2) : macroValue
            }));
          }
        }
      }

      return {
        mainNutrients: mainNutrients,
        vitaminMinerals: vitaminMinerals
      };
    }
  });

  _exports.default = _default;
});