define("ember-aupac-control/templates/components/aupac-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ulo68VI4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"mandatory\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"text-danger\"],[9],[0,\"* \"],[10]],\"parameters\":[]},null],[7,\"label\"],[11,\"class\",\"control-label\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"leftIcon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[9],[7,\"i\"],[12,\"class\",[28,[[21,\"leftIcon\"]]]],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"rightIcon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[9],[7,\"i\"],[12,\"class\",[28,[[21,\"rightIcon\"]]]],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[11,\"class\",\"form-control-feedback\"],[9],[7,\"i\"],[12,\"class\",[28,[[21,\"feedbackIcon\"]]]],[9],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"canShowErrors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"help-block\"],[9],[1,[21,\"helpText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"canShowErrors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"help-block\"],[9],[1,[21,\"helpText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\"],[11,\"class\",\"form-control-feedback\"],[9],[7,\"i\"],[12,\"class\",[28,[[21,\"feedbackIcon\"]]]],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aupac-control/templates/components/aupac-control.hbs"
    }
  });

  _exports.default = _default;
});