define("snapdragon-common/templates/components/hours-minutes-display-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3cw7aD8Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"hours-minutes-display-time\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[27,\"eq\",[[23,[\"hours\"]],0],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"hour-value time-value\"],[11,\"data-test-qa\",\"hour-display-time\"],[9],[1,[21,\"hours\"],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"hour-unit time-unit\"],[9],[1,[27,\"format-by-namespace\",[\"hours_short\",\"units\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[11,\"class\",\"minute-value time-value\"],[11,\"data-test-qa\",\"minute-display-time\"],[9],[1,[21,\"minutes\"],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"minute-unit time-unit\"],[9],[1,[27,\"format-by-namespace\",[\"minutes_short\",\"units\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/hours-minutes-display-time.hbs"
    }
  });

  _exports.default = _default;
});