define("snapdragon-common/components/badge-item", ["exports", "ember-attacher/defaults", "snapdragon-common/templates/components/badge-item"], function (_exports, _defaults, _badgeItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hideDelay = _defaults.default.hideDelay;

  var _default = Ember.Component.extend({
    layout: _badgeItem.default,
    tagName: 'a',
    classNames: ['badge-item'],
    attributeBindings: ['tabindex', 'data-test-qa'],
    tabindex: 0,
    'data-test-qa': 'badge-item',

    /**
     * The default hideDelay for popovers from ember-tooltips
     *
     * Defining it here so that we can override it for testing.
     *
     * @type {Number}
     */
    tooltipHideDelay: hideDelay,

    /**
     * Delays showing the tooltip by the given number of milliseconds
     *
     * @type {Number}
     */
    tooltipShowDelay: 500
  });

  _exports.default = _default;
});