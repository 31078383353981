define("ember-vega/components/vega-vis-container", ["exports", "ember-vega/templates/components/vega-vis-container"], function (_exports, _vegaVisContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component for accessing the component's dimensions.
   *
   * ```hbs
   * {{#vega-vis-container as |ui|}}
   *     {{vega-vis
   *         height=(div ui.dimensions.width 4.259259259)
   *         width=ui.dimensions.width
   *         spec=spec
   *         data=data
   *     }}
   * {{/vega-vis-container}}
   * ```
   *
   * @class VegaVisContainer
   * @yield {Object} ui
   * @yield {ClientRect} ui.dimensions
   * @yield {Element} ui.element
   * @yield {Ember.Component} ui.vis
   */
  var _default = Ember.Component.extend({
    layout: _vegaVisContainer.default,
    classNames: ['vega-vis-container'],
    _resizeTimer: null,

    /**
     * Dimensions of the component - return value of element.getBoundingClientRect().
     *
     * The dimensions can be used to pass the width and height of a vega-vis component.
     *
     * It's useful with aspect ratios.
     *
     * [MDN Docs: Element.getBoundingClientRect](https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect)
     *
     * @computed dimensions
     * @type {ClientRect}
     */
    dimensions: Ember.computed({
      get: function get() {
        return this.computeComponentDimensions();
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Determines if the component is being rendered in Fastboot.
     *
     * @computed fastboot
     * @type {Object|undefined}
     */
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    /**
     * Get the return value of element.getBoundingClientRect().
     *
     * @method computeComponentDimensions
     * @return {ClientRect}
     */
    computeComponentDimensions: function computeComponentDimensions() {
      return this.element.getBoundingClientRect();
    },

    /**
     * Set the return value of element.getBoundingClientRect() as `dimensions` property.
     *
     * @method recomputeComponentDimensions
     */
    recomputeComponentDimensions: function recomputeComponentDimensions() {
      if (!this.isDestroyed || !this.isDestroying) {
        Ember.set(this, 'dimensions', this.computeComponentDimensions());
      }
    },

    /**
     * Store and set the window resize event listener and recompute the dimensions.
     *
     * @method didInsertElement
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Ember.get(this, 'fastboot')) {
        var _windowResize = function _windowResize() {
          _this.resizeTimer = Ember.run.cancel(_this.resizeTimer);
          _this.resizeTimer = Ember.run.debounce(_this, Ember.run.scheduleOnce, 'afterRender', _this, 'recomputeComponentDimensions', 50);
        };

        Ember.set(this, '_windowResize', _windowResize);
        window.addEventListener('resize', _windowResize);
        window.addEventListener('orientationchange', _windowResize);
        Ember.run.scheduleOnce('afterRender', this, 'recomputeComponentDimensions');
      }
    },

    /**
     * Remove the window resize event listener.
     *
     * @method willDestroyElement
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'fastboot')) {
        this.resizeTimer = Ember.run.cancel(this.resizeTimer);
        window.removeEventListener('resize', Ember.get(this, '_windowResize'));
        window.removeEventListener('orientationchange', Ember.get(this, '_windowResize'));
      }
    }
  });

  _exports.default = _default;
});