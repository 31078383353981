define("snapdragon-common/utils/unit-converter", ["exports", "snapdragon-common/constants/unit-conversions"], function (_exports, _unitConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function gramsToKilograms(grams) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var roundDown = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (roundDown) {
      return Math.floor(grams / 100) / 10;
    }

    return roundToDigits(grams / 1000, digits);
  }

  function kilogramsToGrams(kgs) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(kgs * 1000, digits);
  }

  function gramsToPounds(grams) {
    return Math.floor(grams / _unitConversions.GRAMS_PER_POUND * 10) / 10;
  }

  function poundsToGrams(lbs) {
    return Math.ceil(lbs * _unitConversions.GRAMS_PER_POUND);
  }

  function gramsToStones(grams) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return roundToDigits(grams / (1 / _unitConversions.STONES_PER_GRAM), digits);
  }

  function poundsToStones(poundWeight) {
    return poundWeight / _unitConversions.POUNDS_PER_STONE;
  }

  function stonesToGrams(stones) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(stones * (1 / _unitConversions.STONES_PER_GRAM), digits);
  }

  function stonesToPounds(stones) {
    return stones * _unitConversions.POUNDS_PER_STONE;
  }

  function gramsToStonesAndPounds(grams) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var roundDown = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var digitMultiplier = Math.pow(10, digits);
    var stones = grams / _unitConversions.GRAMS_PER_STONE;
    var returnStones = Math.floor(stones);
    var remainder;

    if (roundDown) {
      remainder = Math.floor(grams - stonesToGrams(returnStones, 4)) / _unitConversions.GRAMS_PER_POUND;
    } else {
      remainder = stonesToPounds(stones - returnStones);
    }

    var returnPounds = Math.floor(remainder * digitMultiplier) / digitMultiplier;
    return {
      stones: returnStones,
      pounds: returnPounds
    };
  }

  function poundsToStonesAndPounds(pounds) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var roundDown = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var stones = pounds / _unitConversions.POUNDS_PER_STONE;
    var returnStones = Math.floor(stones);
    var remainderStones = stones - returnStones;
    var digitMultiplier = Math.pow(10, digits);
    var returnPounds;

    if (roundDown) {
      returnPounds = Math.floor(remainderStones * _unitConversions.POUNDS_PER_STONE * digitMultiplier) / digitMultiplier;
    } else {
      returnPounds = Math.round(remainderStones * _unitConversions.POUNDS_PER_STONE * digitMultiplier) / digitMultiplier;
    }

    return {
      stones: returnStones,
      pounds: returnPounds
    };
  }

  function stonesAndPoundsToGrams(stones, pounds, digits) {
    var poundsFloat = parseFloat(pounds);
    var stonesInt = parseInt(stones, 10);
    pounds = !isNaN(poundsFloat) ? poundsFloat : 0;
    pounds += !isNaN(stonesInt) ? stonesInt * _unitConversions.POUNDS_PER_STONE : 0;
    return poundsToGrams(pounds, digits);
  }
  /**
   * Length conversions
   */


  function cmToInches(cm) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    return roundToDigits(cm / _unitConversions.CM_PER_INCH, digits);
  }

  function inchesToCm(inches) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    return roundToDigits(inches * _unitConversions.CM_PER_INCH, digits);
  }

  function remainingInches(inches) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(inches - inchesToFeet(inches) * _unitConversions.INCH_PER_FOOT, digits);
  }

  function inchesToFeet(inches) {
    return Math.floor(inches / _unitConversions.INCH_PER_FOOT);
  }

  function feetAndInchesToCm(feet, inches) {
    var digits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var ins = feet * _unitConversions.INCH_PER_FOOT + Number.parseInt(inches);
    return roundToDigits(ins * _unitConversions.CM_PER_INCH, digits);
  }

  function cmToMiles(cm) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(cm / _unitConversions.CM_PER_MILE, digits);
  }

  function cmToKm(cm) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(cm / 100000, digits);
  }

  function milesToCm(miles) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return roundToDigits(miles * _unitConversions.CM_PER_MILE, digits);
  }

  function kmToCm(km) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return roundToDigits(km * 100000, digits);
  }

  function milesToMeters(miles) {
    return milesToCm(miles) * 0.01;
  }

  function milesToYards(miles) {
    return miles * _unitConversions.YARDS_PER_MILE;
  }

  function kilometersToMeters(kilometers) {
    return kilometers * 1000;
  }

  function kilometersToYards(kilometers) {
    return kilometersToMeters(kilometers) / _unitConversions.METERS_PER_YARD;
  }

  function cmToFeetAndInches(cm) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var inches = cmToInches(cm, 0);
    var feet = inches / _unitConversions.INCH_PER_FOOT;
    var nearestWholeFeet = parseInt(feet, 10);
    inches = roundToDigits(inches - nearestWholeFeet * _unitConversions.INCH_PER_FOOT, digits);
    return {
      feet: nearestWholeFeet,
      inches: inches
    };
  }

  function ozToMl(oz) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(oz * _unitConversions.ML_PER_OZ, digits);
  }

  function mlToOz(ml) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(ml * (1 / _unitConversions.ML_PER_OZ), digits);
  }

  function cupsToOz(cups) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(cups * _unitConversions.OZ_PER_CUP, digits);
  }

  function ozToCups(oz) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(oz * (1 / _unitConversions.OZ_PER_CUP), digits);
  }

  function mlToCups(ml) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(ml * (1 / _unitConversions.ML_PER_CUP), digits);
  }

  function cupsToMl(cups) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return roundToDigits(cups * _unitConversions.ML_PER_CUP, digits);
  }

  function celsiusToFahrenheit(celsius) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return roundToDigits(celsius / 5 * 9 + 32, digits);
  }

  function fahrenheitToCelsius(fahrenheit) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return roundToDigits((fahrenheit - 32) * 5 / 9, digits);
  }
  /**
   * Time conversions
   */


  function minutesToHoursAndMinutes(mins) {
    var hours = Math.floor(mins / 60, 0);
    var minutes = mins - hours * 60;
    return {
      hours: hours,
      minutes: minutes
    };
  }

  function secondsToMinutesAndSeconds(secs) {
    var minutes = Math.floor(secs / 60, 0);
    var seconds = secs - minutes * 60;
    return {
      minutes: minutes,
      seconds: seconds
    };
  }
  /**
   * Round numbers to digits.
   * Source: http://stackoverflow.com/a/19722641
   * @param number
   * @param digits
   * @returns {number}
   */


  function roundToDigits(number, digits) {
    return +(Math.round(number + 'e+' + digits) + 'e-' + digits);
  }

  var unitConverter = {
    gramsToKilograms: gramsToKilograms,
    gramsToPounds: gramsToPounds,
    gramsToStones: gramsToStones,
    gramsToStonesAndPounds: gramsToStonesAndPounds,
    kilogramsToGrams: kilogramsToGrams,
    poundsToGrams: poundsToGrams,
    poundsToStones: poundsToStones,
    stonesToGrams: stonesToGrams,
    stonesAndPoundsToGrams: stonesAndPoundsToGrams,
    poundsToStonesAndPounds: poundsToStonesAndPounds,
    cmToInches: cmToInches,
    inchesToCm: inchesToCm,
    remainingInches: remainingInches,
    inchesToFeet: inchesToFeet,
    feetAndInchesToCm: feetAndInchesToCm,
    cmToFeetAndInches: cmToFeetAndInches,
    roundToDigits: roundToDigits,
    minutesToHoursAndMinutes: minutesToHoursAndMinutes,
    secondsToMinutesAndSeconds: secondsToMinutesAndSeconds,
    cmToMiles: cmToMiles,
    cmToKm: cmToKm,
    milesToCm: milesToCm,
    kmToCm: kmToCm,
    milesToMeters: milesToMeters,
    milesToYards: milesToYards,
    kilometersToMeters: kilometersToMeters,
    kilometersToYards: kilometersToYards,
    mlToOz: mlToOz,
    ozToMl: ozToMl,
    cupsToOz: cupsToOz,
    ozToCups: ozToCups,
    mlToCups: mlToCups,
    cupsToMl: cupsToMl,
    stonesToPounds: stonesToPounds,
    celsiusToFahrenheit: celsiusToFahrenheit,
    fahrenheitToCelsius: fahrenheitToCelsius
  };
  var _default = unitConverter;
  _exports.default = _default;
});