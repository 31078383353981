define("ember-aupac-typeahead/templates/components/aupac-typeahead/suggestion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQyir6uM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"typeahead-suggestion\"],[9],[1,[23,[\"model\",\"displayName\"]],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aupac-typeahead/templates/components/aupac-typeahead/suggestion.hbs"
    }
  });

  _exports.default = _default;
});