define('@bagaar/ember-breadcrumbs/services/bgr-breadcrumbs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * state
     */

    instances: null,

    /**
     * hooks
     */

    init: function init() {
      this._super.apply(this, arguments);

      this.instances = Ember.A([]);
    }
  });
});