define("ude-console-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('data-export', {
      path: 'export'
    });
    this.route('legacy-data-export', {
      path: 'legacy-export'
    });
  });

  _exports.default = _default;
});