define("ember-fitbit-pwd-auth/services/session-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    userAgent: Ember.inject.service(),
    sessionData: Ember.computed(function () {
      return this.parseUserAgent();
    }).readOnly(),
    parseUserAgent: function parseUserAgent() {
      try {
        var _EmberGet = Ember.get(this, 'userAgent'),
            _EmberGet$browser$inf = _EmberGet.browser.info,
            browserName = _EmberGet$browser$inf.name,
            browserVersion = _EmberGet$browser$inf.version,
            _EmberGet$os$info = _EmberGet.os.info,
            osName = _EmberGet$os$info.name,
            osVersion = _EmberGet$os$info.version,
            _EmberGet$device$info = _EmberGet.device.info,
            deviceManufacturer = _EmberGet$device$info.vendor,
            deviceModel = _EmberGet$device$info.model;

        var deviceName;

        if (deviceManufacturer) {
          deviceName = deviceManufacturer + ' ' + deviceModel;
        } else {
          deviceName = osName;
        }

        return {
          'browser-name': browserName,
          'browser-version': browserVersion,
          'os-name': osName,
          'os-version': osVersion,
          'device-model': deviceModel ? deviceModel : '',
          'device-manufacturer': deviceManufacturer ? deviceManufacturer : '',
          'device-name': deviceName
        };
      } catch (e) {
        console.error('User Agent Header couldn\'t be parsed.');
      }

      return null;
    },
    serialize: function serialize() {
      var sessionData = Ember.get(this, 'sessionData');
      return sessionData ? JSON.stringify(Ember.get(this, 'sessionData')) : null;
    }
  });

  _exports.default = _default;
});