define("snapdragon-common/constants/page-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FINISH_ONBOARDING_BUTTON = _exports.COACHING_PREMIUM_GET_STARTED = _exports.DISCOVER_LINK = _exports.REMIND_LATER_BUTTON = _exports.CLOSE_BUTTON = void 0;

  /**
   * Constants for page element names, mostly for consistent usage in metrics
   */
  var CLOSE_BUTTON = 'x_close_button';
  _exports.CLOSE_BUTTON = CLOSE_BUTTON;
  var REMIND_LATER_BUTTON = 'remind_later_button';
  _exports.REMIND_LATER_BUTTON = REMIND_LATER_BUTTON;
  var DISCOVER_LINK = 'discover_link';
  _exports.DISCOVER_LINK = DISCOVER_LINK;
  var COACHING_PREMIUM_GET_STARTED = 'coaching_premium_get_started';
  _exports.COACHING_PREMIUM_GET_STARTED = COACHING_PREMIUM_GET_STARTED;
  var FINISH_ONBOARDING_BUTTON = 'finish_onboarding_button';
  _exports.FINISH_ONBOARDING_BUTTON = FINISH_ONBOARDING_BUTTON;
});