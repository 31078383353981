define("snapdragon-common/constants/app-deeplinks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IDENTITY_MIGRATION_POSTFIX = _exports.IDENTITY_MIGRATION = _exports.HEALTH_COACHING_JOIN_ENTERPRISE = _exports.DISCOVER = void 0;
  var DISCOVER = 'discover';
  _exports.DISCOVER = DISCOVER;
  var HEALTH_COACHING_JOIN_ENTERPRISE = 'health-coaching/join-enterprise';
  _exports.HEALTH_COACHING_JOIN_ENTERPRISE = HEALTH_COACHING_JOIN_ENTERPRISE;
  var IDENTITY_MIGRATION = 'identity-migration';
  _exports.IDENTITY_MIGRATION = IDENTITY_MIGRATION;
  var IDENTITY_MIGRATION_POSTFIX = 'identity-migrated';
  _exports.IDENTITY_MIGRATION_POSTFIX = IDENTITY_MIGRATION_POSTFIX;
});