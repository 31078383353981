define("snapdragon-common/components/change-email-address", ["exports", "ember-cp-validations", "snapdragon-common/mixins/email-actions", "snapdragon-common/templates/components/change-email-address"], function (_exports, _emberCpValidations, _emailActions, _changeEmailAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    newEmail: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: Ember.computed(function () {
          return Ember.get(this, 'model.i18n').formatByNamespace('must_enter_email', 'templates.components.profile-settings');
        }),
        disabled: Ember.computed.oneWay('model.disableNewEmailValidation')
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        message: Ember.computed(function () {
          return Ember.get(this, 'model.i18n').formatByNamespace('email_validation_msg', 'components.profile-settings');
        }),
        disabled: Ember.computed.oneWay('model.disableNewEmailValidation'),
        debounce: 500
      })]
    },
    confirmNewEmail: {
      validators: [(0, _emberCpValidations.validator)('confirmation', {
        on: 'newEmail',
        message: Ember.computed(function () {
          return Ember.get(this, 'model.i18n').formatByNamespace('no_match_email', 'templates.components.profile-settings');
        }),
        disabled: Ember.computed.oneWay('model.disableConfirmValidation'),
        debounce: 500
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: Ember.computed(function () {
          return Ember.get(this, 'model.i18n').formatByNamespace('must_confirm_email', 'templates.components.profile-settings');
        }),
        disabled: Ember.computed.oneWay('model.disableConfirmValidation'),
        debounce: 500
      })]
    },
    verifyPassword: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('must_enter_password', 'templates.components.profile-settings');
      }),
      disabled: Ember.computed.oneWay('model.disablePasswordValidation')
    })
  });

  var _default = Ember.Component.extend(Validations, _emailActions.default, {
    layout: _changeEmailAddress.default,
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    changeEmailModal: null,
    errorMessage: null,
    disableNewEmailValidation: true,
    disableConfirmValidation: true,
    disablePasswordValidation: true,
    validationsDisabled: Ember.computed.or('disableNewEmailValidation', 'disableConfirmValidation', 'disablePasswordValidation'),
    pendingEmail: Ember.computed('profile.pendingEmail', function () {
      return Ember.get(this, 'profile.pendingEmail');
    }),
    emailChangePending: Ember.computed.notEmpty('profile.pendingEmail'),
    onSaveEmailAddress: function onSaveEmailAddress() {},
    onResendEmailAddress: function onResendEmailAddress() {},
    onCancelEmailAddress: function onCancelEmailAddress() {},
    //https://wiki.fitbit.com/display/prdct/Change+Email+API
    actions: {
      /**
       * Will post to /1/account/changeEmail to make request
       * - marks account as unverified
       */
      changeEmailAddress: function changeEmailAddress() {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        var changeEmailModal = Ember.get(this, 'changeEmailModal');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid') && changeEmailModal) {
            var confirmEmail = Ember.get(_this, 'confirmNewEmail');
            return Ember.get(_this, 'onSaveEmailAddress')({
              email: confirmEmail,
              password: Ember.get(_this, 'verifyPassword')
            }).then(function () {
              Ember.set(_this, 'profile.pendingEmail', confirmEmail);
              flashMessages.success(formatter.formatByNamespace('weve_sent_email', 'templates.components.profile-settings'));
              changeEmailModal.close();
            }).catch(function (err) {
              var errMessage = Ember.get(err, 'payload.errors.0.message') || formatter.formatByNamespace('err_changing_email', 'templates.components.profile-settings');
              flashMessages.alert(errMessage, {
                isModal: true
              });
            });
          }
        });
      },

      /**
       * Will post to /verifyEmail/resend to make request
       *
       */
      resendEmailAddress: function resendEmailAddress() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        var changeEmailModal = Ember.get(this, 'changeEmailModal');

        if (changeEmailModal) {
          return Ember.get(this, 'onResendEmailAddress')().then(function () {
            flashMessages.success(formatter.formatByNamespace('weve_sent_email', 'templates.components.profile-settings'));
            changeEmailModal.close();
          }).catch(function (err) {
            var errMessage = Ember.get(err, 'payload.errors.0.message') || formatter.formatByNamespace('err_resending_email', 'templates.components.profile-settings');
            flashMessages.alert(errMessage, {
              isModal: true
            });
          });
        }
      },

      /**
       * Will post to /1/account/cancelPendingEmailChange
       */
      cancelEmailChange: function cancelEmailChange() {
        var _this2 = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var formatter = Ember.get(this, 'i18n');
        var changeEmailModal = Ember.get(this, 'changeEmailModal');

        if (changeEmailModal) {
          return Ember.get(this, 'onCancelEmailAddress')().then(function () {
            Ember.set(_this2, 'profile.pendingEmail', '');
            flashMessages.success(formatter.formatByNamespace('change_canceled', 'templates.components.profile-settings'));
            changeEmailModal.close();
          }).catch(function (err) {
            var errMessage = Ember.get(err, 'payload.errors.0.message') || formatter.formatByNamespace('cancel_email_change_err', 'templates.components.profile-settings');
            flashMessages.alert(errMessage, {
              isModal: true
            });
          });
        }
      },

      /**
       * Enables specific form validations upon interaction with the form
       * @param validation
       */
      enableValidation: function enableValidation(validation) {
        Ember.set(this, validation, false);
      }
    }
  });

  _exports.default = _default;
});