define("snapdragon-common/services/firebase", ["exports", "ember-get-config", "snapdragon-common/mixins/fit-deeplink-nav"], function (_exports, _emberGetConfig, _fitDeeplinkNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_DEEP_LINK = 'dashboard';
  var APP_STORE_ID = 462638897;
  var APP_QUERY_PARAMS = {
    fitbit: {
      apn: 'com.fitbit.FitbitMobile',
      ibi: 'com.fitbit.FitbitMobile',
      ius: 'fitbit',
      isi: APP_STORE_ID
    },
    betabit: {
      apn: 'com.fitbit.betabit.FitbitMobile.hockeyapp',
      ibi: 'com.fitbit.Betabit',
      ius: 'betabit',
      isi: APP_STORE_ID
    },
    devbit: {
      apn: 'com.fitbit.FitbitMobile.hockeyapp',
      ibi: 'com.fitbit.Devbit',
      ius: 'devbit',
      isi: APP_STORE_ID
    }
  }; // Actually there is no any particular reason to use devbit for int and betabit for stage

  var APP_PREFIX_FOR_DEPLOY_ENV = {
    int: 'devbit',
    stage: 'betabit',
    prod: 'fitbit'
  };
  /**
   * For test purposes we allow to point out target application using app-prefix query parameter,
   * otherwise
   * - we use devbit/betabit prefix for int/stage environments
   * - and fitbit prefix for prod environment
   */

  function getAppPrefix() {
    var params = new URLSearchParams(location.search.substring(1));
    var appPrefix = params.get('app-prefix');

    if (appPrefix) {
      return appPrefix;
    }

    var environment = _emberGetConfig.default.environment,
        deployEnvTarget = _emberGetConfig.default.deployEnvTarget; // Always use devbit for development and test environments

    if (environment !== 'production') {
      return 'devbit';
    }

    return APP_PREFIX_FOR_DEPLOY_ENV[deployEnvTarget] || 'fitbit';
  }
  /**
   * This service adds support of firebase dynamic links.
   */


  var _default = Ember.Service.extend(_fitDeeplinkNav.default, {
    deeplinkUrlPrefix: 'https://',
    appPrefix: getAppPrefix(),

    /**
     * - **On the desktop** it opens `url`. By default `url` is an URL of the current page.
     * - **On the mobile device** it opens specified deep link (`deepLink`) in the mobile application.
     * - **On the mobile device if application is not installed** it opens `url` in the browser.
     * - **On the mobile device if application is not installed and `orStore` flag is set** opens store for the Fitbit application.
     *
     * ```
     * // Open mobile application on the Discover screen
     * firebase.open({ deepLink: 'discover' });
     *
     * // Open mobile application on the Discover screen,
     * // otherwise, if application is not installed, the store will be opened
     * firebase.open({ deepLink: 'discover', orStore: true })
     *
     * // Open mobile application on the Discover screen,
     * // otherwise, if application is not installed, provided URL is opened
     * firebase.open({ deepLink: 'discover', url: 'www.fitbit.com/settings' })
     * ```
     */
    open: function open(options) {
      this.deeplinkTo(this._createDynamicLink(options));
    },

    /**
     * Generates link with the semantic described for `open` method
     */
    link: function link(options) {
      return this.getFullPath(this._createDynamicLink(options));
    },
    _createDynamicLink: function _createDynamicLink(_ref) {
      var _ref$deepLink = _ref.deepLink,
          deepLink = _ref$deepLink === void 0 ? DEFAULT_DEEP_LINK : _ref$deepLink,
          _ref$url = _ref.url,
          url = _ref$url === void 0 ? window.location.href : _ref$url,
          _ref$orStore = _ref.orStore,
          orStore = _ref$orStore === void 0 ? false : _ref$orStore;
      var link = url.indexOf('?') >= 0 ? "".concat(url, "&deepLink=").concat(encodeURIComponent(deepLink)) : "".concat(url, "?deepLink=").concat(encodeURIComponent(deepLink));
      var appParams = orStore ? APP_QUERY_PARAMS[this.appPrefix] : {};
      return {
        path: 'link.fitbit.com',
        queryParams: _objectSpread({}, appParams, {
          link: link,
          ofl: url,
          efr: 1
        })
      };
    }
  });

  _exports.default = _default;
});