define("snapdragon-common/templates/components/input-and-label-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aP4jRsIv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column shrink\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column expand\"],[9],[0,\"\\n        \"],[1,[27,\"input\",[[27,\"-input-type\",[[23,[\"type\"]]],null]],[[\"type\",\"placeholder\",\"value\",\"input\",\"focus-out\",\"data-test-qa\"],[[23,[\"type\"]],[23,[\"placeholder\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],[23,[\"validateOnInput\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"enableValidationOnFocusOut\"]]],null],\"ialr\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/input-and-label-row.hbs"
    }
  });

  _exports.default = _default;
});