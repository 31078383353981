define("snapdragon-common/constants/macro-nutrients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PROTEIN = _exports.FAT = _exports.CARBS = void 0;
  var CARBS = 'carbs';
  _exports.CARBS = CARBS;
  var FAT = 'fat';
  _exports.FAT = FAT;
  var PROTEIN = 'protein';
  _exports.PROTEIN = PROTEIN;
});