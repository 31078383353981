define("consent-data/constants/gdpr-consent-ids", ["exports", "consent-data/utils/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SETTINGS_CHECKUP = _exports.REAFFIRM_EXISTING_EU_USER = _exports.CATEGORY = void 0;

  /**
   * Consent category
   * @type {string}
   */
  var CATEGORY = 'gdpr';
  /**
   * Constant for `reaffirm-existing-eu-user` consent from GDPR category.
   * Composite ID where consent ID and consent category is separated by `::`.
   *
   * @type {string}
   */

  _exports.CATEGORY = CATEGORY;
  var REAFFIRM_EXISTING_EU_USER = (0, _string.join)('reaffirm-existing-eu-user', CATEGORY);
  _exports.REAFFIRM_EXISTING_EU_USER = REAFFIRM_EXISTING_EU_USER;
  var SETTINGS_CHECKUP = (0, _string.join)('settings-checkup', CATEGORY);
  _exports.SETTINGS_CHECKUP = SETTINGS_CHECKUP;
});