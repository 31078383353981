define("snapdragon-common/templates/components/cw-form-select-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fUMaeaqj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"cw-form-field-group\",null,[[\"floatLabel\",\"label\",\"focused\",\"empty\",\"showError\",\"errorText\",\"dataTestQa\"],[[27,\"if\",[[23,[\"empty\"]],\"never\",\"always\"],null],[23,[\"label\"]],[23,[\"focused\"]],[23,[\"empty\"]],[23,[\"shouldShowError\"]],[23,[\"errorText\"]],[23,[\"dataTestQa\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"cw-select-box\",null,[[\"disabled\",\"isMobile\",\"hasError\",\"options\",\"translationNamespace\",\"bypassTranslations\",\"hasEmpty\",\"value\",\"dataTestQa\",\"onChange\",\"onFocus\",\"onBlur\"],[[23,[\"disabled\"]],[23,[\"isMobile\"]],[23,[\"shouldShowError\"]],[23,[\"displayedOptions\"]],[23,[\"translationNamespace\"]],[23,[\"bypassTranslations\"]],[23,[\"hasEmpty\"]],[23,[\"selectedValue\"]],[27,\"if\",[[23,[\"dataTestQa\"]],[27,\"concat\",[\"field-\",[23,[\"dataTestQa\"]]],null]],null],[27,\"action\",[[22,0,[]],\"changeValue\"],null],[27,\"action\",[[22,0,[]],\"onFocus\"],null],[27,\"action\",[[22,0,[]],\"onBlur\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "snapdragon-common/templates/components/cw-form-select-field.hbs"
    }
  });

  _exports.default = _default;
});