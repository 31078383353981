define("snapdragon-common/mixins/adapters/application", ["exports", "ember-simple-auth/mixins/data-adapter-mixin", "ember-get-config"], function (_exports, _dataAdapterMixin, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_dataAdapterMixin.default, {
    i18n: Ember.inject.service(),
    host: _emberGetConfig.default.host,

    /**
     * Method to create default headers for the app. Use this method
     * when `headers` computed property is overriden by a subclass
     * So that the necessary default headers are available.
     *
     */
    createHeaders: function createHeaders() {
      var headers = {
        'Accept-Locale': this.i18n.get('userLocale'),
        'Accept-Language': 'x_machine'
      };

      if (this.session.isAuthenticated) {
        headers.Authorization = "Bearer ".concat(this.session.data.authenticated.access_token);
      }

      return headers;
    },
    headers: Ember.computed('userLocale', 'session.isAuthenticated', function () {
      return this.createHeaders();
    }),

    /**
     * Array containing request methods that should be replaced with POST
     * Fitbit API doesn't always handle PUT or PATCH so those are the defaults.
     * @returns {array};
     */
    methodPostForTypes: Ember.computed(function () {
      return ['PUT', 'PATCH'];
    }),
    ajax: function ajax(url, type, options) {
      var _EmberGetProperties = Ember.getProperties(this, 'methodPostForTypes'),
          methodPostForTypes = _EmberGetProperties.methodPostForTypes;

      if (methodPostForTypes.includes(type)) {
        type = 'POST';
      }

      return this._super(url, type, options);
    }
  });

  _exports.default = _default;
});