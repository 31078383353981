define("snapdragon-common/constants/query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ASC_SORT = _exports.DESC_SORT = _exports.LIMIT = _exports.OFFSET = void 0;

  /**
   * Constants for query params
   */
  var OFFSET = 0;
  _exports.OFFSET = OFFSET;
  var LIMIT = 20;
  _exports.LIMIT = LIMIT;
  var DESC_SORT = 'desc';
  _exports.DESC_SORT = DESC_SORT;
  var ASC_SORT = 'asc';
  _exports.ASC_SORT = ASC_SORT;
});