define("snapdragon-common/constants/user-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IS_MOBILE = void 0;

  /**
   * Flag to determine if the user agent is a mobile device.
   *
   * @type {Boolean}
   */
  var IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  _exports.IS_MOBILE = IS_MOBILE;
});