define("snapdragon-common/components/journal-survey-question-slider-legend", ["exports", "snapdragon-common/templates/components/journal-survey-question-slider-legend"], function (_exports, _journalSurveyQuestionSliderLegend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _journalSurveyQuestionSliderLegend.default
  });

  _exports.default = _default;
});