define("snapdragon-common/mixins/log-week-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    constants: Ember.inject.service(),
    moment: Ember.inject.service(),
    i18n: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),

    /**
     * Computes whether or not the passed in date is today.
     * @param {string} date
     */
    verifyTodayDate: function verifyTodayDate(date) {
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      var moment = Ember.get(this, 'moment');
      return moment.moment(date, YEAR_MONTH_DAY).isSame(new Date(), 'days');
    },

    /**
     * Generates a date label based on the passed in date. If the date is today, then will return "today." If the date
     * is within the current week, will return the date formatted in WEEKDAY_FORMAT. Otherwise, will return the date
     * formatted in MONTH_DAY format.
     * @param {string} date
     * @param {string} WEEKDAY_FORMAT - moment format; defaults to abbreviated day of the week (i.e. Tues)
     * @param {string} MONTH_DAY_FORMAT - moment format; defaults to long day format (L, en_US looks like DD/MM/YYYY)
     * @returns {string} formatted date
     */
    generateDateLabel: function generateDateLabel(date) {
      var WEEKDAY_FORMAT = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ddd';
      var MONTH_DAY_FORMAT = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'L';
      var formatter = Ember.get(this, 'i18n');
      var moment = Ember.get(this, 'moment');
      var dateMoment = moment.moment(date);
      var startDayOfWeekOffset = Ember.get(this, 'currentUser.startDayOfWeek') === 'SUNDAY' ? -1 : 0;

      var _this$_computeWeekTra = this._computeWeekTransition(startDayOfWeekOffset),
          startOfCurrentWeek = _this$_computeWeekTra.start,
          endOfCurrentWeek = _this$_computeWeekTra.end;

      if (dateMoment.isSameOrAfter(startOfCurrentWeek, 'day') && dateMoment.isSameOrBefore(endOfCurrentWeek, 'day')) {
        return Ember.get(this, 'isToday') ? formatter.formatByNamespace('today', 'common.calendar') : dateMoment.format(WEEKDAY_FORMAT);
      } else {
        return dateMoment.format(MONTH_DAY_FORMAT);
      }
    },

    /**
     * Generates a time label based on the user's time preferences
     * @param {string} time
     */
    generateTimeLabel: function generateTimeLabel(time) {
      var CLOCK_24_TIME_FORMAT = Ember.get(this, 'constants.CLOCK_24_TIME_FORMAT');
      var TWELVE_HOUR_FORMAT = Ember.get(this, 'constants.TWELVE_HOUR_FORMAT');
      var moment = Ember.get(this, 'moment');
      var timeFormat = Ember.get(this, 'currentUser.isClock12') ? TWELVE_HOUR_FORMAT : CLOCK_24_TIME_FORMAT;
      return moment.moment(time, 'YYYY-MM-DD hh:mm:ss').format(timeFormat);
    },

    /**
     * Generates week title based on the passed in date. If the date is within the current week, will return "this
     * week." Otherwise, will prepend the date with "week of."
     * @param startDate - start date of week
     * @returns {string}
     */
    generateWeekTitle: function generateWeekTitle(startDate) {
      var MONTH_DAY = 'MMM Do';
      var MONTH_DAY_YEAR = 'MMM Do, YYYY';
      var YEAR = 'YYYY';
      var formatter = Ember.get(this, 'i18n');
      var moment = Ember.get(this, 'moment');
      var now = moment.moment();
      var weekStartDate = moment.moment(startDate);
      var currentYear = now.format(YEAR);
      var inCurrentYear = weekStartDate.format(YEAR) === currentYear;
      var startDayOfWeekOffset = Ember.get(this, 'currentUser.startDayOfWeek') === 'SUNDAY' ? -1 : 0;

      var _this$_computeWeekTra2 = this._computeWeekTransition(startDayOfWeekOffset),
          startOfCurrentWeek = _this$_computeWeekTra2.start,
          endOfCurrentWeek = _this$_computeWeekTra2.end;

      if (weekStartDate.isSameOrAfter(startOfCurrentWeek, 'day') && weekStartDate.isSameOrBefore(endOfCurrentWeek, 'day')) {
        return formatter.formatByNamespace('this_week', 'common.messages');
      } else {
        var weekDate = inCurrentYear ? weekStartDate.format(MONTH_DAY) : weekStartDate.format(MONTH_DAY_YEAR);
        return formatter.formatByNamespace('week_of', 'common.messages', {
          date: weekDate
        });
      }
    },

    /**
     * Compute the week transition (start and end of week).
     *
     * Transition can be shifted by number of days from `dayOffSet`.
     *
     * Use `isoWeek` because it is static, instead of `week` which is defined according to locale.
     *
     * @param {Number} dayOffset Number of days to offset transition.
     * @returns {Object} weekTransition
     * @returns {Object} weekTransition.start Start date of the week as a Moment instance.
     * @returns {Object} weekTransition.end End date of the week as a Moment instance.
     * @private
     */
    _computeWeekTransition: function _computeWeekTransition() {
      var dayOffset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var moment = Ember.get(this, 'moment');
      var start = moment.moment();
      var end = moment.moment(); // If start of week is sunday, and today is sunday, startOf('isoWeek') floors to monday and subtracts a day,
      // effectively giving you the date 1 week ago
      // Instead, explicitly handle the sunday case

      if (dayOffset === -1 && start.day() === 0) {
        start.startOf('day');
        end.endOf('day').add(1, 'week');
      } else {
        start.startOf('isoWeek').add(dayOffset, 'day');
        end.endOf('isoWeek').add(dayOffset, 'day');
      }

      return {
        start: start,
        end: end
      };
    }
  });

  _exports.default = _default;
});