define("snapdragon-common/components/fit-weight-goal-edit-form", ["exports", "snapdragon-common/templates/components/fit-weight-goal-edit-form", "ember-cp-validations", "moment", "snapdragon-common/mixins/weight-data"], function (_exports, _fitWeightGoalEditForm, _emberCpValidations, _moment, _weightData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    goalStartWeight: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 5,
      lt: 1500,
      disabled: Ember.computed.not('model.isUSWeight'),
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_US', 'units');
      }),
      message: Ember.computed('goalStartWeight', function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
          gt: '{gt}',
          lt: '{lt}',
          unit: '{unit}'
        });
      })
    }),
    goalStartWeightKg: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 2.5,
      lt: 680,
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_symbol_metric', 'units');
      }),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      }),
      disabled: Ember.computed.not('model.isMetricWeight')
    }),
    goalStartWeightStones: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      gte: 0,
      lt: 107,
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_unit_GB', 'units');
      }),
      disabled: Ember.computed.not('model.isUKWeight'),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      })
    }),
    goalStartWeightPounds: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      lt: 14,
      gte: Ember.computed('model.goalStartWeightStones', function () {
        var stones = Number(Ember.get(this, 'model.goalStartWeightStones'));
        return stones <= 0 ? 5 : 0;
      }),
      unit: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_unit_US', 'units');
      }),
      disabled: Ember.computed.not('model.isUKWeight'),
      message: Ember.computed(function () {
        return Ember.get(this, 'model.i18n').formatByNamespace('weight_validation_msg', 'templates.components.weight-log-add', {
          gt: '{gte}',
          lt: '{lt}',
          unit: '{unit}'
        });
      })
    })
  });

  var _default = Ember.Component.extend(_weightData.default, Validations, {
    layout: _fitWeightGoalEditForm.default,
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNames: ['fit-weight-goal-edit-form'],

    /**
     * No op function
     * closeAction is passed in from whatever component uses this and is called when the user clicks save
     */
    closeAction: function closeAction() {},

    /**
     * Statically determines the end of the current day as a date object for date picker max date
     */
    maxDateFromMoment: Ember.computed(function () {
      var moment = Ember.get(this, 'moment');
      return moment.moment().endOf('day').toDate();
    }),
    weightGoal: null,
    startDateFormatted: Ember.computed('goalStartDate', function () {
      return (0, _moment.default)(Ember.get(this, 'goalStartDate')).format('YYYY-MM-DD');
    }),
    unitStr: Ember.computed(function () {
      var currentUser = Ember.get(this, 'currentUser');
      var formatter = Ember.get(this, 'i18n');

      if (Ember.get(currentUser, 'isMetricWeight')) {
        return formatter.formatByNamespace('weight_symbol_metric', 'units');
      } else if (Ember.get(currentUser, 'isUSWeight')) {
        return formatter.formatByNamespace('weight_symbol_US', 'units');
      } else {
        return formatter.formatByNamespace('weight_unit_GB', 'units') + ' / ' + formatter.formatByNamespace('weight_symbol_US', 'units');
      }
    }),
    goalStartWeightKg: Ember.computed.alias('goalStartWeight'),

    /**
     *  Save the weight goal by passing data to the onSave function which is passed from the route down
     *  the components food-goal-choose-modal to food-plan-add to here
     *  @returns {promise}
     */
    _saveWeightGoal: function _saveWeightGoal() {
      var _this = this;

      var startWeight;

      if (Ember.get(this, 'isUKWeight')) {
        startWeight = this._weightToGrams({
          stones: Ember.get(this, 'goalStartWeightStones'),
          pounds: Ember.get(this, 'goalStartWeightPounds')
        });
      } else {
        startWeight = this._weightToGrams(Ember.get(this, 'goalStartWeight'));
      }

      var saveData = {
        startDate: Ember.get(this, 'startDateFormatted'),
        startWeight: startWeight,
        currentWeight: this._weightToGrams(Ember.get(this, 'currentWeight')),
        goalWeight: null,
        goalType: null // this will preserve the original goal type

      };
      var onSave = Ember.get(this, 'onSave');
      return onSave(saveData).then(function () {
        Ember.set(_this, 'errorMessage', null);
        Ember.set(_this, 'goalStartDate', (0, _moment.default)(Ember.get(_this, 'startDateFormatted')).format('ll'));
        var closeAction = Ember.get(_this, 'closeAction');
        closeAction();
      }).catch(function (err) {
        Ember.set(_this, 'errorMessage', Ember.get(err, 'message'));
        throw err;
      });
    },

    /**
     * Determine if the weight button should be disabled due to empty weight fields
     * @returns {bool} should the weight button be disabled
     */
    weightButtonDisabled: Ember.computed('validations.isInvalid', 'startDateFormatted', 'goalStartWeight', 'goalStartWeightStones', 'goalStartWeightPounds', function () {
      var weightsNotEmpty = false;

      if (Ember.get(this, 'isUKWeight')) {
        var stone = Ember.get(this, 'goalStartWeightStones');
        var pounds = Ember.get(this, 'goalStartWeightPounds');

        if (stone !== '' && pounds !== '') {
          if (Number(stone) >= 0 && Number(pounds) >= 0) {
            weightsNotEmpty = true;
          }
        }
      } else {
        if (Ember.get(this, 'goalStartWeight')) {
          weightsNotEmpty = true;
        }
      }

      return Ember.get(this, 'validations.isInvalid') || !weightsNotEmpty;
    }),
    actions: {
      /**
       * This is used by ember-flatpickr to set the date. When the calendar is changed newDate is passed to this,
       * and we save it as startDateFormatted
       * @params date
       */
      setDate: function setDate(newDate) {
        Ember.set(this, 'startDateFormatted', (0, _moment.default)(newDate[0]).format('YYYY-MM-DD'));
      },
      save: function save(e) {
        e.preventDefault();

        this._saveWeightGoal();
      }
    }
  });

  _exports.default = _default;
});