define("snapdragon-common/mixins/log-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    moment: Ember.inject.service(),
    constants: Ember.inject.service(),
    YEAR_MONTH_DAY: Ember.computed.readOnly('constants.YEAR_MONTH_DAY'),
    MAX_DATE_RANGE: 730,
    MIN_DATE_RANGE: 7,

    /**
     * The document title for the route. Displays the start - date if the params are defined.
     * @returns {Array}
     */
    createTitleToken: function createTitleToken() {
      var _this$paramsFor = this.paramsFor(this.routeName),
          startDate = _this$paramsFor['start-date'],
          endDate = _this$paramsFor['end-date'];

      var titleTokens = [];

      if (startDate && endDate) {
        titleTokens.push("".concat(startDate, " - ").concat(endDate));
      }

      return titleTokens;
    },

    /**
     * Validates the start and end dates
     * @param params - object with start-date and end-date keys
     * @returns {boolean}
     */
    checkIsValidDateRange: function checkIsValidDateRange(params) {
      var startDate = params['start-date'],
          endDate = params['end-date'];

      if (startDate || endDate) {
        var isValidStartDate = !!startDate && this._isValidDate(startDate);

        var isValidEndDate = !!endDate && this._isValidDate(endDate);

        var isValid = isValidStartDate && isValidEndDate;
        return isValid && this._isValidDateRange(startDate, endDate);
      } else {
        return true;
      }
    },

    /**
     * Calculates the start and end date to input into the model
     * @param params - object with start-date and end-date keys
     * @param controller
     * @returns {{startDate, endDate}}
     */
    getModelDateRange: function getModelDateRange(params, controller) {
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      var startDateSrc = params['start-date'],
          endDateSrc = params['end-date'];

      if (!(startDateSrc || endDateSrc)) {
        startDateSrc = Ember.get(controller, 'startDate');
        endDateSrc = Ember.get(controller, 'endDate');
      } else {
        var momentService = Ember.get(this, 'moment');
        startDateSrc = momentService.moment(startDateSrc, YEAR_MONTH_DAY);
        endDateSrc = momentService.moment(endDateSrc, YEAR_MONTH_DAY);
      }

      return {
        startDate: startDateSrc.format(YEAR_MONTH_DAY),
        endDate: endDateSrc.format(YEAR_MONTH_DAY)
      };
    },

    /**
     * Validates single date
     * @param date
     * @returns {boolean}
     * @private
     */
    _isValidDate: function _isValidDate(date) {
      var momentService = Ember.get(this, 'moment');
      var YEAR_MONTH_DAY = Ember.get(this, 'YEAR_MONTH_DAY');
      var dateToValidate = momentService.moment(date, YEAR_MONTH_DAY, true);
      var today = momentService.moment();
      return dateToValidate.isValid() && dateToValidate.isSameOrBefore(today, 'day');
    },

    /**
     * Start date and end date must be at least MIN_DATE_RANGE days apart and within MAX_DATE_RANGE days of eachother.
     * @param startDate
     * @param endDate
     * @returns {boolean}
     * @private
     */
    _isValidDateRange: function _isValidDateRange(startDate, endDate) {
      var momentService = Ember.get(this, 'moment');
      var momentEndDate = momentService.moment(endDate);
      var diffInclusive = momentEndDate.diff(startDate, 'days') + 1;
      return diffInclusive >= Ember.get(this, 'MIN_DATE_RANGE') && diffInclusive <= Ember.get(this, 'MAX_DATE_RANGE');
    },

    /**
     * Refreshes route
     * @param controller
     */
    refreshRoute: function refreshRoute(controller) {
      if (!Ember.get(controller, 'isLoading')) {
        Ember.set(controller, 'isLoading', true);
        return this.refresh().finally(function () {
          Ember.set(controller, 'isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});