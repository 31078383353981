define("ember-accordion/templates/components/accordion-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7urLMGzX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"item\",\"expandAll\",\"collapseAll\",\"allExpanded\"],[[27,\"component\",[\"accordion-item\",[23,[\"_activeItems\"]]],[[\"toggle\",\"close\",\"register\",\"unregister\"],[[27,\"action\",[[22,0,[]],\"toggleItem\"],null],[27,\"action\",[[22,0,[]],\"closeItem\"],null],[27,\"action\",[[22,0,[]],\"register\"],null],[27,\"action\",[[22,0,[]],\"unregister\"],null]]]],[27,\"action\",[[22,0,[]],\"expandAll\"],null],[27,\"action\",[[22,0,[]],\"collapseAll\"],null],[23,[\"allExpanded\"]]]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-accordion/templates/components/accordion-list.hbs"
    }
  });

  _exports.default = _default;
});