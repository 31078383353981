define("snapdragon-common/components/fit-select-menu", ["exports", "snapdragon-common/templates/components/fit-select-menu"], function (_exports, _fitSelectMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Attributes
    layout: _fitSelectMenu.default,
    classNames: ['fit-select-menu'],
    classNameBindings: ['className'],
    // -------------------------------------------------------------------------
    // Actions
    // -------------------------------------------------------------------------
    // Events
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Autocomlete select attribute.
     *
     * @type {String|null}
     */
    autocomplete: 'off' // -------------------------------------------------------------------------
    // Observers
    // -------------------------------------------------------------------------
    // Methods

  });

  _exports.default = _default;
});