define("snapdragon-common/components/cw-form-text-field", ["exports", "snapdragon-common/components/cw-form-field", "snapdragon-common/templates/components/cw-form-text-field"], function (_exports, _cwFormField, _cwFormTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Form component for text field.
   */
  var _default = _cwFormField.default.extend({
    layout: _cwFormTextField.default,

    /**
     * Specifies `type` attribute of `input`
     */
    type: 'text',
    disabled: false,
    readOnly: false,
    actions: {
      changeValue: function changeValue(value) {
        this.onChange(value);
      }
    }
  });

  _exports.default = _default;
});