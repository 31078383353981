define("snapdragon-common/constants/program-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TEST = _exports.TERMINATED = _exports.ABANDONED = _exports.QUIT = _exports.COMPLETE = _exports.ACTIVE = void 0;
  var ACTIVE = 'ACTIVE';
  _exports.ACTIVE = ACTIVE;
  var COMPLETE = 'COMPLETE';
  _exports.COMPLETE = COMPLETE;
  var QUIT = 'QUIT';
  _exports.QUIT = QUIT;
  var ABANDONED = 'ABANDONED';
  _exports.ABANDONED = ABANDONED;
  var TERMINATED = 'TERMINATED';
  _exports.TERMINATED = TERMINATED;
  var TEST = 'TEST';
  _exports.TEST = TEST;
});